import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';
import { GeneralBarsContextProvider } from './context/generalBarsContext';
import ContentRoutes from './ContentRoutes';
import { AuthContext, AuthProvider } from './context/AuthContext';

function App() {

  const [currentRoute, setCurrentRoute] = useState()

  return (
    <div className="App">

    <BrowserRouter>
      <GeneralBarsContextProvider>
        <AuthProvider>
          <ContentRoutes currentRoute={currentRoute} setCurrentRoute={setCurrentRoute}/>
        </AuthProvider>
      </GeneralBarsContextProvider>
    </BrowserRouter>

    </div>
  );
}

export default App;
