import React, { useState } from 'react';
import "./Support.css";
import { MainInput } from '../../../../components/commom/form-input/FormInput';
import { FaWhatsapp } from 'react-icons/fa';
import axios from 'axios';
import LoadingPopup from '../../../../components/commom/loading-pop-up/LoadingPopUp';
import { useAuth } from '../../../../context/AuthContext';

const Support = () => {

  const { user } = useAuth();

  const [data, setData] = useState({

    id: user.id,
    name: user.masterName,
    email: user.email,
    message: "",
  });

  const [state, setState] = useState("empty");

  const handleInputs = (e) => {

    setData({...data, [e.target.name] : e.target.value});
  };

  const sendMessage = (e) => {

    setState("loading");

    e.preventDefault();
  
    axios.post("/api/email/support-email", data)
    .then(res=>{

      if(res.data.success === true){

        setTimeout(()=>{

          setState("success");

          setTimeout(()=>{

            setData({
              ...data,
              message: ""
            });
    
            setState("empty");
    
          }, 1500);
        }, 1500);
      }
    })
    .catch(err=>{

      setTimeout(()=>{

        setState("error");

        setTimeout(()=>{

          setData({
            ...data,
            message: ""
          });
          setState("empty");
  
        }, 1500);
      }, 1500);

    });
  }

  return (
    <div className='support'>

      {

      state === "loading"
      ? <LoadingPopup state={"loading"} message={"Envindo mensagem..."}/>
      : state === "error"
          ? <LoadingPopup state={"error"} message={"Erro ao enviar mensagem, tente novamente."}/>
          : state === "success"
              ? <LoadingPopup state={"success"} message={"Mensagem enviada!"}/>
              : null
      }
      <div className="box">
        <h2>Precisando de suporte?</h2>
        <div className="main">
          <div className="left">
            <h3>Entre em contato conosco</h3>
            <form action="submit" onSubmit={(e)=>{sendMessage(e)}}>
              <div className="row">
                
                <MainInput
                  data={{

                    type: "text",
                    name: "name",
                    label: "Seu nome",
                    placeholder: "Seu nome",
                    value: data.name,
                    required: true,
                    handleInput: handleInputs,
                  }}
                />
                <MainInput
                  data={{

                    type: "email",
                    name: "email",
                    label: "E-mail",
                    placeholder: "E-mail",
                    value: data.email,
                    required: true,
                    handleInput: handleInputs,
                  }}
                />
              
              </div>
              <div className="message">
                <label>Mensagem</label>
                <textarea required={true} name='message' value={data.message} rows={10} onChange={(e)=>{handleInputs(e)}} ></textarea>
              </div>
              <button type='submit'>Enviar</button>
            </form>
          </div>
          <div className="right">
            <h3>Outras formas de contato</h3>
            <a href='https://wa.me/message/X2NWYBEMIF6KI1' target='_blank'>
              <button><FaWhatsapp className='icon'/>Whatsapp</button>
            </a>
            <span><strong>E-mail: </strong>suporte@srbarberoficial.com.br</span>
            <span><strong>Whatsapp: </strong>(31) 99624-6328</span>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Support