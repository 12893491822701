import moment from "moment";

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatToSum = (value, localeDateString, currency) => {

    const fValue = value.replaceAll("R$", "").replaceAll(".", "").replaceAll(" ", "");
    const fValue1 = fValue.replaceAll(",", ".");


    return parseFloat(fValue1);
}

export const formatIntoCurrency = (value) => {

    const n = value.toLocaleString("pt-BR", { style: 'currency', currency: "BRL" });

    return n;
}

export const formatIntoSHA256 = async (string) => {

    const msgBuffer = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export const toClientDateTime = (dateTime, format) => {

    return moment.tz(dateTime, userTimezone).format(format);
}