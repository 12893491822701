import React, { useState } from 'react';
import "./Welcome.css";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { useForm } from '../../../utils/useForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import LoadingPopup from '../../../components/commom/loading-pop-up/LoadingPopUp';
import moment from 'moment/moment';
import ReactPixel from 'react-facebook-pixel';
import { ToastContainer, toast } from 'react-toastify';
import { getCookie } from '../../../utils/functions/Others';

const Welcome = () => {

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  ReactPixel.pageView();

  const { user, subscription } = useAuth();

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [checkLast, setCheckLast] = useState(0);

  const [state, setState] = useState("empty");

  const [userR, setUserR] = useState({

    enterpriseName: "",
    aditionalPhoneNumber: "",
    postalCode: "",
    street: "",
    streetNumber: "",
    neighborhood: "",
    city: "",
    state: "",
    firstAccess: "false"
  });
  const [initialParameters, setInitialParameters] = useState({

    minTimeService: "10",
    intervalTime: "10",
    initialWorkTime: "",
    endWorkTime: "",
    initialLunchTime: "",
    endLunchTime: "",
    days:{
      Segunda: false,
      Terça: false,
      Quarta: false,
      Quinta: false,
      Sexta: false,
      Sábado: false,
      Domingo: false,
    },
  });
  const [professionalsList, setProfessionalsList] = useState([]);
  const [servicesList, setServicesList] = useState([]);

  const handleInput = (e) =>{

    e.preventDefault();

    if(e.target.name == "image"){

      setUserR({...userR, [e.target.name] : e.target.files[0]});

    }else{

      setUserR({...userR, [e.target.name]: e.target.value});

    }

  }

  const handleSubmit = async (e) =>{

    setCheckLast(true);

    setState("loading");

    e.preventDefault();

    if(
      userR.enterpriseName != "" &&
      userR.postalCode != "" &&
      userR.street != "" &&
      userR.streetNumber != "" &&
      userR.neighborhood != "" &&
      userR.city != "" &&
      userR.state != "" &&
      professionalsList.length > 0 &&
      servicesList.length > 0 &&
      initialParameters.minTimeService != "" &&
      initialParameters.intervalTime != "" &&
      initialParameters.initialWorkTime != "" &&
      initialParameters.endWorkTime != "" &&
      initialParameters.initialLunchTime != "" &&
      initialParameters.endLunchTime != ""
      ){

        //Update barber data

        try{

          const informations = await axios.put(`/api/first-register/informations/${user.id}`, {

            enterpriseName: userR.enterpriseName,
            aditionalPhoneNumber: userR.aditionalPhoneNumber,
            postalCode: userR.postalCode,
            street: userR.street,
            streetNumber: userR.streetNumber,
            neighborhood: userR.neighborhood,
            city: userR.city,
            state: userR.state,
            firstAccess: userR.firstAccess,
            modifiedAt: moment().utc()
            
          });

          const businessRules = await axios.post("/api/first-register/business-rules", {

            barberId: user.id,
            minTimeService: initialParameters.minTimeService,
            initialWorkTime: initialParameters.initialWorkTime,
            endWorkTime: initialParameters.endWorkTime,
            initialLunchTime: initialParameters.initialLunchTime,
            endLunchTime: initialParameters.endLunchTime,
            intervalTime: initialParameters.intervalTime,
            monday: initialParameters.days.Segunda,
            tuesday: initialParameters.days.Terça,
            wednesday: initialParameters.days.Quarta,
            thursday: initialParameters.days.Quinta,
            friday: initialParameters.days.Sexta,
            saturday: initialParameters.days.Sábado,
            sunday: initialParameters.days.Domingo,
            createdAt: moment().utc()

          });

          if(informations.data.data.changedRows > 0 && businessRules.data.success === true){

            const professionals = await axios.post("/api/first-register/professionals", { professionalsList: professionalsList });

            if(professionals.data.success){

              const services = await axios.post("/api/first-register/services", {servicesList: servicesList});

              if(services.data.success){

                const pToServicesArray = [];

                servicesList.map(service=>{

                  service.professionals.map(professional=>{

                    pToServicesArray.push({

                      id: uuidv4(),
                      barberId: user.id,
                      professionalId: professional.id,
                      serviceId: service.id
    
                    });
                  });
                });

                const professionalsToServices = await axios.post("/api/first-register/professionals-to-services", { professionalsToServices: pToServicesArray });

                if(professionalsToServices.data.success){

                  axios.post("/api/pixel/events/start-trial", {

                    barberId: user.id,
                    email: user.email,
                    masterName: user.masterName,
                    phoneNumber: user.whatsapp,
                    city: user.city,
                    state: user.state,
                    postalCode: user.postalCode,
                    subscriptionId: subscription.id,
                    planId: 0,
                    source: window.location.href,
                    userAgent: navigator.userAgent,
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp"),
                  }).then(res => {


                  }).catch(err => {


                  });

                  setTimeout(()=>{

                    setState("success");

                    setTimeout(()=>{

                      setState("empty");
                      navigate("/dashboard");
                    }, 2500);
                  }, 2500);
                }
              }
            }
          }
        }catch(err){

          console.log(err);

          setTimeout(()=>{

            setState("error");
            setTimeout(()=>{

              setState("empty");
            }, 2500);
          }, 2500);
        }
    }else{

    }
  }

  const { currentComponent, changeStep, isFirstStep, isLastStep } = useForm(setProfessionalsList, professionalsList, setServicesList, servicesList, setInitialParameters, initialParameters, setUserR, userR, handleInput, handleSubmit, setCurrentStep, currentStep, user);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {

        state === "loading"
          ? <LoadingPopup state={"loading"} message={"Cadastrando sua barbearia..."}/>
          : state === "success"
            ? <LoadingPopup state={"success"} message={"Cadastro concluído!"}/>
            : state === "error"
              ? <LoadingPopup state={"loading"} message={"Erro ao cadastrar, tente novamente."}/>
              : null
        }
      <div className='welcome-container-content' >

        
          <div className='welcome-steps-status-container'>

            {

              currentStep > 0
                ? <IoMdCheckmarkCircle className='icon-check'/>
                : <IoIosCheckmarkCircleOutline className='icon-check-outline'/>
            }

            <div className='welcome-steps-divider'></div>
            {

              currentStep > 1
                ? <IoMdCheckmarkCircle className='icon-check'/>
                : <IoIosCheckmarkCircleOutline className='icon-check-outline'/>
            }

            <div className='welcome-steps-divider'></div>
            {

              currentStep > 2
                ? <IoMdCheckmarkCircle className='icon-check'/>
                : <IoIosCheckmarkCircleOutline className='icon-check-outline'/>
            }

            <div className='welcome-steps-divider'></div>
            {

              checkLast
                ? <IoMdCheckmarkCircle className='icon-check'/>
                : <IoIosCheckmarkCircleOutline className='icon-check-outline'/>
            }
          </div>
          <form action='' onSubmit={(e) => changeStep(currentStep + 1, e)} className='welcome-setps-content-container'>
              {currentComponent}
          </form>
      </div>
    </>
  )
}

export default Welcome;