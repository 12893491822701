import { FaUser } from 'react-icons/fa';
import { Colors } from '../../../Color';

const ComissionReportItem = ({comissionReport}) => {

    const getStatusColor = () => {

        if(comissionReport.status == "done"){

            return "green";
        }else if(comissionReport.status == "not done"){

            return "red";
        }else if(comissionReport.status == "pending"){

            return Colors.yellow;
        }
    }

    const getStatusText= () => {

        if(comissionReport.status == "done"){

            return "Finalizado";
        }else if(comissionReport.status == "not done"){

            return "Cancelado";
        }else if(comissionReport.status == "pending"){

            return "Pendente";
        }
    }

  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "trasnparent", borderRadius: "6px", padding: "8px", border: "none"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"}}>

            {

                comissionReport.professionalImage != "" && comissionReport.professionalImage != null
                    ?<img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${comissionReport.barberId}/${comissionReport.professionalImage}`} style={{width: "50px", height: "50px", backgroundColor: "#CBCBCB", borderRadius: "10px", objectFit: "cover"}}></img>
                    : <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "50px", height: "50px", backgroundColor: "#CBCBCB", borderRadius: "10px", fontSize: "20px", color: "white"}}><FaUser/></div>

            }
            <span style={{color: "#212121", fontSize: "18px", fontWeight: "500"}}>{comissionReport.professionalName}</span>
        </div>
        <span style={{color: "#00796B", fontSize: "18px", fontWeight: "700"}}>{comissionReport.value}</span>
    </div>
  )
}

export default ComissionReportItem;