import React, { useState } from 'react';
import "./BusinessRules.css";
import { intervalTime, serviceDurationData } from '../../../../utils/Data';
import moment from 'moment';
import axios from 'axios';
import { useAuth } from '../../../../context/AuthContext';
import LoadingPopup from '../../../../components/commom/loading-pop-up/LoadingPopUp';
import loadingBlack from "../../../../utils/images/loading_black.gif";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const BusinessRules = () => {

  const { user, businessRules } = useAuth();

  const navigate = useNavigate();

  const [businessRulesR, setBusinessRulesR] = useState({

    id: user.id,
    intervalTime: businessRules ? businessRules.intervalTime : "",
    initialWorkTime: businessRules ? businessRules.initialWorkTime : "",
    endWorkTime: businessRules ? businessRules.endWorkTime : "",
    initialLunchTime: businessRules ? businessRules.initialLunchTime : "",
    endLunchTime: businessRules ? businessRules.endLunchTime : "",
    minTimeService: businessRules ? businessRules.minTimeService : "",
    days:{
      Segunda: businessRules ? businessRules.monday == "1" ? true : false : "",
      Terça: businessRules ? businessRules.tuesday == "1" ? true : false : "",
      Quarta: businessRules ? businessRules.wednesday == "1" ? true : false : "",
      Quinta: businessRules ? businessRules.thursday == "1" ? true : false : "",
      Sexta: businessRules ? businessRules.friday == "1" ? true : false : "",
      Sábado: businessRules ? businessRules.saturday == "1" ? true : false : "",
      Domingo: businessRules ? businessRules.sunday == "1" ? true : false : "",
    },
    modifiedAt: moment().utc()
  });

  const [state, setState] = useState("empty");

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleParametersInputs = (e) =>{

    let name = e.target.name; 

    if(
      name == "Segunda" ||
      name == "Terça" ||
      name == "Quarta" ||
      name == "Quinta" ||
      name == "Sexta" ||
      name == "Sábado" ||
      name == "Domingo"

    ){
      setBusinessRulesR((prevRules) => ({
        ...prevRules,
        days: {
          ...prevRules.days,
          [name]: !businessRulesR.days[name],
        },
      }));

    }else{

      setBusinessRulesR({...businessRulesR, [e.target.name] : e.target.value});
    }

  }

  const handleSubmitParameters = (e) =>{

    e.preventDefault();

    setState("loading");

    axios.post(`/api/account/business-rules/${user.id}`, {

      intervalTime: businessRulesR.intervalTime,
      initialWorkTime: businessRulesR.initialWorkTime,
      endWorkTime: businessRulesR.endWorkTime,
      initialLunchTime: businessRulesR.initialLunchTime,
      endLunchTime: businessRulesR.endLunchTime,
      minTimeService: businessRulesR.minTimeService,
      monday: businessRulesR.days.Segunda,
      tuesday: businessRulesR.days.Terça,
      wednesday: businessRulesR.days.Quarta,
      thursday: businessRulesR.days.Quinta,
      friday: businessRulesR.days.Sexta,
      saturday: businessRulesR.days.Sábado,
      sunday: businessRulesR.days.Domingo,
      modifiedAt: businessRulesR.modifiedAt
    })
    .then(res=>{

      if(res.data.success){

        setTimeout(()=>{

          setState("success");

          setTimeout(()=>{

            navigate("/minha-barbearia/regras-de-negocio");

            setState("empty");
          }, 1500);

        }, 1500);
      }else{

        setState("empty");
      }
    })
    .catch(err=>{

      setTimeout(()=>{

        setState("empty");

        if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else{
          
        }
      }, 1500);
    });
  }

  const checkSavedData = () => {

    if(
      businessRules.initialWorkTime != businessRulesR.initialWorkTime ||
      businessRules.endWorkTime != businessRulesR.endWorkTime ||
      businessRules.initialLunchTime != businessRulesR.initialLunchTime ||
      businessRules.endLunchTime != businessRulesR.endLunchTime ||
      businessRules.intervalTime != businessRulesR.intervalTime ||
      businessRules.minTimeService != businessRulesR.minTimeService ||
      businessRules.monday != businessRulesR.days.Segunda ||
      businessRules.tuesday != businessRulesR.days.Terça ||
      businessRules.wednesday != businessRulesR.days.Quarta ||
      businessRules.thursday != businessRulesR.days.Quinta ||
      businessRules.friday != businessRulesR.days.Sexta ||
      businessRules.saturday != businessRulesR.days.Sábado ||
      businessRules.sunday != businessRulesR.days.Domingo
    ){

      return true;
    }else{

      return false
    }
  }

  const cancel = () => {

    setBusinessRulesR({
      ...businessRules,
      intervalTime: businessRules.intervalTime,
      initialWorkTime: businessRules.initialWorkTime,
      endWorkTime: businessRules.endWorkTime,
      initialLunchTime: businessRules.initialLunchTime,
      endLunchTime: businessRules.endLunchTime,
      minTimeService: businessRules.minTimeService,
      days:{
        Segunda: businessRules.monday == "1" ? true : false,
        Terça: businessRules.tuesday == "1" ? true : false,
        Quarta: businessRules.wednesday == "1" ? true : false,
        Quinta: businessRules.thursday == "1" ? true : false,
        Sexta: businessRules.friday == "1" ? true : false,
        Sábado: businessRules.saturday == "1" ? true : false,
        Domingo: businessRules.sunday == "1" ? true : false
    }
    })
  }

  if(
    businessRules == null
  ){

    return <div style={{height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", boxSizing: "border-box", gap: "10px"}}>
            <img style={{width: "50px", height: "50px", fontSize: "16px", fontWeight: "700"}} src={loadingBlack}/>
            <span style={{fontSize: "16px", fontWeight: "600"}}>Carregando...</span>
          </div>
  }

  return (
    <div className='business-rules'>

      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      <ToastContainer />

      {

      state === "loading"
      ? <LoadingPopup state={"loading"} message={"Salvando alterações..."}/>
      : state === "success"
        ? <LoadingPopup state={"success"} message={"Alterações salvas com sucesso!"}/>
        : state === "error"
          ? <LoadingPopup state={"error"} message={"Erro ao salvar alterações, tente novamente."}/>
          : null
      }

      <div className="main">
        <h1>Regras de negócio</h1>

        <div className="rows">
          <div className="row">
            <h4>Horário de trabalho:</h4>
            <div className="times">
              <div className="row">
                <label>Início:</label>
                <input type='time' name="initialWorkTime" value={businessRulesR.initialWorkTime} onChange={(e)=>{handleParametersInputs(e)}} className="initial-work-time"></input>
              </div>
              <div className="row">
                <label>Fim:</label>
                <input type='time' name="endWorkTime" value={businessRulesR.endWorkTime} onChange={(e)=>{handleParametersInputs(e)}} className="end-work-time"></input>
              </div>
            </div>
          </div>
          <div className="row">
            <h4>Horário de intervalo:</h4>
            <div className="times">
              <div className="row">
                <label>Início:</label>
                <input type='time' name="initialLunchTime" value={businessRulesR.initialLunchTime} onChange={(e)=>{handleParametersInputs(e)}} className="initial-lunch-time"></input>
              </div>
              <div className="row">
                <label>Fim:</label>
                <input type='time' name="endLunchTime" value={businessRulesR.endLunchTime} onChange={(e)=>{handleParametersInputs(e)}} className="end-lunch-time"></input>
              </div>

            </div>
          </div>
          <div className="row">
            <h4>Intervalo de slots <span className='sample'>( Ex: 10 em 10 minutos = 08:10, 08:20... )</span>:</h4>
            <select name="intervalTime" value={businessRulesR.intervalTime} onChange={(e)=>{handleParametersInputs(e)}}>
              {
                intervalTime.map(interval=>{

                  return <option key={interval.id} value={interval.id}>{interval.name}</option>
                })
              }
            </select>
          </div>
          <div className="row">
            <h4>Tempo mínimo de serviço:</h4>
            <select name="minTimeService" value={businessRulesR.minTimeService} onChange={(e)=>{handleParametersInputs(e)}}>
              {
                serviceDurationData.map(interval=>{

                  return <option value={interval.id}>{interval.name}</option>
                })
              }
            </select>
          </div>

          <div className='row'>
            <h4>Dias de funcionamento:</h4>
            <div className="selects">
              {
                Object.keys(businessRulesR.days).map((day) => (
                  <label key={day} className="checkbox-container">
                    <input
                      type="checkbox"
                      name={day}
                      checked={businessRulesR.days[day] == true ? true : false}
                      onChange={handleParametersInputs}
                      className={`custom-checkbox ${businessRulesR.days[day] ? "checked" : "unchecked"}`}
                    />
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </label>
                ))
              }
            </div>
          </div>
        </div>
        {

          checkSavedData() === true
          ? <div className="btns-container">
              <button type='button' className='cancel' onClick={()=>{cancel()}}>Cancelar</button>
              <button type='button' className='save' onClick={(e)=>{handleSubmitParameters(e)}}>Salvar alterações</button>
            </div>
          : null
          }
      </div>
    </div>
  )
}

export default BusinessRules