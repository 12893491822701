import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { BiImageAdd } from 'react-icons/bi';
import "./Profile.css";
import { useLocation } from 'react-router';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerCustom } from '../form-input/FormInput';
import { FaUser } from 'react-icons/fa';

const Profile = () => {

    const {user} = useAuth();
    const lineChartFilterPeriod = JSON.parse(sessionStorage.getItem("dashboardPeriod"));

    const location = useLocation();
    const [startDate, setStartDate] = useState(lineChartFilterPeriod != null ? new Date(lineChartFilterPeriod.startDate) : new Date());
    const [endDate, setEndDate] = useState(lineChartFilterPeriod != null ? new Date(lineChartFilterPeriod.endDate) : new Date());


    return (
        user != null
            ? user.userType === "barber"
                ? <div style={{boxSizing: "border-box", display: "flex", flexDirection: "row", alignItems: "center", gap: "20px"}}>
                    {
                        user.image
                            ? <img style={{width: "60px", height: "60px", borderRadius: "100%", objectFit: "cover"}} src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/barber/${user.image}`} alt="profile image"/>
                            : <div style={{width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "gray"}} className="icon-container"><FaUser style={{color: "white"}} className="icon"/></div>

                    }
                    
                    <span className="info-name">{user.enterpriseName != "" ? user.enterpriseName : "Nome da empresa"}</span>
                </div>

                : <>
                    {
                        user.image
                            ? <img className="info-img" src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/customer/${user.image}`} alt="profile image"/>
                            : <div className="icon-container"><FaUser className="icon"></FaUser></div>
                    }
                    <span className="info-name">{user.name}</span>
                </>
            : null
    )
}

export default Profile