import axios from "axios";
import { useEffect, useState } from "react";
import "./Finances.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerCustom } from "../../../../components/commom/form-input/FormInput";
import { FaFilter } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import ProfessionalItem from "./ProfessionalItem";
import ServiceItem from "./ServiceItem";
import { grossRevenue, totalComissions, totalRevenueForEachProfessional, totalRevenueForEachServiceType } from "../../../../utils/functions/CalcFunctions";
import { formatIntoCurrency } from "../../../../utils/functions/Formatters";
import { ArcElement, BarController, BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LineController, LineElement, LinearScale, PointElement, Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import { calcRevenueForLineChart } from "../../../../utils/functions/ChartsFunctions";
import { useAuth } from "../../../../context/AuthContext";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineController, LineElement, Filler, BarElement, BarController);

const Finances = () => {

  const { user } = useAuth();

  const financesFilterDate = JSON.parse(sessionStorage.getItem("financesPeriod"));

  const [professionals, setProfessionals] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [services, setServices] = useState([]);

  const [showDateFilter, setShowDateFilter] = useState(false);
  const [startDate, setStartDate] = useState(financesFilterDate != null ? moment(financesFilterDate.startDate).utc() : moment().utc());
  const [endDate, setEndDate] = useState(financesFilterDate != null ? moment(financesFilterDate.endDate).utc() :moment().utc());
  const [filtersTrigger, setFiltersTrigger] = useState(false);

  const [totalGrossRevenue, setTotalGrossRevenue] = useState("0,00");
  const [totalComissionsS, setTotalComissionsS] = useState("0,00");
  const [totalRevenueForEachProfessionalS, setTotalRevenueForEachProfessionalS] = useState([]);
  const [totalRevenueForEachServiceTypeS, setTotalRevenueForEachServiceTypeS] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const filter = () => {

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    setShowDateFilter(!showDateFilter);
    setFiltersTrigger(!filtersTrigger);

    sessionStorage.setItem("financesPeriod", JSON.stringify({startDate: startDate, endDate: endDate}));

    axios.get("/api/schedules", {
      params:{
        barberId: user.id,
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD")
      }
    })
    .then(res=>{

      if(res.data.success === true){

        if(res.data.data.length >= 1){

          setSchedules(res.data.data);
          setBarChartData(calcRevenueForLineChart(res.data.data, startDate, endDate));
          setTotalGrossRevenue(grossRevenue(res.data.data));
          setTotalComissionsS(totalComissions(professionals, res.data.data));
          setTotalRevenueForEachProfessionalS(totalRevenueForEachProfessional(professionals, res.data.data));
          setTotalRevenueForEachServiceTypeS(totalRevenueForEachServiceType(services, res.data.data));
        }
      }
    })
    .catch(err=>{

      setTimeout(()=>{

        if(err.response.status === 404){
  
        }else if(err.response.status === 500){

          notifyError(err.response.data.message);

        }else{

        }
      }, 1500);
    });
  }

  useEffect(()=>{

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    const fetchData = () => {

      axios.get("/api/schedules", {
        params:{
          barberId: user.id,
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD")
        }
      })
      .then(res=>{

        if(res.data.success === true){

          setSchedules(res.data.data);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });

      axios.get(`/api/professionals-by-barber/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          let pArray = res.data.data;

          setProfessionals(pArray);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
  
            notifyError(err.response.data.message);
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });

      axios.get(`/api/services/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          setServices(res.data.data);
        }
      })
      .catch(err=>{

        console.log(err);
      });
    }

    if(user){

      fetchData();
    }

  }, []);


  useEffect(()=>{

    setBarChartData(calcRevenueForLineChart(schedules, startDate, endDate));
    setTotalGrossRevenue(grossRevenue(schedules));
    setTotalComissionsS(totalComissions(professionals, schedules));
    setTotalRevenueForEachProfessionalS(totalRevenueForEachProfessional(professionals, schedules));
    setTotalRevenueForEachServiceTypeS(totalRevenueForEachServiceType(services, schedules));

  }, [professionals, services, schedules]);

  return (

    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {
        filtersTrigger
          ? <div className="filters-mobile-container">
              <div className="top">
                <IoMdClose onClick={()=>{setFiltersTrigger(!filtersTrigger)}} />
              </div>
              <div className="date">
                <span>Data:</span>
                <div className="row">
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
                </div>
              </div>
              
              <button className="filter" onClick={()=>{filter()}}>Filtrar</button>
          </div>
          : null
      }
      <div className="finances">
        <div className="top">
          <div className="filters-desktop">
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
            <button className="search" onClick={()=>{filter()}}>Pesquisar</button>
          </div>
          <button className="filters-mobile" onClick={()=>{setFiltersTrigger(!filtersTrigger)}}>
            <FaFilter />
          </button>
        </div>

        <div className="main-container">
          <div className="left">
            <div className="row-1">
              <div className="card">
                <h3>Faturamento bruto total</h3>
                <p>Faturamento bruto total no período selecionado.</p>
                <h1>{formatIntoCurrency(parseFloat(totalGrossRevenue))}</h1>
              </div>
              <div className="card">
                <h3>Total de comissões</h3>
                <p>Total de comissões no período selecionado.</p>
                <h1>{formatIntoCurrency(parseFloat(totalComissionsS))}</h1>
              </div>
            </div>
            <div className="row-2">
              <h3>Faturamento bruto por período</h3>
              <div className="chart">
                {

                  barChartData.every(item => item.sum == 0)
                  ? (

                      <span style={{alignSelf: "center", justifySelf: "center"}}>Sem dados!</span>
                    )
                  : (
                      <Bar
                        data={{
                          labels: barChartData.map(item => moment(item.range.date1).format("DD/MM/YYYY") + " - " + moment(item.range.date2).format("DD/MM/YYYY")),
                          datasets: [{
                            label: 'Valor',
                            data: barChartData.map(item => item.sum),
                            borderColor: '#EE3F2D',
                            backgroundColor: '#EE3F2D'
                          }]
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            y: {
                              ticks: {
                                callback: function(value) {
                                  return formatIntoCurrency(value);
                                }
                              }
                            },
                            x: {

                              display: false
                            }
                          },
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: function(context) {
                                  let label = context.dataset.label || '';
                                  if (label) {
                                    label += ': ';
                                  }
                                  label += formatIntoCurrency(parseFloat(context.raw));
                                  return label;
                                }
                              }
                            }
                          }
                        }}
                      />
                    )
                }
              </div>
            </div>
          </div>
          <div className="right">

            <div className="box">
              <h3>Faturamento total de cada profissional</h3>
              <div className="list">
                {

                  totalRevenueForEachProfessionalS.map((totalRevenue, index)=>{

                    return <ProfessionalItem key={index} data={{barberId: user.id, professionalId: totalRevenue.professional.id, image: totalRevenue.professional.image, name: totalRevenue.professional.name, revenue: formatIntoCurrency(totalRevenue.total)}}/>
                
                  })
                }
              </div>
            </div>

            <div className="box">
              <h3>Faturamento total de cada tipo de serviço</h3>
              <div className="list">
                {

                  totalRevenueForEachServiceTypeS.map((totalRevenue, index)=>{

                    return <ServiceItem key={index} data={{name: totalRevenue.service.name, revenue: formatIntoCurrency(totalRevenue.total)}}/>

                  })
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Finances;