import moment from 'moment';
import { useEffect, useState } from 'react';
import { BiImageAdd } from "react-icons/bi";
import axios from '../../../../node_modules/axios/index';
import { FormInput, MaskedFormInput } from '../../../components/commom/form-input/FormInput';
import LoadingPopup from '../../../components/commom/loading-pop-up/LoadingPopUp';
import "./MyAccount.css";
import { useAuth } from '../../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';

const MyAccount = () => {

  const { user } = useAuth();
  
  const [userData, setUserData] = useState({

    image: user.image,
    name: user.name,
    phoneNumber: user.phoneNumber,
    postalCode: user.postalCode,
    street: user.street,
    streetNumber: user.streetNumber,
    neighborhood: user.neighborhood,
    city: user.city,
    state: user.state,
    modifiedAt: ""

  });
  const [newImage, setNewImage] = useState(null);
  const [state, setState] = useState("");
  const [, forceUpdate] = useState();

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const fetchAddress = () =>{

    if(userData.postalCode != ""){

      let noDash = userData.postalCode.replace('-', '');
      let isNum = /^\d+$/.test(noDash);

      if(noDash.length == 8 && isNum){

          fetch(`https://viacep.com.br/ws/${noDash}/json/`)
          .then((result)=> {

            return result.json()
          })
          .then((data)=>{

            setUserData({
              ...userData,  
              'street': data.logradouro, 
              'neighborhood': data.bairro,
              'city': data.localidade,
              'state': data.uf
            })
          })
      }
    }

}

  const validateFields = (name) =>{

    if(name == "phoneNumber" && userData.phoneNumber != null){


        let str1 = userData.phoneNumber.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", "");
        if(str1.length == 11){
        return false;
        }else{
    
        return true;
        }
    }

}

  const handleInputs = (e) => {

    e.preventDefault();

    if(e.target.name === "newImage"){

      setNewImage(e.target.files[0]);

    }else{

      setUserData({...userData, [e.target.name] : e.target.value});

    }

  }

  const saveChanges = (e) => {

    setState("loading");

    const modifiedAt = moment().utc();

    if(newImage != null){

      const formdata = new FormData();
      formdata.append('customerId', user.id);
      formdata.append('image', newImage);
      axios.post("/api/account/edit-customer/save-image", formdata)
      .then(resImg=>{

        if(resImg.data.success){

          axios.post("/api/account/edit-customer", {params:{

            id: user.id,
            image: resImg.data.image,
            name: userData.name,
            phoneNumber: userData.phoneNumber,
            postalCode: userData.postalCode,
            street: userData.street,
            streetNumber: userData.streetNumber,
            neighborhood: userData.neighborhood,
            city: userData.city,
            state: userData.state,
            modifiedAt: modifiedAt
          }})
          .then(res=>{

            if(res.data.success){

              setTimeout(()=>{

                setState("success");
                const nUser = {
                  ...user,
                  image: resImg.data.image,
                  name: userData.name,
                  phoneNumber: userData.phoneNumber,
                  postalCode: userData.postalCode,
                  street: userData.street,
                  streetNumber: userData.streetNumber,
                  neighborhood: userData.neighborhood,
                  city: userData.city,
                  state: userData.state,
                  modifiedAt: modifiedAt
                }
                sessionStorage.setItem("userData", JSON.stringify(nUser));

                setTimeout(()=>{
                  setState("");
                  window.location.reload();
                },1500);
              },1500);
            }
          })
          .catch(err=>{

            setState("error");

            setTimeout(()=>{

              setState("")

              if(err.response.status === 404){
        
              }else if(err.response.status === 500){
      
                notifyError(err.response.data.message);
      
              }else{
      
              }
            }, 1500);
          });
        }
      })
      .catch(err=>{

        setState("error");
        setTimeout(()=>{

          setState("");

          if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });
    }else{

        axios.post("/api/account/edit-customer", {params:{

        id: user.id,
        image: userData.image,
        name: userData.name,
        phoneNumber: userData.phoneNumber,
        postalCode: userData.postalCode,
        street: userData.street,
        streetNumber: userData.streetNumber,
        neighborhood: userData.neighborhood,
        city: userData.city,
        state: userData.state,
        modifiedAt: modifiedAt
      }})
      .then(res=>{

        if(res.data.success){

          setTimeout(()=>{

            setState("success");
            const nUser = {
              ...user,
              image: userData.image,
              name: userData.name,
              phoneNumber: userData.phoneNumber,
              postalCode: userData.postalCode,
              street: userData.street,
              streetNumber: userData.streetNumber,
              neighborhood: userData.neighborhood,
              city: userData.city,
              state: userData.state,
              modifiedAt: modifiedAt
            }
            sessionStorage.setItem("userData", JSON.stringify(nUser));
            setTimeout(()=>{
              setState("");
              window.location.reload();
            }, 1500);
          }, 1500);
        }
      })
      .catch(err=>{

        setState("error");
        setTimeout(()=>{

          setState("");

          if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });
    }

    
  }

  const checkData = () => {

    if(
      newImage != null ||
      userData.name != user.name ||
      userData.phoneNumber != user.phoneNumber ||
      userData.postalCode != user.postalCode ||
      userData.street != user.street ||
      userData.streetNumber != user.streetNumber ||
      userData.neighborhood != user.neighborhood ||
      userData.city != user.city ||
      userData.state != user.state
    ){
      return true;
    }else{

      return false;
    }
  }

  return (
    <div className="account-container">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {
        state !== ""
          ? <div className='loading'>
          {

            state === "loading"

              ? <LoadingPopup state={"loading"} message={"Salvando alterações..."}/>
              : state === "success"

                ? <LoadingPopup state={"success"} message={"Alterações salvas com sucesso!"}/>
                : state === "error"

                  ? <LoadingPopup state={"error"} message={"Erro ao salvar alterações, tente novamente."}/>
                  : null
          }
        </div>
        : null
      }
      <div className="account-main-container">
        <div className="customer-data-column">
          <h3 className="account-data-column-title">Informações do usuário</h3>
          <span className="account-data-column-description">
            Insira informações sobre você aqui, nome, telefone e uma foto de perfil (não é obrigatória).
          </span>

          <div className="fields-container">
            <div className="image-container">
                <input type="file" className="image-input" onChange={(e)=>handleInputs(e)} name="newImage"/>
                {

                  newImage != null
                    ? <img src={URL.createObjectURL(newImage)} />
                    : userData.image != ""
                        ?<img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/customer/${userData.image}`}/>
                        :<BiImageAdd className="image-icon"/>
                }
            
            </div>
            <div className='fields'>
              <div className='row'>
                <FormInput

                  inputData={{

                    label: "Nome",
                    placeholder: "Nome",
                    name: "name",
                    value: userData.name,
                    required: true
                  }}
                  handleInput={handleInputs}
                  aditionalClass={"stretched"}
                  />
                <MaskedFormInput
                  inputData={{

                    name: "phoneNumber",
                    label: "Telefone",
                    placeholder: "(00) 00000-0000",
                    value: userData.phoneNumber,
                    mask: "(99) 99999-9999",
                    hasError: validateFields("phoneNumber").toString(),
                    error: "Número inválido!"
                  }}
                  handleInput={handleInputs}
                  aditionalClass={"stretched"}
                />
              </div>
              <div className='row'>

              <MaskedFormInput inputData={{

                name: 'postalCode',
                placeholder: 'CEP', 
                label: 'CEP',
                value: userData.postalCode,
                mask: "99999-999",
                error: 'Preencha o cep.',
                pattern: "^[0-9]{5}(?:-[0-9]{3})?$",
                required: true,
                disabled: false
                }} 
                aditionalClass='' 
                handleInput={handleInputs}  
                fetchAddress={fetchAddress}
                /> 

                <FormInput

                  inputData={{

                    label: "Rua",
                    placeholder: "Rua",
                    name: "street",
                    value: userData.street,
                    disabled: true
                  }}
                  handleInput={handleInputs}
                  aditionalClass={"stretched"}
                />
                <FormInput

                  inputData={{

                    label: "Número",
                    placeholder: "Número",
                    name: "streetNumber",
                    value: userData.streetNumber,
                    required: true
                  }}
                  handleInput={handleInputs}
                />
              </div>
              <div className='row'>

                <FormInput

                  inputData={{

                    label: "Bairro",
                    placeholder: "Bairro",
                    name: "neighborhood",
                    value: userData.neighborhood,
                    disabled: true
                  }}
                  handleInput={handleInputs}
                  aditionalClass={"stretched"}
                />

                <FormInput

                  inputData={{

                    label: "Cidade",
                    placeholder: "Cidade",
                    name: "city",
                    value: userData.city,
                    disabled: true
                  }}
                  handleInput={handleInputs}
                  aditionalClass={"stretched"}
                />
                <FormInput

                  inputData={{

                    label: "Estado",
                    placeholder: "Estado",
                    name: "state",
                    value: userData.state,
                    disabled: true
                  }}
                  handleInput={handleInputs}
                  aditionalClass={"stretched"}
                />
              </div>
            </div>
          </div>

          {

            checkData() == true
              ? <button type='button' className="save-changes-btn" onClick={(e)=>{saveChanges(e)}}>Salvar alterações</button>
              : <button type='button' disabled={true} className="save-changes-btn" onClick={(e)=>{saveChanges(e)}}>Salvar alterações</button>
          }

          

        </div>

      </div>
    </div>
  )
}

export default MyAccount