import axios from "axios";
import { useContext, useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { FaPen, FaTrashAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import DeleteConfirmPopUp from "../../../../components/commom/delete-confirm-pop-up/DeleteConfirmPopUp";
import { useAuth } from "../../../../context/AuthContext";
import { GeneralBarsContext } from "../../../../context/generalBarsContext";
import "./Services.css";
import { ToastContainer, toast } from 'react-toastify';

const Services = () => {

  const { user } = useAuth();

  const navigate = useNavigate();

  const {setSelectedServiceData, setAddServiceBarState, addServiceBarState, setAddServiceBarTriggerName} = useContext(GeneralBarsContext)

  const [servicesList, setServicesList] = useState();
  const [deletePopUpTrigger, setDeletePopUpTrigger] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const callFunctions = (row) =>{

    setSelectedServiceData(row);
    setAddServiceBarState(!addServiceBarState);
    setAddServiceBarTriggerName("edit");
  }

  const callFunctionsBtn = () =>{

    setSelectedServiceData(null);
    setAddServiceBarState(!addServiceBarState);
    setAddServiceBarTriggerName("add");
  }

  const deleteCallFunctions = (state, row) => {

    setDeletePopUpTrigger(state);
    setRowToDelete(row);
  }

  const deleteService = (e, row) => {

    e.preventDefault();

    axios.delete(`/api/services/delete-service/${row.id}`)
    .then(res=>{

      if(res.data != null && res.data != "Error"){

        navigate("/cadastros/servicos")
      }
    })
    .catch(err=>{

      //DELETE SERVICE

      setTimeout(()=>{

        if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else{
            
        }
      }, 1500);
    })
  }

  const columns = [
    //IMPLEMENT AFTER
    /*{
        name: 'Imagem',
        selector: row => row.image,
        sortable: false,
        cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center"}}>
            {
              row.image != ""
              ? <img style={{width: "60px", height: "60px", objectFit: "cover", borderRadius: "100%"}} src={`https://srbarberoficial.com.br/uploads/services/${user.id}/${row.image}`} />
              : <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgray", borderRadius: "100%", width: "50px", height: "50px"}}><BiImageAdd style={{color: "white", fontSize: "24px"}}/></div>
            }
            </div>
        },

    },*/
    {
      name: 'Nome',
      selector: row => row.name,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.name}</div>
      }

    },
    {
        name: 'Preço',
        selector: row => row.price,
        sortable: true,
        cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.price}</div>
        }
    },

    {
        name: 'Duração',
        selector: row => row.duration,
        sortable: true,
        cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.duration} minutos</div>
        }
    },

    {
      name: 'Ação',
      selector: row => row.action,
      sortable: false,
      cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", flexDirection: "row", gap: "10px"}}>
                    <button style={{padding: "10px 12px", outline: 0, background: "#5A6B7D", border: 0, color: "white", fontWeight: "600", cursor: "pointer", borderRadius: "4px"}} onClick={()=>{callFunctions(row)}}><FaPen/></button>
                    <button style={{padding: "10px 12px", outline: 0, background: "#EE3F2D", border: 0, color: "white", fontWeight: "600", cursor: "pointer", borderRadius: "4px"}} onClick={()=>{deleteCallFunctions(true, row)}}><FaTrashAlt /></button>
                 </div>
      }
      
    },
  ];

  const customStyles = {
    rows: {
        style: {
          width: "100%",
          borderBox: "box-sizing",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "stretch",
          backgroundColor: "#FAFAFA",
          padding: "10px 0px",
          marginTop: "12px",
          borderRadius: "4px"
        },
    },
    headCells: {
        style: {
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px",
        },
    },
    cells: {
        style: {
            
        },
    },
  };

  useEffect(()=>{

    axios.get(`/api/services/${user.id}`)
    .then(res=>{


      if(res.data.success === true){

        setServicesList(res.data.data);
      }

    })
    .catch(err=>{

      //SERVICES

      setTimeout(()=>{

        if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else if(err.response.status === 404){

          notifyError(err.response.data.message);
        }else{
            
        }
      }, 1500);
    });
  },[]);

  return (

    <>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {

        deletePopUpTrigger
          ? <DeleteConfirmPopUp message={"Deletar esse serviço?"} data={rowToDelete} cancel={setDeletePopUpTrigger} action={deleteService} cancelBtnText={"Cancelar"} actionBtnText={"Deletar"}/>
          : null
      }
    

    <div className="services">
      <div className="top">
        <button onClick={()=>{callFunctionsBtn()}} className="add-service-btn"><IoMdAdd style={{fontSize: "22px", color: "white"}}/>NOVO SERVIÇO</button>
      </div>
      <div className="services-list-container">
        <DataTable
            columns={columns}
            data={servicesList}
            customStyles={customStyles}
            selectableRows
        />
      </div>
    </div>
    </>
  )
}

export default Services;