// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color-light: #101418;
    --button-color-light: #35383f;
    --title-color-light: #35383f;
    --background-color-light: #EEEEEE;
    --highlight-color: #EE3F2D;
  }
  `, "",{"version":3,"sources":["webpack://./src/utils/Main.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,6BAA6B;IAC7B,4BAA4B;IAC5B,iCAAiC;IACjC,0BAA0B;EAC5B","sourcesContent":[":root {\n    --primary-color-light: #101418;\n    --button-color-light: #35383f;\n    --title-color-light: #35383f;\n    --background-color-light: #EEEEEE;\n    --highlight-color: #EE3F2D;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
