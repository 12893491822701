import React, { useEffect, useState } from 'react';
import "./ReactiveAccount.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BiLogOut } from 'react-icons/bi';
import { CiImageOn } from "react-icons/ci";
import { planName, plans } from '../../../utils/Data';
import { useAuth } from '../../../context/AuthContext';
import logoGray from "../../../utils/images/logo_gray.svg";
import moment from 'moment';

const ReactiveAccount = () => {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [subscription, setSubscription] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [plansList, setPlansList] = useState(null);

    const reactiveAccount = async () => {

        await axios.get(`/api/subscriptions/${user.id}`)
        .then(async resSub=>{

            if(
                resSub.data.data.status === "canceled"
            ){

                //Create a new subscription o Stripe

                await axios.post('/api/upgrade/create-subscription', {

                    stripeCustomerId: resSub.data.data.stripeCustomerId,
                    planId: selectedPlanId
                })
                .then(async res=>{

                    await axios.put(`/api/upgrade/save-subscription-id${user.id}`, {

                        stripeCustomerId: resSub.data.data.stripeCustomerId,
                        subscriptionId: res.data.subscriptionId,
                        barberId: user.id,
                        status: "incomplete"
                    }).then(resSaveSub=>{

                        if(resSaveSub.data.success){

                            navigate(`/checkout?planId=${selectedPlanId}&py=${res.data.paymentIntent}&cs=${res.data.clientSecret}&scid=${resSub.data.data.stripeCustomerId}`);
                        }
                    })
                    .catch(err=>{

                        console.log(err);

                        if(err.response.status === 500){

                        }
                    });

                })
                .catch(err=>{

                    console.log(err);

                });

            }else if(resSub.data.data.status === "past-due"){

                //Request subscription from Stripe

                if(resSub.data.data.subscriptionId !== ""){

                    await axios.get('/api/upgrade/stripe-subscription', { params:{

                        subscriptionId: resSub.data.data.subscriptionId
                    }})
                    .then(async resStripeSubscription=>{

                        if(resStripeSubscription.data.success === true){

                            //Request invoice from Stripe to get payment intent id

                            await axios.post('/api/stripe/invoice', {invoiceId: resStripeSubscription.data.subscription.latest_invoice})
                            .then(async resInvoice => {

                                if(resInvoice.data.invoice.status === "draft"){

                                    //If invoice status is DRAFT turn into OPEN

                                    await axios.post('/api/stripe/finalize-invoice', {invoiceId: resInvoice.data.invoice.id})
                                    .then(async resInvoiceEdited=>{

                                        //Request payment intent from Stripe to get client secret


                                        await axios.post('/api/stripe/payment-intent', {paymentIntentId: resInvoiceEdited.data.invoice.payment_intent})
                                        .then(resPaymentIntent=>{

                                            if(resPaymentIntent.data.success === true){

                                                //Return checkout link

                                                navigate(`/checkout?planId=${resSub.data.data.planId}&py=${resInvoiceEdited.data.invoice.payment_intent}&cs=${resPaymentIntent.data.paymentIntent.client_secret}&scid=${resSub.data.data.stripeCustomerId}&src="reactive-account"`);
                                            }

                                        })
                                        .catch(err=>{

                                            console.log(err);


                                        });

                                    })
                                    .catch(err=>{

                                        console.log(err);

                                    });
                                }else if(resInvoice.data.invoice.status === "open"){

                                    //Request payment intent from Stripe to get client secret

                                    await axios.post('/api/stripe/payment-intent', {paymentIntentId: resInvoice.data.invoice.payment_intent})
                                    .then(resPaymentIntent=>{

                                    console.log(resPaymentIntent)
                                        //Return checkout link

                                        if(resPaymentIntent.data.success === true){

                                            navigate(`/checkout?planId=${resSub.data.data.planId}&py=${resInvoice.data.invoice.payment_intent}&cs=${resPaymentIntent.data.paymentIntent.client_secret}&scid=${resSub.data.data.stripeCustomerId}`);
                                        }

                                    })
                                    .catch(err=>{

                                        console.log(err);

                                    });

                                }else{

                                    //Request payment intent from Stripe to get client secret

                                    await axios.post('/api/stripe/payment-intent', {paymentIntentId: resInvoice.data.invoice.payment_intent})
                                        .then(resPaymentIntent=>{

                                            //Return checkout link

                                            if(resPaymentIntent.data.success === true){

                                                navigate(`/checkout?planId=${resSub.data.data.planId}&py=${resInvoice.data.invoice.payment_intent}&cs=${resPaymentIntent.data.paymentIntent.client_secret}&scid=${resSub.data.data.stripeCustomerId}`);
                                            }

                                        })
                                        .catch(err=>{

                                            console.log(err);

                                        });
                                }

                            })
                            .catch(err=>{

                                console.log(err);

                            });
                        }
                    })
                    .catch(err=>{

                        console.log(err);

                    });
                }
            }
        })
        .catch(err=>{

            console.log(err);

        });
    }

    const logout = () => {

    localStorage.removeItem("token");
    navigate("/acessar-conta");
    }    

    useEffect(()=>{

        axios.get(`/api/subscriptions/${user.id}`)
        .then(res=>{

            if(res.data.success === true){

                setSubscription(res.data.data);

                axios.get('/api/upgrade/stripe-subscription', { params:{

                    subscriptionId: res.data.data.subscriptionId
                }})
                .then(async resStripeSubscription=>{

                    if(resStripeSubscription.data.success === true){

                        await axios.post('/api/stripe/invoice', {invoiceId: resStripeSubscription.data.subscription.latest_invoice})
                        .then(async resInvoice => {

                            setInvoice(resInvoice.data.invoice)
                        })
                        .catch(err=>{

                            console.log(err);

                        });

                    }
                })
                .catch(err=>{

                    console.log(err);

                });

            }
        })
        .catch(err=>{

            console.log(err);

        });

    }, []);

    useEffect(()=>{

        if(subscription != null){

            const currentPlanIndex = plans.findIndex(plan => plan.id == subscription.planId);
        
            if (currentPlanIndex === -1) {
                setPlansList(plans); // Retorna a lista original se o item não for encontrado
            }
        
            // Clonar o array original para evitar modificações no array original
            const clonedPlans = [...plans];
        
            // Remover o item especial do array clonado
            const [specialItem] = clonedPlans.splice(currentPlanIndex, 1);
        
            // Adicionar o item especial no início do array
            const reorderedItems = [specialItem, ...clonedPlans];

            setPlansList(reorderedItems);
            setSelectedPlanId(subscription.planId)

        }


    }, [subscription]);

  return (
    <div className='reactive-account'>

      <div className="main">
        <div className='top-bar'>

            <img className='logo' src={logoGray}></img>

            {

                user != null
                    ? <div className="user-info">
                        {
                        user.image != ""
                            ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/barber/${user.image}`}></img>
                            : <div className="icon-container"><CiImageOn/></div>
                        }
                        <span>{user.enterpriseName}</span>
                        <button onClick={()=>{logout()}} className="logout">
                        <BiLogOut className="icon"/>
                        </button>
                    </div>
                : null
            }


        </div>
        <div className='main'>
            <h2>Reative sua conta</h2>

            {

                subscription != null
                    ? subscription.status == "canceled"
                        ? <p>Para reativar sua conta basta continuar com o seu plano anterior ou escolher um novo:</p>
                        : <p>Para reativar sua conta basta pagar a última fatura em aberto:</p>
                    : null
            }
            

            {

                subscription != null && subscription.status != "canceled"
                    ? <div className="details">
                        <div className="left">
                            <div className="row">
                                <span>Fatura criada em:</span>
                                <span className='main'>{invoice != null ? moment.unix(invoice.created).format("DD/MM/YYYY") : null}</span>
                            </div>
                            <div className="row">
                                <span>Fatura vence em:</span>
                                <span className='main'>{invoice != null && invoice.due_date != null ? invoice.due_date : "--/--/----"}</span>
                            </div>
                            <div className="row">
                                <span>Valor:</span>
                                <span className='main'>{planName(subscription.planId).planPrice}</span>
                            </div>
                        </div>
                        <div className="right">
                            <div className="row">
                                <span>Nome do responsável:</span>
                                <span className='main'>{user.masterName}</span>
                            </div>
                            <div className="row">
                                <span>E-mail do responsável:</span>
                                <span className='main'>{user.email}</span>
                            </div>
                            <div className="row">
                                <span>Telefone do responsável:</span>
                                <span className='main'>{user.whatsapp}</span>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <div className="current-plan">
                <span>Seu plano atual:</span>
                <span className='price'>{subscription != null ? planName(subscription.planId).planTitle : null}</span>
            </div>
            
            

            {
                subscription != null
                    ? subscription.status == "canceled"
                        ?
                        <div className='cards'>

                            {
                                plansList != null
                                    ? plansList.map(plan=>{

                                        return <div className={`card ${plan.id == selectedPlanId ? "active" : ""}`} onClick={()=>{setSelectedPlanId(plan.id)}}>
                                                    <div className='plan-info'>
                                                        <span className='plan-title'>{plan.name}</span>
                                                        <span className='plan-price'>{plan.price}<span style={{fontSize: "18px"}}>/mês</span></span>
                                                    </div>
                                                    <ul>
                                                        <li>Cobranças mensais automáticas</li>
                                                        <li>Suporte ativo</li>
                                                    </ul>
                                                </div>
                                    })
                                    : null
                                
                            }
                        </div>
                        : <div className={`card`} >
                            <div className='plan-info'>
                                <span className='plan-title'>{planName(subscription.planId).planTitle}</span>
                                <span className='plan-price'>{planName(subscription.planId).planPrice}<span style={{fontSize: "18px"}}>/mês</span></span>
                            </div>
                            <ul>
                                <li>Cobranças mensais automáticas</li>
                                <li>Suporte ativo</li>
                            </ul>
                        </div>
                    : null
            }
            
            <button onClick={()=>{reactiveAccount()}}>{
                
                subscription != null && selectedPlanId != null
                    ? selectedPlanId == subscription.planId
                        ? `Continuar com o ${planName(selectedPlanId).planTitle}`
                        : `Assinar o ${planName(selectedPlanId).planTitle}`
                    : null
                }</button>
            {/*<span className='do-upgrade'>Fazer upgrade</span>*/}
        </div>


      </div>

    </div>
  )
}

export default ReactiveAccount