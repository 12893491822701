// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services{

    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 10px 0px 10px;
    height: 100%;
    box-sizing: border-box;
    background-color: #EEEEEE;
}

.services .top{

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.services .top .add-service-btn{

    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 8px;
    background-color: var(--button-color-light);
    padding: 16px 20px;
    cursor: pointer;
    color: white;
    font-weight: 700;
}

.services .top .add-service-btn:hover{

    opacity: 0.9;
}

.services .services-list-container{

    flex: 1 1;
    display: flex;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 6px;
    padding: 20px;
    justify-content: center;
    align-items: flex-start;

}

@media only screen and (max-width: 600px){

    .services{

        padding: 10px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px){

    .services{

        padding: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/barber/registrations/services/Services.css"],"names":[],"mappings":"AAAA;;IAEI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,0BAA0B;IAC1B,YAAY;IACZ,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,2CAA2C;IAC3C,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;;IAEI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,uBAAuB;;AAE3B;;AAEA;;IAEI;;QAEI,aAAa;IACjB;AACJ;;AAEA;;IAEI;;QAEI,aAAa;IACjB;AACJ","sourcesContent":[".services{\n\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 0px 10px 0px 10px;\n    height: 100%;\n    box-sizing: border-box;\n    background-color: #EEEEEE;\n}\n\n.services .top{\n\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    align-items: flex-start;\n}\n\n.services .top .add-service-btn{\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 0;\n    border-radius: 8px;\n    background-color: var(--button-color-light);\n    padding: 16px 20px;\n    cursor: pointer;\n    color: white;\n    font-weight: 700;\n}\n\n.services .top .add-service-btn:hover{\n\n    opacity: 0.9;\n}\n\n.services .services-list-container{\n\n    flex: 1;\n    display: flex;\n    margin-bottom: 20px;\n    background-color: white;\n    border-radius: 6px;\n    padding: 20px;\n    justify-content: center;\n    align-items: flex-start;\n\n}\n\n@media only screen and (max-width: 600px){\n\n    .services{\n\n        padding: 10px;\n    }\n}\n\n@media only screen and (min-width: 601px) and (max-width: 900px){\n\n    .services{\n\n        padding: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
