// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-item-container{

    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    gap: 10px;
    border: 0;
    background-color: transparent;
}

.profile-item-container:hover{

    background-color: #F5F5F5;
    cursor: pointer;
}

.profile-item-container .img{

    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100%
}

.profile-item-container .barber-name{

    font-size: 16px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/customer/profile-item/ProfileItem.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,6BAA6B;AACjC;;AAEA;;IAEI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB;AACJ;;AAEA;;IAEI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".profile-item-container{\n\n    width: 150px;\n    height: 150px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    border-radius: 4px;\n    gap: 10px;\n    border: 0;\n    background-color: transparent;\n}\n\n.profile-item-container:hover{\n\n    background-color: #F5F5F5;\n    cursor: pointer;\n}\n\n.profile-item-container .img{\n\n    width: 80px;\n    height: 80px;\n    object-fit: cover;\n    border-radius: 100%\n}\n\n.profile-item-container .barber-name{\n\n    font-size: 16px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
