import { useState } from 'react';
import { MainInput, MainSelect, MaskedInput, SelectIpt } from '../../../../components/commom/form-input/FormInput';
import "./Informations.css";
import { getAddress } from '../../../../utils/functions/Others';
import SelectInput from '@mui/material/Select/SelectInput';
import { states } from '../../../../utils/Data';
import axios from 'axios';
import LoadingPopup from '../../../../components/commom/loading-pop-up/LoadingPopUp';
import moment from 'moment';
import { FaRegImages } from 'react-icons/fa';
import { useAuth } from '../../../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import loadingBlack from "../../../../utils/images/loading_black.gif";

const Informations = () => {

    const { user } = useAuth();

    const [informations, setInformations] = useState({

        id: user.id,
        image: null,
        masterName: user.masterName,
        enterpriseName: user.enterpriseName,
        whatsapp: user.whatsapp,
        aditionalPhoneNumber: user.aditionalPhoneNumber,
        email: user.email,
        postalCode: user.postalCode,
        street: user.street,
        streetNumber: user.streetNumber,
        neighborhood: user.neighborhood,
        city: user.city,
        state: user.state,
        modifiedAt: moment().utc()
    });

    const [state, setState] = useState("empty");

    const notifyError = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    const handleInputs = (e) =>{

        if(e != undefined || e != null){

            if(e.target.name == "postalCode"){

                setInformations({...informations, [e.target.name]: e.target.value});


                getAddress(e.target.value).then(res=>{

                    if(res != undefined){

                        setInformations({
                            ...informations,
                            postalCode: e.target.value,
                            street: res.logradouro,
                            neighborhood: res.bairro,
                            city: res.localidade,
                            state: res.uf,
                        });
                    }

                    
                });
            }else if(e.target.name == "image"){


                setInformations({...informations, [e.target.name]: e.target.files[0]});

            }else{

                setInformations({...informations, [e.target.name]: e.target.value});

            }
        } 
    } 


    const checkSavedData = () => {

        if(
            informations.image != null ||
            user.masterName != informations.masterName ||
            user.enterpriseName != informations.enterpriseName ||
            user.whatsapp != informations.whatsapp ||
            user.email != informations.email ||
            user.postalCode != informations.postalCode ||
            user.street != informations.street ||
            user.streetNumber != informations.streetNumber ||
            user.neighborhood != informations.neighborhood ||
            user.city != informations.city ||
            user.state != informations.state

        ){

            return true;
        }else{

            return false
        }
    }

    const cancel = () => {

        setInformations({

            ...informations,
            image: null,
            masterName: user.masterName,
            enterpriseName: user.enterpriseName,
            whatsapp: user.whatsapp,
            email: user.email,
            postalCode: user.postalCode,
            street: user.street,
            streetNumber: user.streetNumber,
            neighborhood: user.neighborhood,
            city: user.city,
            state: user.state,
        });
    }

    const handleSubmitInformations = (e) => {

        e.preventDefault();

        setState("loading");

        if(informations.image != null){

            const formdata = new FormData();
            formdata.append('barberId', user.id);
            formdata.append('image', informations.image);

            axios.post("/api/account/enterprise-info/image", formdata)
            .then(resImage=>{

                if(resImage.data.success){

                    axios.post(`/api/account/enterprise-info/${user.id}`, {

                        ...informations,
                        image: resImage.data.image
                    })
                    .then(res=>{

                        if(res.data.success){

                            setTimeout(()=>{

                                setState("success");

                                setTimeout(()=>{
                                    window.location.reload();
                                    setState("empty");
                                }, 1500);
                                
                            }, 1500);
                        }
                    })
                    .catch(err=>{

                        //ENTERPRISE INFO WITH IMAGE

                        setTimeout(()=>{

                            setState("empty");
                    
                            if(err.response.status === 500){
                    
                                notifyError(err.response.data.message);
                            }else if(err.response.status === 400){

                                notifyError(err.response.data.message);
                            }else{
                                
                            }
                        }, 1500);
                    });
                }
            })
            .catch(err=>{

                //IMAGE

                setTimeout(()=>{

                    setState("empty");
            
                    if(err.response.status === 500){
            
                        notifyError(err.response.data.message);
                    }else{
                        
                    }
                }, 1500);
            });
        }else{

            axios.post(`/api/account/enterprise-info/${user.id}`, {


                ...informations,
                image: user.image

            })
            .then(res=>{

                if(res.data.success){

                    setTimeout(()=>{

                        setState("success");

                        window.location.reload();

                        setTimeout(()=>{
        
                            setState("empty");
                        }, 1500);
                        
                    }, 1500);
                }
            })
            .catch(err=>{

                //ENTERPRISE INFO WITHOUT IMAGE

                setTimeout(()=>{

                    setState("empty");
            
                    if(err.response.status === 500){
            
                        notifyError(err.response.data.message);
                    }else if(err.response.status === 400){

                        notifyError(err.response.data.message);
                    }else{
                        
                    }
                }, 1500);
            });
        }
    }

    if(
        user == null
      ){
    
        return <div style={{height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", boxSizing: "border-box", gap: "10px"}}>
                <img style={{width: "50px", height: "50px", fontSize: "16px", fontWeight: "700"}} src={loadingBlack}/>
                <span style={{fontSize: "16px", fontWeight: "600"}}>Carregando...</span>
              </div>
      }

  return (
    <div className='informations'>

        <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
        />
        <ToastContainer />

        {

            state === "loading"
            ? <LoadingPopup state={"loading"} message={"Salvando alterações..."}/>
            : state === "error"
                ? <LoadingPopup state={"error"} message={"Erro ao salvar alterações, tente novamente."}/>
                : state === "success"
                    ? <LoadingPopup state={"success"} message={"Alterações salvas com sucesso!"}/>
                    : null
        }

        <div className="box">
            <div className="fields">
                
                <div className="main">
                    <div className="image">
                        <input name='image' type="file" id='change-image-ipt' onChange={(e)=>{handleInputs(e)}}/>
                        {

                            informations.image != null
                                ? <img src={URL.createObjectURL(informations.image)} alt="" />
                                :user.image != null

                                    ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/barber/${user.image}`} alt="" />
                                    :<div className='icon-container'><FaRegImages/></div>
                        }
                        
                        <span className="change-image" onClick={()=>{document.getElementById("change-image-ipt").click()}}>Mudar imagem de perfil</span>
                    </div>
                    <h3>Informações principais</h3>

                    <MainInput
                        data={{

                            type: "text",
                            name: "masterName",
                            placeholder: "Nome do responsável",
                            label: "Nome do responsável",
                            value: informations.masterName,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />

                    <MainInput
                        data={{

                            type: "text",
                            name: "enterpriseName",
                            placeholder: "Nome da empresa",
                            label: "Nome da empresa",
                            value: informations.enterpriseName,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />

                    <MaskedInput
                        data={{

                            type: "text",
                            name: "whatsapp",
                            placeholder: "Whastapp",
                            label: "Whatsapp",
                            mask: "(99) 99999-9999",
                            value: informations.whatsapp,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />

                    <MaskedInput
                        data={{

                            type: "text",
                            name: "aditionalPhoneNumber",
                            placeholder: "Telefone adicional",
                            label: "Telefone adicional (opcional)",
                            mask: "(99) 99999-9999",
                            value: informations.aditionalPhoneNumber,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />

                    <MainInput
                        data={{

                            type: "email",
                            name: "email",
                            placeholder: "E-mail",
                            label: "E-mail",
                            value: informations.email,
                            required: true,
                            disabled: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />
                </div>

                <div className="address">
                    <h3>Endereço</h3>
                    <MaskedInput
                        data={{

                            type: "number",
                            name: "postalCode",
                            placeholder: "XXXXX-XXX",
                            label: "C.E.P",
                            mask: "99999-999",
                            value: informations.postalCode,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />
                    <MainInput
                        data={{

                            type: "text",
                            name: "street",
                            placeholder: "Rua",
                            label: "Rua",
                            value: informations.street,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />
                    <MainInput
                        data={{

                            type: "text",
                            name: "streetNumber",
                            placeholder: "Número",
                            label: "Número",
                            value: informations.streetNumber,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />
                    <MainInput
                        data={{

                            type: "text",
                            name: "neighborhood",
                            placeholder: "Bairro",
                            label: "Bairro",
                            value: informations.neighborhood,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />
                    <MainInput
                        data={{

                            type: "text",
                            name: "city",
                            placeholder: "Cidade",
                            label: "Cidade",
                            value: informations.city,
                            required: true,
                            handleInput: handleInputs,
                            iconName: ""
                        }}
                        iconStyle=""
                    />
                    <MainSelect
                        data={{

                            type: "text",
                            name: "state",
                            placeholder: "Estado",
                            label: "Estado",
                            value: informations.state,
                            required: true,
                            handleInput: handleInputs,
                            iconName: "",
                            list: states
                        }}
                    />

                </div>
            </div>
            {

                checkSavedData() === true
                ? <div className="btns-container">
                    <button type='button' className='cancel' onClick={()=>{cancel()}}>Cancelar</button>
                    <button type='button' className='save' onClick={(e)=>{handleSubmitInformations(e)}}>Salvar alterações</button>
                </div>
            : null
            }
        </div>

    </div>
  )
}

export default Informations