import React, { useState } from 'react';
import "./SignupCustomer.css";
import { AuthInput, MaskedInput } from '../../../components/commom/form-input/FormInput';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../../node_modules/axios/index';
import { useNavigate } from '../../../../node_modules/react-router-dom/dist/index';
import LoadingPopup from '../../../components/commom/loading-pop-up/LoadingPopUp';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import bgcustomer from "../../../utils/images/b2.webp";
import { Colors } from '../../../utils/Colors';
import ReactCodeInput from 'react-code-input';
import { FaArrowLeft } from 'react-icons/fa';

const SignupCustomer = () => {

    const navigate = useNavigate();

    const [state, setState] = useState("");
    const customerD = JSON.parse(localStorage.getItem("customer"));

    const [customer, setCustomer] = useState({

        id: "",
        userType: "customer",
        name: customerD ? customerD.name : "",
        email: customerD ? customerD.email : "",
        phoneNumber: customerD ? customerD.phoneNumber : "",
        password: customerD ? customerD.password : "",
        confirmPassword: customerD ? customerD.confirmPassword : "",
        createdAt: "",
        confirmationCode: customerD ? customerD.confirmationCode : "",
        token: customerD ? customerD.token : "",
        step: customerD ? customerD.step : 1
    });

    const notifyError = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const backStep = () => {

      setCustomer({...customer, step: 1});
      localStorage.setItem("customer", JSON.stringify({
  
        ...customer,
        step: 1
      }));
    }

    const sendConfirmationCode = (e) => {

      if(e != undefined){
  
        e.preventDefault();
  
      }
  
      setState("sending");
  
      axios.post("/api/email/send-confirmation-code-email-customer", {
  
        masterName: customer.name,
        email: customer.email
      })
      .then(res=>{
  
        if(res.data.success === true){
  
          setTimeout(()=>{
  
            setState("sended");
            setCustomer({...customer, token: res.data.data.token, step: 2});
            localStorage.setItem("customer", JSON.stringify({
  
              ...customer,
              token : res.data.data.token,
              step: 2
            }));
            setTimeout(()=>{
              setState("empty");
            }, 1500);
  
          }, 1500);
  
          
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 500){

            notifyError(err.response.data.message);
          }
        }, 1500);
  
        
      });
    }

    const validateFields = (name) =>{

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    
        if(name === "name"){
    
          if(customer.name != "" && customer.name.length >= 3 && customer.name.length <= 50){
    
            return false;
          }else{
    
            return true;
          }
        } else if(name == "phoneNumber"){
    
    
            let str1 = customer.phoneNumber.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", "");
            if(customer.phoneNumber != "" && str1.length == 11){
              return false;
            }else{
        
              return true;
            }
          } else if(name == "email"){
    
            if(customer.email != "" && emailRegex.test(customer.email)){
    
              return false;
            }else{
    
              return true;
            }
          } else if(name === "password"){
    
            if(customer.password != "" && passRegex.test(customer.password)){
    
              return false;
            }else{
    
              return true;
            }
          }else if(name === "confirmPassword"){
    
            if(customer.confirmPassword != "" && customer.password === customer.confirmPassword){
    
              return false;
            }else{
    
              return true;
            }
          }else if(name === "confirmationCode"){

            if(customer.confirmationCode.length == 6){

              return false;
            }else{
    
              return true;
            }
          }
      
      }

    const handleInputs = (e) => {

        setCustomer({...customer, [e.target.name] : e.target.value });
    }

    const register = (e) => {

        e.preventDefault();

        setState("loading");

        const id = uuidv4();
        const createdAt = moment().utc();

        axios.post("/api/signup/verify-email-confirmation-code", {

          confirmationCode: customer.confirmationCode,
          token: customer.token
        })
        .then(resCode=>{

          if(resCode.data.success === true){

            axios.post("/api/customer-signup", {
              id: id,
              userType: customer.userType,
              name: customer.name,
              phoneNumber: customer.phoneNumber,
              email: customer.email,
              password: customer.password,
              createdAt: createdAt
          })
          .then(res=>{
  
              if(res.data != null){
  
                  setTimeout(()=>{
  
                      setState("success");
                      setTimeout(()=>{
  
                          setState("");
  
                          setCustomer({
                              id: "",
                              userType: "customer",
                              name: "",
                              email: "",
                              phoneNumber: "",
                              password: "",
                              confirmPassword: "",
                              createdAt: "",
                              token: "",
                              step: 1,
                              confirmationCode: ""
                          });
  
                          navigate("/acessar-conta-cliente");
                      }, 1500);
                  }, 1500);
              }
          })
          .catch(err=>{
  
              setState("error");
              notifyError("Erro ao criar conta, tente novamente.")
              setTimeout(()=>{
  
                  setState("")
              }, 1500);
          });
          }
        })
        .catch(err=>{


        });

        
    }

    return(

      <div className='signup-customer'>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
        <ToastContainer />
          {
  
            state === "loading"
              ? <LoadingPopup state={"loading"} message={"Criando conta..."}/>
              : state === "success"
                ? <LoadingPopup state={"success"} message={"Conta criada com sucesso!"}/>
                : state === "sending"
                  ? <LoadingPopup state={"loading"} message={"Enviando..."}/>
                  : state === "sended"
                    ? <LoadingPopup state={"success"} message={"Novo código enviado!"}/>
                    : null
          }
        {/*<div className='theme-switch'><ThemeSwitch/></div>*/}
        <div className='left'>

          {

            customer.step === 1
              ? <div className='box'>
              <h1>Criar uma conta</h1>
    
              <form onSubmit={(e)=>{sendConfirmationCode(e)}} className='inputs'>
    
                <AuthInput
                  data={{
                    error: "O nome deve ter entre 3 e 50 caracteres.",
                    hasError: customer.name != "" ? validateFields("name").toString() : null,
                    iconName: "person",
                    label: "Nome do responsável",
                    placeholder: "Nome do responsável",
                    name: "name",
                    type: "text",
                    required: true,
                    value: customer.name,
                    handleInput: handleInputs
                  }}
                  iconStyle={{
    
                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                <MaskedInput
                  data={{
                    error: "Número inválido!",
                    hasError: customer.phoneNumber != "" ? validateFields("phoneNumber").toString() : null,
                    iconName: "phone",
                    aditionalClass: "",
                    label: "Número de telefone",
                    placeholder: "Número de telefone",
                    name: "phoneNumber",
                    type: "text",
                    mask: "(99) 99999-9999",
                    required: true,
                    value: customer.phoneNumber,
                    handleInput: handleInputs
                  }}
                  iconStyle={{
    
                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                  fetchAddress={null}
                />
                <AuthInput
                  data={{
                    error: "E-mail inválido!",
                    hasError: customer.email != "" ? validateFields("email").toString() : null,
                    iconName: "envelope",
                    label: "E-mail",
                    placeholder: "E-mail",
                    name: "email",
                    type: "email",
                    required: true,
                    value: customer.email,
                    handleInput: handleInputs
                  }}
                  iconStyle={{
    
                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                <AuthInput
                  data={{
                    error: "A senha deve conter no mínimo 8 caracters, pelo menos 1 letra, 1 número e 1 carctere especial.",
                    hasError: customer.password != "" ? validateFields("password").toString() : null,
                    iconName: "lock",
                    label: "Senha",
                    placeholder: "Senha",
                    name: "password",
                    type: "password",
                    required: true,
                    value: customer.password,
                    handleInput: handleInputs
                  }}
                  iconStyle={{
    
                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                <AuthInput
                  data={{
                    error: "As senhas digitadas não conferem.",
                    hasError: customer.confirmPassword != "" ? validateFields("confirmPassword").toString() : null,
                    iconName: "lock",
                    label: "Confirmar senha",
                    placeholder: "Confirmar senha",
                    name: "confirmPassword",
                    type: "password",
                    required: true,
                    value: customer.confirmPassword,
                    handleInput: handleInputs
                  }}
                  iconStyle={{
    
                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                {
                  !validateFields("name") &&
                  !validateFields("phoneNumber") &&
                  !validateFields("email") &&
                  !validateFields("password") &&
                  !validateFields("confirmPassword")
                  ? <button type="submit">Confirmar e-mail e continuar</button>
                
                  : <button disabled={true} type="submit">Confirmar e-mail e continuar</button>
    
                }
              </form>
              
              <span className='have-account'>Já tem conta? <span className='link' onClick={()=>{navigate("/acessar-conta-cliente")}}>Acesse aqui.</span></span>
              </div>
            : <div className='box-email-confirmation'>
                <h2>Confirme seu e-mail</h2>
                <span>Enviamos um código de verificação de 6 dígitos para seu e-mail, digite o código para continuar o cadastro.</span>
                <ReactCodeInput type='text' fields={6} inputStyle={{}} onChange={(e)=>{handleInputs({target:{value: e, name: "confirmationCode"}})}} />
                <span className='resend-code' onClick={()=>{sendConfirmationCode()}}>Reenviar código</span>
                {
                  !validateFields("name") &&
                  !validateFields("phoneNumber") &&
                  !validateFields("email") &&
                  !validateFields("password") &&
                  !validateFields("confirmPassword") &&
                  !validateFields("confirmationCode") 
                  ? <button type="button" onClick={(e)=>{register(e)}}>Confirmar e criar conta</button>
                
                  : <button disabled={true} >Verificar e criar conta</button>

                }

                <span className='back-btn' onClick={()=>{backStep()}}><FaArrowLeft className='icon'/>Voltar </span>
            </div>
          }
        </div>
        <div className='right'>
          <img className='bg' src={bgcustomer}></img>
          {/*<img src={signinbg}></img>*/}
        </div>
      </div>
    );
}

export default SignupCustomer;