import { formatToSum } from "./Formatters";

export const grossRevenue = (schedules) => {

    let sum = 0;

    schedules.map(schedule=>{

      const fValue = schedule.servicePrice.replaceAll("R$", "").replaceAll(",", ".");

      sum = sum + parseFloat(fValue);
    });



    return sum.toFixed(2);
}

export const totalComissionsForEachProfessional = (professionals, schedules) => {

    const array = [];

    professionals.map(professional=>{

        let sum = 0;

        schedules.map(schedule=>{

            if(professional.id == schedule.professionalId){

                sum += formatToSum(schedule.servicePrice, "pt-BR", "BRL");

            }

        });

        if(professional.id == 0){

            array.push({professional: professional, totalComission: sum});
        }else{

            let totalComission = ((sum * professional.comission) / 100);
            array.push({professional: professional, totalComission: totalComission});
        }
        

    });

    return array;

}

export const totalComissions = (professionals, schedules) => {

    let sum = 0;

    totalComissionsForEachProfessional(professionals, schedules).map(totalComissionForEachProfessional=>{

        sum += totalComissionForEachProfessional.totalComission;
    });

    return sum.toFixed(2);
}

export const totalRevenueForEachProfessional = (professionals, schedules) => {

    let array = [];

    professionals.map(professional=>{

        let sum = 0;

        schedules.map(schedule=>{

            if(professional.id === schedule.professionalId){

                sum += formatToSum(schedule.servicePrice, "pt-BR", "BRL");
            }
        });

        array.push({professional: professional, total: sum});

    });

    return array;
}

export const totalRevenueForEachServiceType = (services, schedules) => {

    let array = [];

    services.map(service=>{

        let sum = 0;
        schedules.map(schedule=>{

            if(service.id == schedule.serviceId){

                sum += formatToSum(schedule.servicePrice, "pt-BR", "BRL");

            }
        });

        array.push({service: service, total: sum})
    });

    return array;
}

export const grossRevenueForBarChart = () => {


}

export const totalServicesForEachProfessional = (professionals, schedules) => {

    let array = [];

    professionals.map(professional=>{

        let sum = 0;

        schedules.map(schedule=>{

            if(professional.id == schedule.professionalId){

                sum += 1;
            }
        });

        array.push({professional: professional, total: sum});
    });

    return array;
}

export const totalQuantityForEachServiceType = (services, schedules) => {

    let array = [];

    services.map(service=>{

        let sum = 0;

        schedules.map(schedule=>{

            if(service.id == schedule.serviceId){

                sum += 1;
            }
        });

        array.push({service: service, total: sum});
    });

    return array;
}

export const calcPerformance = (totalServicesForEachProfessional) => {


    const heigherPerformance = totalServicesForEachProfessional.reduce((heigher, obj) => {

      return (obj.total > heigher.total) ? obj : heigher;
    }, totalServicesForEachProfessional[0]);

    const lowerPerformance = totalServicesForEachProfessional.reduce((heigher, obj) => {
      return (obj.total < heigher.total) ? obj : heigher;
    }, totalServicesForEachProfessional[0]);

    return {heigherPerformance: heigherPerformance, lowerPerformance: lowerPerformance}

  }
