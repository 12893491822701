import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilter, FaPen, FaTrashAlt, FaUser } from "react-icons/fa";
import { IoMdAdd, IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Colors } from "../../../Color";
import DeleteConfirmPopUp from "../../../components/commom/delete-confirm-pop-up/DeleteConfirmPopUp";
import { DatePickerCustom } from "../../../components/commom/form-input/FormInput";
import { useAuth } from "../../../context/AuthContext";
import { GeneralBarsContext } from "../../../context/generalBarsContext";
import "./ScheduleBarber.css";

const ScheduleBarber = () => {

  const { user } = useAuth();

  const navigate = useNavigate();

  const selectedDateFilter = JSON.parse(sessionStorage.getItem("schedulesPeriod"));

  const {setAddScheduleBarState, addScheduleBarState, setAddScheduleBarTriggerName, addScheduleBarTriggerName, setSelectedScheduleData, selectedScheduleData} = useContext(GeneralBarsContext);

  const [schedulesList, setSchedulesList] = useState([]);
  const [professionalsList, setProfessionalsList] = useState([]);

  const [startDate, setStartDate] = useState(selectedDateFilter != null ? moment(selectedDateFilter.startDate).utc() : moment().utc());
  const [endDate, setEndDate] = useState(selectedDateFilter != null ? moment(selectedDateFilter.endDate).utc() :moment().utc());
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [deletePopUpTrigger, setDeletePopUpTrigger] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();
  const [filtersTrigger, setFiltersTrigger] = useState(false);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const callFunctions = (row) =>{

    setSelectedScheduleData(row);
    setAddScheduleBarTriggerName("edit");
    setAddScheduleBarState(!addScheduleBarState);
  }

  const callFunctionsBtn = () =>{

    setSelectedScheduleData(null);
    setAddScheduleBarTriggerName("add");
    setAddScheduleBarState(!addScheduleBarState);
  }

  const deleteCallFunctions = (state, row) => {

    setDeletePopUpTrigger(state);
    setRowToDelete(row);
  }

  const getColorStatus = (status) =>{

    if(status == "done"){

      return "green";

    }else if(status == "not done"){

      return "red";

    }else if(status == "pending"){

      return Colors.yellow;

    }
  }

  const getStatusText = (status) => {

    if(status == "done"){

      return "Finalizado";

    }else if(status == "not done"){

      return "Cancelado";

    }else if(status == "pending"){

      return "Pendente";

    }
  }

  const deleteSchedule = (e, row) => {

    e.preventDefault();

    axios.delete(`/api/schedule/delete-schedule/${row.id}`)
    .then(res=>{

      if(res.data != null && res.data != "Error"){

        navigate("/agenda");

      }
    })
    .catch(err=>{

      setTimeout(()=>{

        if(err.response.status === 404){
  
          notifyError(err.response.data.message);

        }else if(err.response.status === 500){

          notifyError(err.response.data.message);

        }else{

        }
      }, 1500);
    });
  }


  const filter = () => {

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');
    
    setShowDateFilter(!showDateFilter);
    setFiltersTrigger(!filtersTrigger);

    sessionStorage.setItem("schedulesPeriod", JSON.stringify({startDate: startDate, endDate: endDate}))
    axios.get('/api/schedules', {
      params:{
        barberId: user.id,
        barberEmail: user.email,
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD")
      }
    })
    .then(res=>{

      if(res.data.success === true){
        
        setSchedulesList(res.data.data);
      }
    })
    .catch(err=>{

      setTimeout(()=>{

        if(err.response.status === 404){

        }else if(err.response.status === 500){

          notifyError(err.response.data.message);

        }else{

        }
      }, 1500);
    });
  }


  const columns = [

    {
      name: 'Cliente',
      sortable: false,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>
            {row.customerName}
          </div>
      },

    },

    {
        name: 'Profissional',
        sortable: false,
        cell: (row)=>{

          return <>
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <ToastContainer />
            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", gap: "10px"}}>
                {
                  professionalsList.length > 0
                    ? professionalsList.find(professional => professional.id == row.professionalId)?.image != null && professionalsList.find(professional => professional.id == row.professionalId)?.image != ""

                      ? <img style={{width: "60px", height: "60px", objectFit: "cover", borderRadius: "100%"}} src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${user.id}/${professionalsList.length > 0 ? professionalsList.find(professional => professional.id == row.professionalId)?.image : null}`}/>
                      : <div style={{width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "lightgray"}}><FaUser style={{width: "22px", height: "22px", color: "white"}}/></div>
                    : <div style={{width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "lightgray"}}><FaUser style={{width: "22px", height: "22px", color: "white"}}/></div>
                }
                
                <div style={{display: "flex", justifyContent: "flex-start", fontWeight: "600", fontSize: "14px"}}>{professionalsList.length > 0 ? professionalsList.find(professional => professional.id == row.professionalId)?.name : null}</div>
              </div>
          </>
        },

    },

    {
        name: 'Serviço',
        selector: row => row.serviceName,
        sortable: true,
        cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.serviceName}</div>
        }
    },

    {
      name: 'Preço',
      selector: row => row.servicePrice,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.servicePrice}</div>
      }

    },

    {
      name: 'Data',
      selector: row => row.date,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{moment(row.scheduleDateTime).format("DD/MM/YYYY")}</div>
      }

    },

    {
      name: 'Horário',
      selector: row => row.startTime,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{moment(row.scheduleDateTime).format("HH:mm") + " - " + moment(row.scheduleDateTime).add(row.serviceDuration, "minutes").format("HH:mm")}</div>
      }

    },

    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px", alignItems: "center"}}><span style={{color: getColorStatus(row.status)}}>{getStatusText(row.status)}</span></div>
      }

    },
    {
      name: 'Ação',
      selector: row => row.action,
      sortable: false,
      cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", flexDirection: "row", gap: "10px"}}>
                    <button style={{padding: "10px 12px", outline: 0, background: "#5A6B7D", border: 0, color: "white", fontWeight: "600", cursor: "pointer", borderRadius: "4px"}} onClick={()=>{callFunctions(row)}}><FaPen />
                    </button>
                    <button style={{padding: "10px 12px", outline: 0, background: "#EE3F2D", border: 0, color: "white", fontWeight: "600", cursor: "pointer", borderRadius: "4px"}} onClick={()=>{deleteCallFunctions(true, row)}}><FaTrashAlt />
                    </button>
                 </div>
      }
      
    },
  ];

  const customStyles = {
    rows: {
        style: {
          width: "100%",
          borderBox: "box-sizing",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "stretch",
          backgroundColor: "#FAFAFA",
          padding: "10px 0px",
          marginTop: "12px",
          borderRadius: "4px",
          borderBottom: "1px solid #EEEEEE !important"
        },
    },
    headCells: {
        style: {
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px"
        },
    },
    cells: {
        style: {
            
        },
    },
  };

  useEffect(()=>{


    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    axios.get('/api/schedules', {
      params:{
        barberId: user.id,
        barberEmail: user.email,
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD")
      }
    })
    .then(res=>{

      if(res.data.success === true){

        setSchedulesList(res.data.data);
      }
    })
    .catch(err=>{

      setTimeout(()=>{

        if(err.response.status === 404){


        }else if(err.response.status === 500){

          notifyError(err.response.data.message);

        }else{

        }
      }, 1500);
    });

    axios.get(`/api/professionals-by-barber/${user.id}`)
    .then(res=>{

      if(res.data.success){

        setProfessionalsList(res.data.data);
      }
    })
    .catch(err=>{

      setTimeout(()=>{

        if(err.response.status === 404){
  
          notifyError(err.response.data.message);

        }else if(err.response.status === 500){

          notifyError(err.response.data.message);

        }else{

        }
      }, 1500);

    });
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {
        filtersTrigger
          ? <div className="filters-mobile-container">
              <div className="top">
                <IoMdClose onClick={()=>{setFiltersTrigger(!filtersTrigger)}} />
              </div>
              <div className="date">
                <span>Data:</span>
                <div className="row">
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
                </div>
              </div>
              
              <button className="filter" onClick={()=>{filter()}}>Filtrar</button>

          </div>
          : null
      }
      {

        deletePopUpTrigger
        ? <DeleteConfirmPopUp message={"Deletar esse agendamento?"} data={rowToDelete} cancel={setDeletePopUpTrigger} action={deleteSchedule} cancelBtnText={"Cancelar"} actionBtnText={"Deletar"}/>
        : null
      }
    
      <div className="schedules">
      
        <div className="top">
          <div className="filters-desktop">
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
            <button className="search" onClick={()=>{filter()}}>Pesquisar</button>
          </div>

          <button className="filters-mobile" onClick={()=>{setFiltersTrigger(!filtersTrigger)}}>
            <FaFilter />
          </button>

          <button onClick={()=>{callFunctionsBtn()}} className="add-schedulling-btn"><IoMdAdd style={{fontSize: "22px", color: "white"}}/>Novo Agendamento</button>
        </div>
        <div className="schedullings-list-container">
          <DataTable
            columns={columns}
            data={schedulesList}
            customStyles={customStyles}
            selectableRows
        />
        </div>
      </div>
    </>
  )
}

export default ScheduleBarber;