import ReactPixel from 'react-facebook-pixel';

const initFacebookPixel = () => {
  ReactPixel.init(process.env.FACEBOOK_PIXEL_ID); // Rastrear a visualização de página
};

/*const trackEvent = ({eventName, data, userData, customData}) => {
  ReactPixel.track(eventName, data);

  // Também envie o evento para a API de Conversão
  axios.post(`https://graph.facebook.com/v20.0/${process.env.FACEBOOK_PIXEL_ID}/events?access_token=${process.env.FACEBOOK_ACCESS_TOKEN}`, {
    event_name: eventName,
    event_time: Math.floor(new Date() / 1000),
    event_id: uuidv4(),
    data: data,
    user_data: userData,
    custom_data: customData
  });
};*/

export { initFacebookPixel };
