// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.my-schedules{

    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
}

.my-schedules-container{

    height: 100%;
    flex-direction: column;
    justify-content: stretch;
    background-color: white;
    border-radius: 10px;
    padding: 32px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    margin: 20px;
    box-sizing: border-box;
}

.my-schedules-title{

    margin-bottom: 30px;
}

.my-schedules-list-container{

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/views/customer/customer-schedules/MySchedules.css"],"names":[],"mappings":";AACA;;IAEI,SAAO;IACP,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;;IAEI,YAAY;IACZ,sBAAsB;IACtB,wBAAwB;IACxB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,8CAA8C;IAC9C,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;;IAEI,mBAAmB;AACvB;;AAEA;;IAEI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,gBAAgB;AACpB","sourcesContent":["\n.my-schedules{\n\n    flex: 1;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    box-sizing: border-box;\n}\n\n.my-schedules-container{\n\n    height: 100%;\n    flex-direction: column;\n    justify-content: stretch;\n    background-color: white;\n    border-radius: 10px;\n    padding: 32px;\n    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);\n    margin: 20px;\n    box-sizing: border-box;\n}\n\n.my-schedules-title{\n\n    margin-bottom: 30px;\n}\n\n.my-schedules-list-container{\n\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
