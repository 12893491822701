import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { GetSlotsForAnyProfessional, GetSlotsForSpecificProfessional } from '../../../../../utils/functions/SlotsManager';
import "./MakeBookingPopUp.css";

const MakeBookingPopUp = ({setSelectedSlot, selectedSlot, handleInput, handleInputService, handleInputProfessional, scheduleData, services, barberData, businessRules, setTrigger, setProfessionalsList}) => {
  
  const [professionals, setProfessionals] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [slots, setSlots] = useState([]);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(()=>{

    handleInputService(scheduleData.serviceId)

    const fetchData = async () => {

      if(scheduleData.serviceId != ""){

        const p2sReq = await axios.get(`/api/professionals-to-services/${scheduleData.serviceId}`);

        Promise.all(

          p2sReq.data.data.map(async(p2s)=>{

            let res;

            res = await axios.get(`/api/professionals/${p2s.professionalId}`);

            return res.data;
          })
        )
        .then(res=>{

          let professionalsArray = [];
          res.map((professional)=>{

            professionalsArray.push(professional.data)
          });
          setProfessionalsList(professionalsArray)
          setProfessionals(professionalsArray);
        });
      }

    }

    fetchData()
  }, [scheduleData.serviceId]);

  useEffect(()=>{

    if(scheduleData.date != null && scheduleData.professionalId != "" && scheduleData.serviceId != ""){

      handleInputProfessional(scheduleData.professionalId)
      getSchedules()
    }

  }, [scheduleData.professionalId, scheduleData.serviceId, scheduleData.date]);

  useEffect(()=>{


  }, [selectedSlot])

  const getSchedules = async () => {

    if(businessRules != null){

      const selectedDay = moment(scheduleData.date).format("dddd");

      if(businessRules[selectedDay.toLocaleLowerCase()] == 0){
            
        return setSlots([]);
      }


      const date = moment.tz(scheduleData.date, userTimezone).startOf('day').format("YYYY-MM-DD");

      try{

        const schedulesRequest = await axios.get("/api/schedules/scheduled-services-by-date", { params:{

            barberId: barberData.id,
            professionalId: scheduleData.professionalId,
            date: date,
        }});

        findTimeSlots(schedulesRequest.data.data, scheduleData.professionalId)

        setSchedules(schedulesRequest.data);

      }catch(err){

        if(err.response.status == 404){

          findTimeSlots([]);
          
      }
      }
    }

  }

  const findTimeSlots = (schedules, professionalId) => {

    setSlots(GetSlotsForSpecificProfessional(businessRules.initialWorkTime, businessRules.endWorkTime, businessRules.initialLunchTime, businessRules.endLunchTime, schedules, professionalId, scheduleData.serviceDuration, businessRules.minTimeService));

  }

  return (
    <div className='make-booking-popup-bg'>
      
      <div className="box" >
        <IoMdClose className="icon" onClick={()=>{setTrigger(false)}}/>
        <div className="rows">
          <div className="row">

            <h4>Selecione a data</h4>
            <input min={moment().format('YYYY-MM-DD')} name='date' type="date" value={scheduleData.date} onChange={(e)=>{handleInput(e)}}/>
          </div>
          <div className="divider"/>
          <div className="row">
            <h4>Selecione um serviço</h4>
            <div className="list">
              {

                services != null && services.length > 0
                  ? services.map(p=>{

                    return <button onClick={()=>{handleInputService(p.id)}} className={`item ${scheduleData.serviceId === p.id ? "active" : ""}`}>
                      <span>{p.name}</span>
                    </button>
                  })
                  : null
              }
            </div>
          </div>
          <div className="divider"/>
          <div className="row">
            <h4>Selecione um profissional</h4>
            <div className="list">
              {

                professionals != null && professionals.length > 0
                  ? professionals.map(p=>{

                    return <button onClick={()=>{handleInputProfessional(p.id)}} className={`item ${scheduleData.professionalId === p.id ? "active" : ""}`}>
                      {
                        p.image != null && p.image != ""
                          ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${barberData.id}/${p.image}`}/>

                          : <div style={{width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "lightgray"}}><FaUser style={{width: "22px", height: "22px", color: "white"}}/></div>
                      }
                      <span>{p.name}</span>
                    </button>
                  })
                  : null
              }
            </div>
          </div>
          <div className="divider"/>
          <div className="row">
            <h4>{slots.length !== 0 ? "Horários disponíveis" : null}</h4>
              <div className="times">

                {

                  slots.length !== 0
                  ? slots.map((slot, index)=>{

                      return <button onClick={()=>{setSelectedSlot({index: index, slot: slot})}} className={`slot ${selectedSlot.index == index ? "active" : null}`}>{slot}</button>
                    })
                  : <span style={{flex: 1, textAlign: "center"}}>Não funcionamos hoje!</span>

                }
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MakeBookingPopUp