import { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { BiImageAdd } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../../../../node_modules/axios/index';
import { Colors } from '../../../Color';
import DeleteConfirmPopUp from '../../../components/commom/delete-confirm-pop-up/DeleteConfirmPopUp';
import { useAuth } from '../../../context/AuthContext';
import Divider from '../../../generalComponents/fragments/Divider';
import { GetEndTime } from '../../../utils/functions/SlotsManager';
import "./MySchedules.css";
import moment from 'moment';
import { FaUser } from 'react-icons/fa';

const MySchedules = () => {

  const { user } = useAuth();

  const navigate = useNavigate();

  const [schedulesList, setSchedulesList] = useState([]);
  const [professionalsList, setProfessionalsList] = useState([]);
  const [cancelPopUpStatus, setCancelPopUpStatus] = useState(false);
  const [scheduleToCancel, setScheduleToCancel] = useState(null);

  const notifySuccess = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
});

  const notifyError = (message) => toast.error(message, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });

  const getStatusText = (status) => {

    if(status == "done"){

      return "Finalizado";

    }else if(status == "not done"){

      return "Cancelado";

    }else if(status == "pending"){

      return "Pendente";

    }else if(status == "canceled"){

      return "Cancelado";

    }
  }

  const getColorStatus = (status) =>{

    if(status == "done"){

      return "green";

    }else if(status == "not done"){

      return "red";

    }else if(status == "pending"){

      return Colors.yellow;

    }else if(status == "pending"){

      return "lightgray";

    }
  }

  const is3HoursBefore = (row) => {

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    const currentDateHour = moment();
    const scheduleDateHour = moment.tz(row.scheduleDateTime, userTimezone);
    const differenceInHours = scheduleDateHour.diff(currentDateHour, 'hours');

    return differenceInHours >= 3;
  }

  const cancelSchedule = (e, row) => {

    e.preventDefault();

    axios.put('/api/my-schedules/edit-schedule', {params:{

      status: "canceled",
      id: row.id,
      canceledAt: moment().utc()
    }})
    .then(res=>{

      if(res.data.changedRows = 1){

        setTimeout(()=>{

          setCancelPopUpStatus(false);
          navigate("/my-schedules");
          setTimeout(()=>{
            notifySuccess("Agendamento cancelado com sucesso!");
          },1100);
        },1000);
      }
    })
    .catch(err=>{

      if(err.response.status === 500){

        notifyError(err.response.data.message);
      }else{


      }

    });
  }

  const cancelImageTrigger = (schedule) => {

    setCancelPopUpStatus(true);
    setScheduleToCancel(schedule);
  }

  const columns = [

    {
        name: 'Profissional',
        sortable: false,
        cell: (row)=>{

          return <>
                  <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", gap: "10px"}}>
                      {
                        professionalsList.length > 0
                          ? professionalsList.find(professional => professional.id == row.professionalId)?.image != null 
                          && professionalsList.find(professional => professional.id == row.professionalId)?.image != ""

                            ? <img style={{width: "60px", height: "60px", objectFit: "cover", borderRadius: "100%"}} src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${row.barberId}/${professionalsList.length > 0 ? professionalsList.find(professional => professional.id == row.professionalId)?.image : null}`}/>
                            : <div style={{width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "lightgray"}}><FaUser style={{width: "22px", height: "22px", color: "white"}}/></div>
                          : <div style={{width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "lightgray"}}><FaUser style={{width: "22px", height: "22px", color: "white"}}/></div>
                      }
                      
                      <div style={{display: "flex", justifyContent: "flex-start", fontWeight: "600", fontSize: "14px"}}>{professionalsList.length > 0 ? professionalsList.find(professional => professional.id == row.professionalId)?.name : null}</div>
                    </div>
                </>
        },

    },
    {
        name: 'Serviço',
        selector: row => row.serviceName,
        sortable: true,
        cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{row.serviceName}</div>
        }
    },

    {
      name: 'Preço',
      selector: row => row.servicePrice,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{row.servicePrice}</div>
      }

    },

    {
      name: 'Data',
      selector: row => row.date,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{moment(row.scheduleDateTime).format("DD/MM/YYYY")}</div>
      }

    },

    {
      name: 'Horário',
      selector: row => row.startTime,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{moment(row.scheduleDateTime).format("HH:mm") + " - " + moment(row.scheduleDateTime).add(row.serviceDuration, "minutes").format("HH:mm")}</div>
      }

    },

    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 1, justifyContent: "center", fontWeight: "600", fontSize: "14px", alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><span style={{color: getColorStatus(row.status)}}>{getStatusText(row.status)}</span></div>
      }

    },
    {
      name: 'Ação',
      sortable: false,
      cell: (row)=>{

        return <div style={{flex: "1", display: "flex", justifyContent: "center"}}>{
          is3HoursBefore(row) && row.status == "pending"
            ?  <button type='button' onClick={(e)=>{cancelImageTrigger(row)}} style={{width: "80px", height: "30px", borderRadius: "4px", cursor: "pointer", color: "white", background: "red", border: 0}}>Cancelar</button>
            : <span style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center", fontSize: "30px"}}>-</span>
          }</div>
      }

    },
  ];

  const customStyles = {
    rows: {
        style: {
          width: "100%",
          borderBox: "box-sizing",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "stretch",
          backgroundColor: "#FAFAFA",
          padding: "10px 0px",
          marginTop: "12px",
          borderRadius: "4px"
        },
    },
    headCells: {
        style: {
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px"
        },
    },
    cells: {
        style: {
            
        },
    },
  };
  useEffect(()=>{

    const fetchData = async () => {

      const schedulesReq = await axios.get("/api/customer-schedules", {params:{customerId: user.id}});

      setSchedulesList(schedulesReq.data.data)

      if(schedulesReq.data.success && schedulesReq.data.data.length > 0){

        let professionalsArray = [];
        
        schedulesReq.data.data.map(schedule=>{

          professionalsArray.push({barberId: schedule.barberId, professionalId: schedule.professionalId});
        });

      const professionalsReq = await axios.get("/api/customer-schedules/professionals",
          {params:{

            professionalsList: professionalsArray
          }}
        );


        setProfessionalsList(professionalsReq.data.data)
      }

    }

    fetchData()
  },[]);
  
  return (
    <div className='my-schedules'>
      <div className='my-schedules-container'>

        {
          cancelPopUpStatus
            ? <DeleteConfirmPopUp message={"Cancelar esse agendamento?"} cancel={setCancelPopUpStatus} data={scheduleToCancel} action={cancelSchedule} cancelBtnText={"Não"} actionBtnText={"Sim"}/>
            : null
        }

      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
        <ToastContainer />
        <h2 className='my-schedules-title'>Meus agendamentos</h2>
        <Divider/>
        <div className='my-schedules-list-container'>
          <DataTable
                columns={columns}
                data={schedulesList}
                customStyles={customStyles}
                selectableRows
            />
        </div>

      </div>
    </div>
  )
}

export default MySchedules;