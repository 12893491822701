import React, { forwardRef, useState } from 'react';
import "./FormInput.css";
import InputMask from 'react-input-mask';
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import Select from 'react-select';
import { FaEnvelope, FaLock, FaPhoneAlt } from 'react-icons/fa';
import { IoMdPerson } from 'react-icons/io';
import { TbLockCheck } from "react-icons/tb";

registerLocale('br', ptBR);


export const FormInput = ({inputData, aditionalClass, handleInput}) => {

  const [focused, setFocused] = useState(false);


  const handleFocus = (event) =>{

    setFocused(true);
  }

  return (
    <div key={inputData.id} className={`signup-input-container ${aditionalClass}`}>
      <label className='signup-label'>{inputData.label}</label>
      <input 
        className={`signup-input ${aditionalClass}`}
        placeholder={inputData.placeholder} 
        type={inputData.type} 
        name={inputData.name} 
        onChange={(event)=>{handleInput(event)}}
        value={inputData.value}
        required={inputData.required}
        pattern={inputData.pattern}
        onBlur={handleFocus}
        focused={focused.toString()}
        haserror={inputData.hasError}
        disabled={inputData.disabled}
      ></input>
      <span className='signup-input-error'>{inputData.error}</span>
    </div>
  )
}

export const MaskedFormInput = ({inputData, handleInput, aditionalClass, fetchAddress}) => {

  const [focused, setFocused] = useState(false);

  const handleFocus = (event) => {

    setFocused(true);
    if(inputData.name == "postalCode"){

      fetchAddress()
    }
  }

  return(
    <div key={inputData.id} className={`signup-input-container ${aditionalClass}`}>
      <label className='signup-label'>{inputData.label}</label>
      <InputMask 
        className='signup-input'
        placeholder={inputData.placeholder}
        name={inputData.name}
        onChange={(event)=>{handleInput(event)}}
        value={inputData.value}
        mask={inputData.mask}
        required={inputData.required}
        pattern={inputData.pattern}
        onBlur={handleFocus}
        focused={focused.toString()}
        haserror={inputData.hasError}/>
      <span className='signup-input-error'>{inputData.error}</span>
    </div>
    
  );
}

export const SelectInput = ({inputData, aditionalClass, handleInput, professionalsList, servicesList}) => {

  const [focused, setFocused] = useState(false);

  const handleFocus = (event) => {

    setFocused(true);
  }

  return(

    <div className={`signup-input-container ${aditionalClass}`}>
      <label className='signup-label'>{inputData.label}</label>
      <select
        className={`signup-input ${aditionalClass}`}
        placeholder={inputData.placeholder}
        name={inputData.name}
        onChange={(event)=>{handleInput(event)}}
        required={inputData.required}
        pattern={inputData.pattern}
        onBlur={handleFocus}
        focused={focused.toString()}
      >
        {
          inputData.name == "duration"
            ? inputData.options.map((option, index)=>{

              return <option key={index} value={option.value} label={option.label}></option>
            })
            : inputData.name == "intervalTime"
              ? inputData.options.map((option, index)=>{

                return <option key={index} value={option} label={option.label}></option>
              })
              : inputData.name == "professional" && professionalsList != ""
                ? professionalsList.map((professional)=>{


                  return <option key={professional.id} value={professional.id} label={professional.name}></option>
                })
                : inputData.name == "service" && servicesList != ""
                  ? servicesList.map((service)=>{

                    return <option key={service.id} value={service.id} label={service.name}></option>
                  })
                  : inputData.name == "professionalWelcomePage" && professionalsList != ""
                    ? professionalsList.map((professional)=>{
    
    
                      return <option key={professional.id} value={professional.name} label={professional.name}></option>
                    })
                    : ""
          
        }
      </select>
      <span className='signup-input-error'>{inputData.error}</span>
    </div>
  );
}

export const SelectInputMulti = ({inputData, aditionalClass, handleInput, professionalsList, servicesList}) => {

  const [focused, setFocused] = useState(false);

  const handleFocus = (event) => {

    setFocused(true);
  }

  return(

    <div className={`signup-input-container ${aditionalClass}`}>
      <label className='signup-label'>{inputData.label}</label>
      <select
        className={`signup-input ${aditionalClass}`}
        placeholder={inputData.placeholder}
        name={inputData.name}
        onChange={(event)=>{handleInput(event)}}
        required={inputData.required}
        pattern={inputData.pattern}
        onBlur={handleFocus}
        focused={focused.toString()}
        multiple
      >
        {
          inputData.name == "duration"
            ? inputData.options.map((option, index)=>{

              return <option key={index} value={option.value} label={option.label}></option>
            })
            : inputData.name == "intervalTime"
              ? inputData.options.map((option, index)=>{

                return <option key={index} value={option} label={option.label}></option>
              })
              : inputData.name == "professional" && professionalsList != ""
                ? professionalsList.map((professional)=>{


                  return <option key={professional.id} value={professional.id} label={professional.name}></option>
                })
                : inputData.name == "service" && servicesList != ""
                  ? servicesList.map((service)=>{

                    return <option key={service.id} value={service.id} label={service.name}></option>
                  })
                  : inputData.name == "professionalWelcomePage" && professionalsList != ""
                    ? professionalsList.map((professional)=>{
    
    
                      return <option key={professional.id} value={professional.name} label={professional.name}></option>
                    })
                    : ""
          
        }
      </select>
      <span className='signup-input-error'>{inputData.error}</span>
    </div>
  );
}

export const DatePickerInput = ({inputData, date, setDate, aditionalClass, handleInput, recInputData}) => {

  const [focused, setFocused] = useState(false);

  const handleFocus = (event) => {

    setFocused(true);
  }

  return(

    <div className={`signup-input-container ${aditionalClass}`}>
      <label className='signup-label'>{inputData.label}</label>
      <DatePicker
      minDate={inputData.minDate}
        name={inputData.name}
        className='date-piker-test' 
        wrapperClassName='date-piker' 
        locale='br' 
        dateFormat={"dd/MM/yyyy"} 
        placeholderText={new Date().toLocaleDateString("pt-BR")}
        selected={recInputData != null ? new Date(recInputData.substring(0, 10)+"T00:00:00") : date} 
        onChange={(date) => handleInput("", "", "", date, "date")}
      />
    </div>
  );
}

export const SelectIpt = ({inputData, recInputData, aditionalClass}) => {

  return(
    <div className={`select-input ${aditionalClass}`}>
  
      <label>{inputData.label}</label>
      <Select

        isDisabled={inputData.disabled}
        value={inputData.value}
        defaultValue={recInputData != null ? {label: recInputData.label, value: recInputData.value} : null}
        onChange={(value)=>{inputData.handleInput(value)}}
        styles={{
          control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: 'lightgray',
          backgroundColor: '#FFF'
          }),
        }} 
        options={
          inputData.list
            ? inputData.list.map((item)=>{

              return {value: item.id, label: item.name}
            })
          : null
        }
        placeholder={inputData.placeholder}
      />
    </div>
  );
}

export const SelectIptMulti = ({inputData, recInputData, aditionalClass}) => {

  return(
    <div className={`select-input ${aditionalClass}`}>
  
      <label>{inputData.label}</label>
      <Select

        isMulti
        defaultValue={recInputData}
        onChange={(value)=>{inputData.handleInput(value)}}
        styles={{
          control: (baseStyles, state) => ({
          ...baseStyles,
          flex: 1,
          borderColor: '#37474F',
          borderWidth: "2px",
          borderRadius: "10px",
          backgroundColor: '#FFF',
          padding: "2px 0px"
          }),
        }} 
        options={
          inputData.list
            ? inputData.list.map((item)=>{

              return {label: item.name, value: item.id}
            })
          : null
        }
        placeholder={inputData.placeholder}
      />
    </div>
  );
}

export const AuthInput = ({data, iconStyle}) => {

  const [focused, setFocused] = useState(false);

  const handleFocus = (event) => {

      setFocused(true);
  }
  return (
    <div className='auth-input'>
      <label>{data.label}</label>
      <div className='input-container'>
        {getInputIcon(data.iconName, iconStyle)}
        <input
          className='input'
          placeholder={data.placeholder}
          name={data.name}
          type={data.type}
          required={data.required}
          disabled={data.disabled}
          value={data.value}
          pattern={data.pattern}
          autoComplete={data.autoComplete}
          onChange={(e)=>{data.handleInput(e)}}
          onBlur={handleFocus}
        />
      </div>
      {
        data.hasError == "true" && focused
        ? <span className='input-error'>{data.error}</span>
        : null
      }
    </div>
  )
}

export const MainInput = ({data, iconStyle}) => {

  const [focused, setFocused] = useState(false);

  const handleFocus = (event) => {

      setFocused(true);
  }
  return (
    <div className={`main-input ${data.aditionalClass}`}>
      <label>{data.label}</label>
      <div className='input-container'>
        {getInputIcon(data.iconName, iconStyle)}
        <input
          className='input'
          placeholder={data.placeholder}
          name={data.name}
          type={data.type}
          required={data.required}
          disabled={data.disabled}
          value={data.value}
          pattern={data.pattern}
          autoComplete={data.autoComplete}
          onChange={(e)=>{data.handleInput(e)}}
          onBlur={handleFocus}
        />
      </div>
      {
        data.hasError == "true" && focused
        ? <span className='input-error'>{data.error}</span>
        : null
      }
    </div>
  )
}

export const MainSelect = ({data}) => {

  return(
    <div className={`main-select ${data.aditionalClass}`}>
      <label>{data.label}</label>
      <select name={data.name} value={data.value} onChange={(e)=>{data.handleInput(e)}}>
        {
          data.list.map(item=>{
            return <option key={item.id} value={item.id}>{item.name}</option>
          })
        }
      </select>
    </div>
  )
}

export const MainSelectMulti = ({data}) => {

  return(
    <div className={`main-select ${data.aditionalClass}`}>
      <label>{data.label}</label>
      <select multiple={true} name={data.name} value={data.value} onChange={(e)=>{data.handleInput(e)}}>
        {
          data.list.map(item=>{
            return <option value={item.id}>{item.name}</option>
          })
        }
      </select>
    </div>
  )
}

export const MaskedInput = ({data, iconStyle, fetchAddress}) => {

  const [focused, setFocused] = useState(false);

  const handleFocus = (event) => {

    setFocused(true);
  }

  return(
    <div key={data.id} className={`auth-input ${data.aditionalClass}`}>
      <label>{data.label}</label>

      <div className='input-container'>
        {getInputIcon(data.iconName, iconStyle)}
        <InputMask 
          placeholder={data.placeholder}
          name={data.name}
          onChange={(event)=>{data.handleInput(event)}}
          value={data.value}
          mask={data.mask}
          required={data.required}
          pattern={data.pattern}
          onBlur={handleFocus}
          focused={focused.toString()}
          haserror={data.hasError}
          style={{
            margin: "0px",
            padding: "12px 0px",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            borderRadius: "10px",
            outline: "none",
            border: "0",
            backgroundColor: "transparent"
            }}/>
      </div>
      {
        data.hasError == "true" && focused
        ? <span className='input-error'>{data.error}</span>
        : null
      }
    </div>
    
  );
}

export const DatePickerCustom = forwardRef(({ value, onClick }, ref) => (
  <button className="date-picker-custom" onClick={onClick} ref={ref}>
    {value}
  </button>
));

// ===========================================================================================
//                                         FUNCTIONS
// ===========================================================================================

const getInputIcon = (iconName, iconStyle) => {

  switch(iconName){

    case "person":
      return <IoMdPerson style={iconStyle} />
      break;
    case "phone":
      return <FaPhoneAlt style={iconStyle} />
      break;
    case "envelope":
      return <FaEnvelope style={iconStyle} />;
      break;
    case "lock":
      return <FaLock style={iconStyle} />
      break;
    case "lock-check":
      return <TbLockCheck style={iconStyle} />
      break;
    
  }
}