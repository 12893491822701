import React, { useState, createContext } from "react";

export const GeneralBarsContext = createContext({});

export const GeneralBarsContextProvider = ({ children }) => {

// ------------ ADD SERVICE BAR STATES ---------------
const [addServiceBarState, setAddServiceBarState] = useState(false);
const [selectedServiceData, setSelectedServiceData] = useState();
const [addServiceBarTriggerName, setAddServiceBarTriggerName] = useState("");

// ------------ ADD PROFESSIONAL BAR STATES ---------------
const [addProfessionalBarState, setAddProfessionalBarState] = useState(false);
const [selectedProfessionalData, setSelectedProfessionalData] = useState();
const [addProfessionalBarTriggerName, setAddProfessionalBarTriggerName] = useState("");

// ------------ ADD SCHEDULE BAR STATES ---------------
const [addScheduleBarState, setAddScheduleBarState] = useState(false);
const [selectedScheduleData, setSelectedScheduleData] = useState();
const [addScheduleBarTriggerName, setAddScheduleBarTriggerName] = useState("");

const [changePassModalState, setChangePassModalState] = useState(false);
const [loadingModalStates, setLoadingModalStates] = useState({

  active: false,
  loading: false,
  success: false,
  error: false,
  loadingmessage: "",
  successMessage: "",
  errorMessage: ""
});
const [currentUserData, setCurrentUserData] = useState({});
const [fetchedUserData, setFetchedUserData] = useState({});



  return (
    <GeneralBarsContext.Provider 
      value={{
        addServiceBarState,
        setAddServiceBarState,

        changePassModalState, 
        setChangePassModalState,

        addProfessionalBarState,
        setAddProfessionalBarState,

        setSelectedProfessionalData,
        selectedProfessionalData,

        setSelectedServiceData,
        selectedServiceData,

        setSelectedScheduleData,
        selectedScheduleData,

        setAddProfessionalBarTriggerName,
        addProfessionalBarTriggerName,

        setAddServiceBarTriggerName,
        addServiceBarTriggerName,

        setAddScheduleBarTriggerName,
        addScheduleBarTriggerName,

        setAddScheduleBarState,
        addScheduleBarState,

        setLoadingModalStates,
        loadingModalStates
      }}>
      {children}
    </GeneralBarsContext.Provider>
  );
};

