import { useEffect, useState } from 'react';
import axios from '../../../../../node_modules/axios/index';
import { SelectIpt } from '../../../../components/commom/form-input/FormInput';
import './GetBarber.css';
import ProfileItem from '../../../../components/customer/profile-item/ProfileItem';

const GetBarber = ({setSelectedBarber}) => {

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [barbers, setBarbers] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [pages, setPages] = useState([]);
    const [totalPages, setTotalPages] = useState();

    const handleStates = (value) => {

        getCities(value.value);
    }

    const handleCities = (value) => {

        setSelectedCity(value.label);
        setCurrentPage(1);
    }

    useEffect(()=>{

        const fetchData = async () => {

            const res = await fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados");

            const states = await res.json();

            let statesArray = [];
            states.map((state)=>{

                statesArray.push({

                    id: state.sigla,
                    name: state.nome
                })
            });

            setStates(statesArray);
        }

        fetchData()
    },[]);

    const getCities = async (state) => {

        const res = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`);

        const cities = await res.json();

        let citiesArray = [];

        cities.map((city)=>{

            citiesArray.push({

                id: city.id,
                name: city.nome
            })
        });

        setCities(citiesArray);

    }

    const getPageRange = (totalPages) => {
        const maxPagesToShow = 10;
    
        let startPage = currentPage - Math.floor(maxPagesToShow / 2);
        let endPage = currentPage + Math.floor(maxPagesToShow / 2);
    
        // Garantir que o intervalo não ultrapasse os limites
        if (startPage < 1) {
          startPage = 1;
          endPage = Math.min(totalPages, maxPagesToShow);
        }
    
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }
    
        return Array.from({ length: endPage - startPage+1 }, (_, i) => startPage + i);
    };

    useEffect(()=>{

    const fetchData = async () => {

        const res = await axios.get('/api/make-booking/get-barbers', {params:{city: selectedCity, page: currentPage}});

        
        setBarbers(res.data.data);
        setTotalPages(res.data.numberOfPages);
        setPages(getPageRange(res.data.numberOfPages));

    }

    fetchData()
    },[currentPage, selectedCity]);

    return (
        <div className='get-barber-container'>
            <h3 className='title'>Encontre o estabelecimento pareceiro mais próximo de você</h3>
            <div className='inputs-container'>
                <SelectIpt
                    inputData={{

                        placeholder: "Estado",
                        label: "Selecione o estado",
                        list: states,
                        handleInput: handleStates
                    }}
                />

                <SelectIpt
                    inputData={{

                        placeholder: "Cidade",
                        label: "Selecione a cidade",
                        list: cities,
                        handleInput: handleCities
                    }}
                />
            </div>
            <h4 className='title-2'>Barbearias disponíveis próximo à você:</h4>
            <div className='main'>
                
                <div className='barber-list'>
                    {
                        barbers.map((barber)=>{

                            return <ProfileItem key={barber.id} barberData={barber} setSelectedBarber={setSelectedBarber}/>
                        })
                    }
                </div>

                    {

                        barbers.length > 0
                            ?<div className='pagination'>
                                <button className='btn-back' type='button' onClick={()=>{setCurrentPage(currentPage == 1 ? currentPage : currentPage - 1)}}>Anterior</button>
                                <div className='numbers'>
                                    {
                
                                        pages.map((page, index)=>{
                
                                            return <button className={`btn-page-number ${currentPage == page ? "active" : null}`} type='button' onClick={(e)=>{setCurrentPage(page)}} key={index}>{page}</button>
                                        })
                                    }
                                </div>
                                <button className='btn-next' type='button' onClick={()=>{setCurrentPage(currentPage == totalPages ? currentPage : currentPage + 1)}}>Próxima</button>
            
                            </div>
                            : null
                    }
                    
            </div>
            
        </div>
    )
}

export default GetBarber;