import React, { useState } from 'react';

const InputMask = ({ onChange }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Remove caracteres não numéricos
    const numericValue = inputValue.replace(/\D/g, '');

    // Verifica se é CPF ou CNPJ e aplica a máscara correspondente
    let maskedValue;
    if (numericValue.length <= 11) {
      // CPF
      maskedValue = numericValue.replace(
        /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2}).*/,
        '$1.$2.$3-$4'
      );
    } else {
      // CNPJ
      maskedValue = numericValue.replace(
        /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2}).*/,
        '$1.$2.$3/$4-$5'
      );
    }

    setValue(maskedValue);

    // Chamada da função onChange com o valor sem máscara
    onChange(numericValue);
  };

  // Determina o tamanho máximo do campo de entrada
  const maxLength = value.length <= 11 ? 14 : 18;

  return (
    <div style={{

        display: 'flex',
        flexDirection: 'column'
    }}>
        <label style={{

            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "14px",
            marginBottom: "6px",
            color: "rgb(41, 41, 41)"
        }}>CPF / CNPJ*</label>
        <input style={{

            display: "flex",
            borderRadius: "6px",
            borderColor: "lightgray",
            backgroundColor: "rgb(240, 240, 240)",
            borderStyle: "solid",
            borderWidth: "0.5px",
            padding: "10px",
            outline: "0"
        }}
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="CPF ou CNPJ"
        maxLength={maxLength}
        required={true}
        />
    </div>
  );
};

export default InputMask;