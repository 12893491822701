import { useContext, useState } from 'react';
import { GeneralBarsContext } from '../../../context/generalBarsContext';
import { FormInput } from '../form-input/FormInput';
import "./ChangePassModal.css";

const ChangePassModal = () => {

    const {changePassModalState, setChangePassModalState} = useContext(GeneralBarsContext);

    const [passData, setPassData] = useState({

        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });

    const handleInput = (e) =>{

        e.preventDefault()

        setPassData({...passData, [e.target.name] : e.target.value})
    }

    const changePassword = (e) => {

        e.preventDefault();

        if(passData.currentPassword.length <= 0){

            console.log("MENOR QUE 0")
        }
    }
  return (

    <>
        {
            changePassModalState
            ?
            <div className='change-pass-modal-background-container'>
                <div className='change-pass-modal-main-container'>
                    <form onSubmit={(e)=> changePassword(e)}>
                        <h3 className='change-pass-modal-main-title'>Alterar senha</h3>

                        <FormInput
                            inputData={{

                                id: 1, 
                                name: 'currentPassword', 
                                type: 'password', 
                                placeholder: 'Senha', 
                                label: 'Senha', 
                                value: passData.currentPassword,
                                error: 'Digite a senha atual corretamente.',
                                pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
                                required: true
                            }}
                            handleInput={handleInput}
                        />

                        <FormInput
                            inputData={{

                                id: 2, 
                                name: 'newPassword', 
                                type: 'password', 
                                placeholder: 'Nova senha', 
                                label: 'Nova senha', 
                                value: passData.newPassword,
                                error: 'A senha precisa ter no mínimo 8 caracteres, dentre eles 1 letra, 1 número e 1 caractere especial.',
                                pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
                                required: true
                            }}
                            handleInput={handleInput}
                        />

                        <FormInput
                            inputData={{

                                id: 3, 
                                name: 'confirmNewPassword', 
                                type: 'password', 
                                placeholder: 'Confirmar nova senha', 
                                label: 'Confirmar nova senha', 
                                value: passData.confirmNewPassword,
                                error: 'As senhas digitadas não conferem.',
                                pattern: passData.newPassword,
                                required: true
                            }}
                            handleInput={handleInput}
                        />
                        <div className='change-pass-field-btns-container'>
                            <button onClick={()=>{setChangePassModalState(!changePassModalState)}} className='change-pass-cancel-btn'>Cancelar</button>
                            <button type='submit' className='change-pass-change-pass-btn'>Alterar senha</button>
                        </div>
                    </form>
                </div>
            </div>
            :
            null
        }
    </>
  )
}

export default ChangePassModal