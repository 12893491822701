import moment from "moment";

export const GetTimeSlots = (start, end) => {

    var startTime = moment(start, 'HH:mm');
    var endTime = moment(end, 'HH:mm');
    
    if( endTime.isBefore(startTime) ){
        endTime.add(1, 'day');
    }
    
    var timeStops = [];
    
    while(startTime <= endTime){
        timeStops.push(new moment(startTime).format('HH:mm'));
        startTime.add(10, 'minutes');
    }
    return timeStops;
}

const getTimeRange = (startTimeUnder, endTime, slotsArray) => {
    const sortedSlots = [...slotsArray].sort();

    const timeRange = sortedSlots.filter(slot => {
        const slotTime = new Date(`01/01/2011 ${slot}`);
        return slotTime > startTimeUnder && slotTime < endTime;
    });

    return timeRange;
};

export const GetSlotsForSpecificProfessional = (initialWorkTime, endWorkTime, initialLunchTime, endLunchTime, schedules, selectedProfessionalId, sDuration, minTimeService) => {

    const beforLunch = GetTimeSlots(initialWorkTime, initialLunchTime);
    const afterLunch = GetTimeSlots(endLunchTime, endWorkTime);
    const workingHours = [...beforLunch, ...afterLunch];


    const getOccupiedSlots = (time, schedules) => {

        const isOccupied = schedules.some((schedule)=>{

            const startTime = moment(`2011-01-01 ${moment(schedule.scheduleDateTime).format("HH:mm")}`, 'YYYY-MM-DD HH:mm');
            const startTimeUnder = moment(startTime).subtract(minTimeService, 'minutes');
            const endTime = moment(startTime).add(schedule.serviceDuration, 'minutes');

            return(time > startTimeUnder && time < endTime);
        });

        return !isOccupied;

    }

    let availableSlots = [];

    workingHours.map((slot)=>{

        const currentMoment = moment();
        const specificTime = currentMoment.hour(parseInt(slot.split(":")[0])).minute(parseInt(slot.split(":")[1]));
        const specificMoment = moment(`2011-01-01 ${moment(specificTime).format("HH:mm")}`, 'YYYY-MM-DD HH:mm');

        if(getOccupiedSlots(specificMoment, schedules)){

            availableSlots.push(slot);
        }else{


        }
    });

    return availableSlots;
}

export const GetSlotsForAnyProfessional = (initialWorkTime, endWorkTime, initialLunchTime, endLunchTime, schedules, professionalsList, minTimeService) => {

    const beforLunch = GetTimeSlots(initialWorkTime, initialLunchTime);
    const afterLunch = GetTimeSlots(endLunchTime, endWorkTime);
    const workingHours = [...beforLunch, ...afterLunch];

    // Function to check if at least one professional is available at a given time
    const isProfessionalAvailable = (time, appointments) => {
        return professionalsList.some((professional) => {

            if(professional.id != 0){

                const isOccupied = appointments.some(schedule => {

                    const startTime = moment(`2011-01-01 ${schedule.startTime}`, 'YYYY-MM-DD HH:mm');
                    const startTimeUnder = moment(startTime).subtract(minTimeService, 'minutes');
                    const endTime = moment(startTime).add(schedule.serviceDuration, 'minutes');
        
                    return (
                        schedule.professionalId === professional.id &&
                        time > startTimeUnder &&
                        time < endTime
                    );
                });

                return !isOccupied;
            }
        });
    }

    let availableSlots = [];

    workingHours.map((slot)=>{

        const formatted = moment(`2011-01-01 ${slot}`, 'YYYY-MM-DD HH:mm');

        if(isProfessionalAvailable(formatted, schedules)){

            availableSlots.push(slot);
        }else{

        }
    });

    return availableSlots;
  
}

export const UpdateSlotsBasedOnSelected = (selectedSlot, slots, duration, minTimeService, setUpdatedSlots) =>{

    let startTime = new Date(Date.parse('01/01/2011 '+selectedSlot.slot));
    let endTime =  new Date(startTime.getTime() + duration * 60000);
    let startTimeUnder =  new Date(startTime.getTime() - minTimeService * 60000);

    if(slots != null){
    
        slots.forEach((hour, index)=>{

            let hourAsDate = new Date(Date.parse('01/01/2011 '+ hour));
            if (hourAsDate > startTimeUnder && hourAsDate < endTime){

                slots[index] = null; 
            }else{

            }
        });
        slots =  slots.filter((hour) => {return hour != null;});

    }

    setUpdatedSlots(slots);

}

export const GetEndTime = (startTime, duration) => {

    let startTimeC = new Date(Date.parse('01/01/2011 '+startTime));
    let endTime =  new Date(startTimeC.getTime() + duration * 60000);
    let endTimeF =  endTime.getHours() + ":" + endTime.getMinutes();

    return endTimeF;
}