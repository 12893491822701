import React from 'react'
import { FaUser } from 'react-icons/fa';

const ProfessionalItem = ({key, data}) => {

  return (
    <div key={key} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px", backgroundColor: "#ECEFF190", borderRadius: "6px", border: "1px solid #ECEFF1"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "6px"}}>
          {

            data.image != ""
              ? <img style={{width: "40px", height: "40px", borderRadius: "10px", objectFit: "cover"}} src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${data.barberId}/${data.image}`}/> 
              : <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgray", borderRadius: "6px", width: "40px", height: "40px"}}><FaUser style={{color: "white", width: "16px", height: "16px"}}/></div>          
          }
          
          <span style={{fontWeight: "600", color: "#101418"}}>{data.name}</span>
        </div>
        <span style={{fontSize: "17px", fontWeight: "600", color: "#00796B"}}>{data.revenue}</span>
    </div>
  )
}

export default ProfessionalItem;