import { useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import "./AddProfessionalWelcome.css";
import ProfessionalItem from './ProfessionalItem';
import { FormInput, MainInput, MaskedFormInput, MaskedInput } from '../../commom/form-input/FormInput';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';
import { FaPlus } from 'react-icons/fa';

const AddProfessionalWelcome = ({setProfessionalsList, professionalsList, setCurrentStep, currentStep, currentUser}) => {

    let professionalsArray = professionalsList != null ? professionalsList : [];

    const [professional, setProfessional] = useState({

        id: "",
        name: "",
        email: "",
        phoneNumber: "",
        comission: "",
        barberId: "",
        createdAt: ""
    });

    const [addProfessionalTrigger, setAddProfessionalTrigger] = useState(false);

    const addProfessional = () =>{

        if(
            professional.name != "" && 
            professional.name.length > 3 && 
            professional.name.length < 50 && 
            professional.email != "" && 
            professional.comission != "" && 
            professional.phoneNumber != "" &&
            validateFields("phoneNumber") == false
        ){

            professionalsArray.push({

                id: uuidv4(),
                email: professional.email,
                name: professional.name,
                phoneNumber: professional.phoneNumber,
                comission: professional.comission,
                barberId: currentUser.id,
                createdAt: moment().tz('America/Sao_Paulo').format()
            });
            setProfessionalsList(professionalsArray);
            setProfessional({

                id: "",
                name: "",
                email: "",
                phoneNumber: "",
                comission: "",
                barberId: currentUser.id,
                createdAt: ""
            });
            setAddProfessionalTrigger(false);
        }
    }

    const deleteProfessional = (index) =>  {

        professionalsArray.splice(index, 1);
        setProfessionalsList(professionalsArray);
        setProfessional({

            id: "",
            name: "",
            email: "",
            phoneNumber: "",
            comission: "",
            barberId: "",
            createdAt: ""
        });
    }

    const handleInput = (e) =>{

        setProfessional({...professional, [e.target.name] : e.target.value})
    }

    const validateFields = (name) =>{

        if(name == "phoneNumber"){

            let str1 = professional.phoneNumber.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", "");
            if(str1.length == 11){

                console.log(false)
            return false;
            }else{
        
            return true;
            }
        }
    
    }

  return (
    <div className='welcome-add-professional'>

        {

        addProfessionalTrigger
        ? <div className='mobile'>
            <div className='box'>
                    <div className='fields'>

                        <MainInput
                            data={{

                                id: 2,
                                name: "name",
                                label: "Nome *",
                                placeholder: "Nome do prossional",
                                pattern: "^[a-zA-Z0-9 ]{3,50}$",
                                error: "O nome do profissional deve ter entre 3 e 50 caractéres!",
                                value: professional.name,
                                aditionalClass:"stretched",
                                handleInput: handleInput
                            }}
                        />
                        
                        <MainInput
                            data={{

                                id: 4,
                                name: "email",
                                label: "E-mail *",
                                placeholder: "E-mail",
                                value: professional.email,
                                type: "email",
                                error: "Email inválido!",
                                aditionalClass:"stretched",
                                handleInput: handleInput
                            }}
                        />

                        <MaskedInput
                            data={{

                                id: 3,
                                name: "phoneNumber",
                                label: "Telefone *",
                                placeholder: "Telefone",
                                value: professional.phoneNumber,
                                mask: "(99) 99999-9999",
                                hasError: validateFields("phoneNumber").toString(),
                                error: "Número inválido!",
                                aditionalClass:"stretched",
                                handleInput: handleInput
                            }}
                        />

                        <MainInput
                            data={{

                                id: 4,
                                name: "comission",
                                label: "Comissão (%)*",
                                placeholder: "Comissão",
                                value: professional.comission,
                                type: "number",
                                error: "Email inválido!",
                                aditionalClass:"stretched",
                                handleInput: handleInput
                            }}
                        />

                        

                    </div>
                    <div className='buttons'>
                        <button type='button' className='cancel' onClick={(e)=>{setAddProfessionalTrigger(false)}}>Cancelar</button>
                        <button type='button' className='add' onClick={()=>{addProfessional()}} >Adicionar</button>
                    </div>

                </div>
            </div>
        : null
        }

        <h3 className='welcome-add-professional-title'>Adicionar Profissionais</h3>
        <span className='subtitle-desktop'>Adicione pelo menos 1 profissional...</span>
        <span className='subtitle-mobile'>Adicione pelo menos 1 profissional, basta clicar no botão abaixo:</span>

        <button type='button' className='trigger' onClick={(e)=>{setAddProfessionalTrigger(true)}}><FaPlus className='icon'/></button>

        <div className='desktop'>
            <div className='row'>

                <div className='fields'>

                    <MainInput
                        data={{

                            id: 2,
                            name: "name",
                            label: "Nome *",
                            placeholder: "Nome do prossional",
                            pattern: "^[a-zA-Z0-9 ]{3,50}$",
                            error: "O nome do profissional deve ter entre 3 e 50 caractéres!",
                            value: professional.name,
                            aditionalClass:"stretched",
                            handleInput: handleInput
                        }}
                    />
                    
                    <MainInput
                        data={{

                            id: 4,
                            name: "email",
                            label: "E-mail *",
                            placeholder: "E-mail",
                            value: professional.email,
                            type: "email",
                            error: "Email inválido!",
                            aditionalClass:"stretched",
                            handleInput: handleInput
                        }}
                    />

                    <MaskedInput
                        data={{

                            id: 3,
                            name: "phoneNumber",
                            label: "Telefone *",
                            placeholder: "Telefone",
                            value: professional.phoneNumber,
                            mask: "(99) 99999-9999",
                            hasError: validateFields("phoneNumber").toString(),
                            error: "Número inválido!",
                            aditionalClass:"stretched",
                            handleInput: handleInput
                        }}
                    />

                    <MainInput
                        data={{

                            id: 4,
                            name: "comission",
                            label: "Comissão (%)*",
                            placeholder: "Comissão",
                            value: professional.comission,
                            type: "number",
                            error: "Email inválido!",
                            aditionalClass:"stretched",
                            handleInput: handleInput
                        }}
                    />

                </div>
                <IoMdAdd className='welcome-add-professional-add-btn' onClick={()=>{addProfessional()}} />
            </div>
        </div>

        <div className='welcome-add-professional-professionals-list'>

            {professionalsList.length > 0
                ? professionalsList.map((professional, index)=>{
                    return <ProfessionalItem key={index} index={index} professional={professional} deleteProfessional={deleteProfessional}/>
                })
                : null
            }
        </div>

        <div className='navigate-buttons'>
            <button type='button' className="add-professional-back" onClick={(e)=>{setCurrentStep(currentStep-1)}}>Anterior</button>
            {professionalsList.length > 0
                ? <button type="submit" className="add-professional-next">Próxima</button>
                : <button disabled type="button" className={`add-professional-next ${"disabled"}`}>Próxima</button>
            }
        </div>
            

    </div>
  )
}

export default AddProfessionalWelcome;