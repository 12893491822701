import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FormInput, MaskedFormInput } from '../../../components/commom/form-input/FormInput';
import InputMask from '../../../components/commom/input-mask/InputMask';
import LoadingPopup from '../../../components/commom/loading-pop-up/LoadingPopUp';
import Security from "../../../utils/images/selo-seguranca.png";
import "./Checkout.css";
import { useAuth } from '../../../context/AuthContext';
import { planName } from '../../../utils/Data';
import { getCookie } from '../../../utils/functions/Others';

const Checkout = ({plan, planId, clientSecret, paymentIntent, stripeCustomerId, src}) => {

  const { user } = useAuth();

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [checkoutData, setCheckoutData] = useState({

    name: "",
    whatsapp: "",
    email: "",
    confirmEmail: "",
    cpf_cnpj: "",
    postalCode: "",
    street: "",
    streetNumber: "",
    neighborhood: "",
    city: "",
    state: ""
  });
  const [cardDataFilled, setCardDataFilled] = useState(false);
  const [state, setState] = useState("empty");
  const [subBtnState, setSubBtnState] = useState("empty");
  

  const notifyError = (message) => toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });

  const fetchAddress = () =>{

    if(checkoutData.postalCode != ""){

      let noDash = checkoutData.postalCode.replace('-', '');
      let isNum = /^\d+$/.test(noDash);

      if(noDash.length == 8 && isNum){

          fetch(`https://viacep.com.br/ws/${noDash}/json/`)
          .then((result)=> {

            return result.json()
          })
          .then((data)=>{

            setCheckoutData({
              ...checkoutData,  
              'street': data.logradouro, 
              'neighborhood': data.bairro,
              'city': data.localidade,
              'state': data.uf
            })
          })
      }
    }

  }

  const handleInput = (e) => {

    setCheckoutData({...checkoutData, [e.target.name] : e.target.value});      
  }

  const handleCPFOrCPJ = (value) => {

    setCheckoutData({...checkoutData, cpf_cnpj : value})
  }

  const subscribe = async (e) => {

    e.preventDefault();
    document.body.classList.add('no-scroll');

    setSubBtnState("loading");
    setState("loading");

    elements.submit();
    await stripe.confirmPayment({

      clientSecret: clientSecret,
      elements: elements,
      redirect: 'if_required',
      confirmParams: {

        return_url: "https://app.srbarberoficial.com.br"
      }
    }).then( async res=>{

      if(res.error){

        setTimeout(()=>{

          setState("error");

          setTimeout(()=>{

            if(res.error.code === "incomplete_number"){

              setSubBtnState("empty");
              setState("empty");
              notifyError(res.error.message);

            }else if(res.error.code === "incomplete_expiry"){

              setSubBtnState("empty");
              setState("empty");
              notifyError(res.error.message);

            }else if(res.error.code === "incomplete_cvc"){

              setSubBtnState("empty");
              setState("empty");
              notifyError(res.error.message);

            }else{

              setSubBtnState("empty");
              setState("empty");
              notifyError(res.error.message);
            }
          }, 2500);
        }, 2500)
      }else if(res.paymentIntent){

        await axios.get(`/api/subscriptions/${user.id}`)
        .then(async res => {

          if(res.data.success){

            await axios.put(`/api/checkout/update-subscription/${res.data.data.id}`, {

              status: "active",
              planId: planId,
              professionalsNumber: planName(planId).professionalsNumber
            })
            .then(async resUpdateSubStatus=>{
    
              if(resUpdateSubStatus.data.success === true){

                if(src === "upgrade"){

                  await axios.post("/api/pixel/events/subscribe", {

                    barberId: user.id,
                    email: user.email,
                    phoneNumber: user.whatsapp,
                    masterName: user.masterName,
                    city: user.city,
                    state: user.state,
                    postalCode: user.postalCode,
                    subscriptionId: res.data.data.id,
                    planId: planId,
                    userAgent: navigator.userAgent,
                    source: window.location.href,
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp")
                  }).then(res => {


                  }).catch(err => {

                    setTimeout(()=>{

                      if(err.response.status === 500){
  
                          notifyError(err.response.data.message);
                      }else{
                          
                      }
                  }, 1500);
                  });
                }else if(src === "reactive-account"){

                  await axios.post("/api/checkout/send-reactive-subscription", {

                    id: user.id,
                    email: user.email,
                    phoneNumber: user.whatsapp,
                    masterName: user.masterName,
                    planId: planId,
                    userAgent: navigator.userAgent
                  }).then(res => {


                  }).catch(err => {

                    setTimeout(()=>{

                        if(err.response.status === 500){
    
                            notifyError(err.response.data.message);
                        }else{
                            
                        }
                    }, 1500);
                  });
                }

    
                await axios.post("/api/checkout/send-email", {
            
                  masterName: user.masterName,
                  email: user.email,
                  plan: plan.title,
                  emailType: src == "upgrade" ? "congratulations" : "welcome-again"
                })
                .then(res=>{
        
                  if(res.data.success){
        
                    setTimeout(()=>{
        
                      setSubBtnState("empty");
                      setState("success");
                      sessionStorage.setItem("subscriptionStatus", "active");
            
                      setTimeout(()=>{
                        setState("empty");
                        window.location.href = "https://app.srbarberoficial.com.br/dashboard";
                      }, 2500);
                    }, 2500);
                  }
                })
                .catch(err=>{
        
                  setTimeout(()=>{

                    if(err.response.status === 500){

                      notifyError(err.response.data.message);
                    }else{
                        
                    }
                }, 1500);
                });
    
              }
            })
            .catch(err=>{

              setTimeout(()=>{

                if(err.response.status === 500){

                  notifyError(err.response.data.message);
                }else if(err.response.status === 400){

                  notifyError(err.response.data.message);
                }else{
                    
                }
            }, 1500);
    
            });
          }
        })
        .catch(err => {

          setTimeout(()=>{

            if(err.response.status === 500){

                notifyError(err.response.data.message);
            }else if(err.response.status === 404){

                notifyError(err.response.data.message);
            }else{
                
            }
        }, 1500);
        });
      }

    }).catch(err=>{

      setTimeout(()=>{

          setSubBtnState("empty");
          setState("error");

          setTimeout(()=>{
            setState("empty");
          }, 2500);
        }, 2500);
    });
  }

  const validateFields = (name) =>{

    if(name == "whatsapp" && checkoutData.whatsapp != null){


      let str1 = checkoutData.whatsapp.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", "");
      if(str1.length == 11){
        return false;
      }else{

        return true;
      }
    }else if(name == "cpf_cnpj" && checkoutData.cpf_cnpj != null){

      let str1 = checkoutData.cpf_cnpj.replaceAll(".", "").replaceAll("-", "")
      if(str1.length === 11){
        return false;
      }else if(str1.length === 14){

        return false;
      }else{

        return true;

      }
    }
  }

  return (
    <div className='checkout'>
      {
        state === "loading"
          ? <LoadingPopup state={"loading"} message={"Processando pagamento..."}/>
          : state === "success"
              ? <LoadingPopup state={"success"} message={"Pagamento realizado com sucesso!"}/>
              : state === "error"
                ? <LoadingPopup state={"error"} message={"Erro ao processar pagamento."}/>
                : null
        
      }
      
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
        <ToastContainer />
      <div className='container'>
        <div className='plan'>
          <div className='card'>
            <h4>{planName(planId).planTitle}</h4>
            <div className='container'>
                <span className='p-number'>*Até {planName(planId).professionalsNumber} profissionais</span>
                <div className="divider"></div>
                <span className='line-through'>{planName(planId).slashedPrice}</span>
                <span className='price'>R$<span className='bigger'>{planName(planId).priceN}</span>,{planName(planId).centN}<span style={{fontSize: "18px", letterSpacing: -1, margin: 0, padding: 0}}></span></span>
                <div className="divider"></div>
                <span className='msg'>*Sua assinatura será cobrada a cada um mês automaticamente.</span>
                <img src={Security}></img>
            </div>
          </div>
        </div>

          <form action='submit'  className='box'>
            
            <h3>Dados do responsável</h3>

            <div className='info'>

              <FormInput
                inputData={{

                  label: "Nome do responsável*",
                  placeholder: "Nome completo do responsável",
                  name: "name",
                  value: checkoutData.name,
                  required: true
                }}
                aditionalClass=""
                handleInput={handleInput}
              />

              <MaskedFormInput 
              inputData={{
                name: 'whatsapp',
                type: '',
                placeholder: 'Telefone do responsável',
                label: 'Celular*',
                mask: "(99) 99999-9999",
                value: checkoutData.whatsapp,
                error: "Número inválido!",
                hasError: checkoutData.whatsapp != null ? validateFields("whatsapp").toString() : null,   
                required: true
              }}
              handleInput={handleInput}/>

              <FormInput
                inputData={{

                  label: "E-mail da sua conta no Sr Barber*",
                  placeholder: "exemplo@exemplo.com",
                  name: "email",
                  value: checkoutData.email,
                  required: true
                }}
                aditionalClass=""
                handleInput={handleInput}
              />

              <FormInput
                inputData={{

                  label: "Confirmar e-mail*",
                  placeholder: "exemplo@exemplo.com",
                  name: "confirmEmail",
                  value: checkoutData.confirmEmail,
                  required: true
                }}
                aditionalClass=""
                handleInput={handleInput}
              />

                <InputMask
                  onChange={handleCPFOrCPJ}
                />

            </div>

            <h3>Endereço de cobrança</h3>


            <div className='address'>

            <MaskedFormInput inputData={{

              name: 'postalCode',
              placeholder: '00000-000', 
              label: 'CEP*',
              value: checkoutData.postalCode!= undefined ? checkoutData.postalCode : "",
              mask: "99999-999",
              error: 'Preencha o cep.',
              pattern: "^[0-9]{5}(?:-[0-9]{3})?$",
              required: true
              }}
              aditionalClass=""
              handleInput={handleInput}  
              fetchAddress={fetchAddress}/>

              <FormInput
                inputData={{

                  label: "Rua",
                  placeholder: "Rua",
                  name: "street",
                  value: checkoutData.street,
                  required: true,
                  disabled: true
                }}
                aditionalClass=""
                handleInput={handleInput}
              />
              <FormInput
                inputData={{

                  label: "Número*",
                  placeholder: "000",
                  name: "streetNumber",
                  value: checkoutData.streetNumber,
                  required: true

                }}
                aditionalClass=""
                handleInput={handleInput}
              />
              <FormInput
                inputData={{

                  label: "Bairro",
                  placeholder: "Bairro",
                  name: "neighborhood",
                  value: checkoutData.neighborhood,
                  required: true,
                  disabled: true

                }}
                aditionalClass=""
                handleInput={handleInput}
              />
              <FormInput
                inputData={{

                  label: "Cidade",
                  placeholder: "Cidade",
                  name: "city",
                  value: checkoutData.city,
                  required: true,
                  disabled: true

                }}
                aditionalClass=""
                handleInput={handleInput}
              />
              <FormInput
                inputData={{

                  label: "Estado",
                  placeholder: "Estado",
                  name: "state",
                  value: checkoutData.state,
                  required: true,
                  disabled: true

                }}
                aditionalClass=""
                handleInput={handleInput}
              />
              </div>

            <h3>Dados de pagamento</h3>
              <PaymentElement  className='card-data' onChange={(e)=>{setCardDataFilled(e.complete)}}/>

            {
              checkoutData.name !== "" &&
              checkoutData.whatsapp !== "" &&
              checkoutData.email !== "" &&
              checkoutData.cpf_cnpj !== "" &&
              checkoutData.postalCode !== "" &&
              checkoutData.street !== "" &&
              checkoutData.streetNumber !== "" &&
              checkoutData.neighborhood !== "" &&
              checkoutData.city !== "" &&
              checkoutData.state !== "" &&
              checkoutData.email == checkoutData.confirmEmail &&
              cardDataFilled === true
                ? subBtnState === "empty"
                  ? <button type='submit' onClick={(e)=>{subscribe(e)}}>Assinar agora</button>
                  : <button disabled={true} type='submit' onClick={(e)=>{subscribe(e)}}>Processando...</button>
                : <button disabled={true} onClick={(e)=>{subscribe(e)}}>Assinar agora</button>

            }

          </form>
      </div>
      
    </div>
  )
}

export default Checkout;