import React, { useContext } from 'react';
import "./LoadingPopUp.css";
import { GeneralBarsContext } from '../../../context/generalBarsContext';
import LoadingIcon from "../../../utils/images/loading_black.gif";
import CheckIcon from "../../../utils/images/check_black.gif";
import ErrorIcon from "../../../utils/icons/error.gif";

const LoadingPopup = ({state, message}) => {

  return (
    <div>
        {

            state
            ? <div className="loading-popup">
                <div className="loading-box-welcome">
                {
                    state === "loading"
                    ? <><img src={LoadingIcon} className="loading-icon-welcome"></img>{message}</>
                    :<></>
                
                }

                {
                    state === "success"
                    ? <><img src={CheckIcon} className="loading-icon-welcome"></img>{message}</>
                    :<></>
                
                }

                {
                    state === "error"
                    ? <><img src={ErrorIcon} className="loading-icon-welcome"></img>{message}</>
                    :<></>
                
                }
                
                </div>
            </div>
            : <></>
            }
    </div>
  )
}

export default LoadingPopup;