import { BiImageAdd } from 'react-icons/bi';
import { FormInput, MainInput, MaskedFormInput, MaskedInput } from '../../../../components/commom/form-input/FormInput';
import "./EnterpriseInfo.css";
import { getAddress } from '../../../../utils/functions/Others';


const EnterpriseInfo = ({handleInput, user, setUser}) => {

  const inputsData = [

    {
        id: 1, 
        name: 'street', 
        type: 'text', 
        placeholder: 'Rua', 
        label: 'Rua*', 
        value: user.street,
        error: 'Preencha a rua.',
        pattern: "^(?!\s*$).+",
        required: true,
        disabled: false,
        handleInput: handleInput,
        aditionalClass: "stretched"
    },
    {
        id: 2, 
        name: 'streetNumber', 
        type: 'number', 
        placeholder: 'Número', 
        label: 'Número*', 
        value: user.streetNumber,
        error: 'Preencha o número.',
        pattern: "^(?!\s*$).+",
        required: true,
        disabled: false,
        handleInput: handleInput
    },
    {
        id: 3, 
        name: 'neighborhood', 
        type: 'text', 
        placeholder: 'Bairro', 
        label: 'Bairro*',
        value: user.neighborhood,
        error: 'Preencha o bairro.',
        pattern: "^(?!$\s*$).+",
        required: true,
        disabled: false,
        handleInput: handleInput,
        aditionalClass: "stretched"

    },
    {
        id: 4, 
        name: 'postalCode', 
        type: 'text', 
        placeholder: 'CEP', 
        label: 'CEP*',
        mask: "99999-999",
        value: user.postalCode,
        error: 'Preencha o CEP.',
        pattern: `^[0-9]{5}(?:-[0-9]{3})?$`,
        required: true,
        disabled: false,
        handleInput: handleInput,
        aditionalClass: "stretched"
    },
    {
        id: 5, 
        name: 'city', 
        type: 'text', 
        placeholder: 'Cidade', 
        label: 'Cidade*',
        value: user.city,
        error: 'Selecione a cidade.',
        pattern: "^(?![\s]*$).+",
        required: true,
        disabled: false,
        handleInput: handleInput
    },
    {
        id: 6, 
        name: 'state', 
        type: 'text', 
        placeholder: 'Estado', 
        label: 'Estado*',
        value: user.state,
        error: 'Selecione o estado.',
        pattern: "^(?!\s*$).+",
        required: true,
        disabled: false,
        handleInput: handleInput

    },
  ]
  const fetchAddress = () =>{

      getAddress(user.postalCode).then(res=>{

        if(res){

          setUser({
              ...user,
              postalCode: res.cep,
              street: res.logradouro,
              neighborhood: res.bairro,
              city: res.localidade,
              state: res.uf,
          })
        }

      });
    }

  const validateFields = (name) =>{


    if(name == "aditionalPhoneNumber"){


      let str1 = user.aditionalPhoneNumber.replace("(", "").replace(")", "").replace("-", "").replace("_", "").replace(" ", "");

      if(user.aditionalPhoneNumber != "" && str1.length == 11){

        return false;
      }else{
  
        return true;
      }
    }
  }

  return (
    <div className='welcome-enterprise-info-container'>
        <h2 className='welcome-enterprise-info-title'>Informações da empresa</h2>
        { /*
          <div className="welcome-user-image-main-container">
            <input type="file" className="welcome-user-image-container" onChange={(e)=>handleInput(e)} name="image"/>
              {

                user.image != ""
                    ?<img id="1" src={URL.createObjectURL(user.image)} className="add-professional-bar-professional-image" />
                    :<BiImageAdd className="welcome-image-icon"/>
              }
          </div>
        */}

        <h3 className='welcome-enterprise-info-subtitle'>Informações</h3>

        <div className="row">
          <MainInput
          
            data={{

              type: "text",
              name: "enterpriseName",
              label: "Nome da empresa*",
              placeholder: "Nome da empresa",
              value: user.enterpriseName,
              handleInput: handleInput

            }}
          />

          <MaskedInput
          
            data={{
              error: "Número inválido!",
              hasError: user.aditionalPhoneNumber != null ? validateFields("aditionalPhoneNumber").toString() : null,
              type: "text",
              name: "aditionalPhoneNumber",
              label: "Telefone adicional (opcional)",
              placeholder: "Telefone",
              mask: "(99) 99999-9999",
              value: user.aditionalPhoneNumber,
              handleInput: handleInput,
              aditionalClass: "stretched"

            }}
          />
        </div>
        
        <h3 className='welcome-enterprise-info-subtitle'>Endereço</h3>

        <div className='column'>
          <div className='row'>
            <MaskedInput data={inputsData[3]} fetchAddress={fetchAddress}/> 
            <MainInput data={inputsData[0]}/>
            <MainInput data={inputsData[1]}/>
          </div>


          <div className={`row`}>
              <MainInput data={inputsData[4]}/>
              <MainInput data={inputsData[2]}/>
              <MainInput data={inputsData[5]}/>
          </div>
        </div>
        {
        user != null
          ? user.postalCode != "" && user.street != "" && user.streetNumber != "" && user.neighborhood != "" && user.city != "" && user.state != ""
            ? <button type="submit" className="next">Próxima </button>
            : <button disabled type="button" className="next">Próxima </button>
          : null
        }
        
    </div>
  )
}

export default EnterpriseInfo;