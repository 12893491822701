import { Navigate, Outlet, useLocation } from "react-router";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";

export const RequireAuth = ({ userTypeR }) => {

    const token = localStorage.getItem("token");
    const { user, businessRules, subscription, updateData } = useAuth();
    const subscriptionStatus = sessionStorage.getItem("subscriptionStatus");
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const pc = urlParams.get('pc');

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (token) {
            axios.get('/api/token-verification', {
                headers: { 'x-access-token': token, 'x-user-type': userTypeR }
            })
            .then(res => {
                setIsAuthenticated(true);
                updateData(res.data)
            })
            .catch(error => {
                setIsAuthenticated(false)
                localStorage.removeItem("token");
            })
            .finally(() => {
                setIsLoading(false);
            });
        } else {

            setIsAuthenticated(false);
            setIsLoading(false);
        }
    }, []);

    if (isLoading) {
        return null;
    }

    if (isAuthenticated) {

        if (user && user.userType) {

            if(user.userType === userTypeR){
    
                if (user.userType === "barber") {
                    if (user.firstAccess === "true" && location.pathname !== "/bem-vindo") {
                        return <Navigate to="/bem-vindo" replace={true} />;
                    } else {
                        if (subscription.status === "free-trial-over" && location.pathname !== "/upgrade") {
                            return <Navigate to="/upgrade" replace={true} />;
                        } else if ((subscription.status === "past-due" || subscription.status === "canceled") && location.pathname !== "/reativar-conta") {
                            return <Navigate to="/reativar-conta" replace={true} />;
                        } else if (subscription.status === "active" && (location.pathname === "/reativar-conta" || location.pathname === "/upgrade")) {
                            return <Navigate to="/dashboard" replace={true} />;
                        } else {
                            return <Outlet />;
                        }
                    }
                } else {
                    return <Outlet />;
                }
            }else{
    
    
            }
            
        } else {
    
        }
    }else{

        if (userTypeR === "barber") {
            return <Navigate to="/acessar-conta" replace={true}/>;
        } else {
            if (pc != null) {
                return <Navigate to={`/acessar-conta-cliente?pc=${pc}`} replace={true} />;
            } else {
                return <Navigate to="/acessar-conta-cliente" replace={true} />;
            }
        }
    }

};
