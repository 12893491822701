// ==============================================================================
//                                   LINE CHART
// ==============================================================================

import moment from "moment";

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


export const calcRevenueForLineChart = (schedules, startDate, endDate) => {

    //This function calculate revenue for Line chart in Dashboard page based on all schedules in a specifc period of time

    //CALCULATE PERIODS BETWEEN START DATE AND END DATE INCLUDING THEM

    const differenceInMs = moment.tz(endDate, userTimezone).endOf('day').valueOf() - moment.utc(startDate).valueOf();

    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    let currentDate = moment.tz(startDate, userTimezone).startOf('day');
    const resultDates = [];

    let sum = 0;
    while( differenceInDays > sum + 20){
    
        sum = sum + 20;
    }
    
    let sub = differenceInDays - sum;
    let nDaysToAdd = sum / 20;
    
    let nArray = [];
    
    for(let i = 0; i < 20; i++){
    
    nArray.push(nDaysToAdd);
    }
    
    for(let i = 0; i < sub; i++){
    
    nArray[i] = parseInt(nArray[i] + 1)
    }
      
    for(let i = 0; i < 20; i++){
    
        const nextDate = moment.utc(currentDate);

        nextDate.add(nArray[i], 'days');
        
        resultDates.push({
            date1: moment.utc(currentDate), 
            date2: moment.utc(nextDate)
        });

        currentDate = moment.utc(nextDate);
        currentDate.add(1, 'days');
    
    }


    //SUM VALUES BAESED ON SCHEDULES INSIDE EACH PERIOD TIME

    let chartDataArray = [];
    let finalDataToLineChart = [];

    resultDates.map((range, index)=>{

        const schedulesInPeriod = schedules.filter(schedule=>{
            
            const dateM = moment.utc(schedule.scheduleDateTime).toDate()
            const fDate1M = moment.utc(range.date1).startOf("day").toDate()
            const fDate2M = moment.utc(range.date2).endOf("day").toDate()
            
            return dateM >= fDate1M && dateM <= fDate2M;
        })

        chartDataArray.push({

            range: range,
            schedules: schedulesInPeriod
        })

    });

    chartDataArray.map(data=>{

    let sum = 0;


    data.schedules.map(schedule=>{


        const fValue = schedule.servicePrice.replaceAll("R$", "").replaceAll(".", ",").replaceAll(",", ".");

        sum = sum + parseFloat(fValue);

    });

    finalDataToLineChart.push({
        range: data.range,
        sum: sum.toFixed(2)
    })
    });

    return finalDataToLineChart;

}

// ==============================================================================
//                            BAR CHART
// ==============================================================================


export const calcProfessionalsServicesForBarChart = (schedules, professionals) => {

//This function calculate total revenue of services for each professinal to Bar chart in Dashboard page based on all schedules in specific period of time

let dataToBarChartF = [];
let dataToBarChart = [];

//ATTACH SHCEDULES OF EACH PROFESSIONAL IN AN ARRAY

professionals.map(professional=>{

    const filteredSchedules = schedules.filter(schedule=>{

    return schedule.professionalId == professional.id
    
    });

    dataToBarChartF.push({professional: professional, schedules: filteredSchedules});
});

//SUM SCHEDULES VALUES OF EACH PROFESSIONAL

dataToBarChartF.map(data=>{


    dataToBarChart.push({professional: data.professional, sum: data.schedules.length});
})

return dataToBarChart;
}

export const calcTotalOfServicesDone = (schedules, services) => {

    let finalChartData = [];

    services.map(service=>{

        let sum = 0;

        schedules.map(schedule=>{

            if(schedule.serviceId === service.id){

                sum += 1;
            }
        });

        finalChartData.push({ service: service, sum: sum });
    });

    return finalChartData;

}