import { FaUser } from "react-icons/fa";
import "./ProfileItem.css";

const ProfileItem = ({barberData, setSelectedBarber}) => {

  return (
    <button type="button" className="profile-item-container" onClick={()=>{setSelectedBarber(barberData)}}>

      {

        barberData.image != null
          ? <img className="img" src={`http://localhost:3307/public/uploads/barber/${barberData.image}`}></img>
          : <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgray", borderRadius: "100%", width: "70px", height: "70px", marginBottom: "10px"}}><FaUser style={{color: "white", width: "22px", height: "22px"}}/></div>
      }
        
        <span className="barber-name">{barberData.enterpriseName}</span>
    </button>
  )
}

export default ProfileItem;