import React from 'react';
import { MdDeleteForever } from "react-icons/md";

const ProfessionalItem = ({index, professional, deleteProfessional }) => {

  return (
    <div className='' style={{display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: "#FAFAFA", padding: "14px 20px", borderRadius: "4px", marginBottom: "10px", gap: "6px"}}>
        <div className='' style={{flex: 1, alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {professional.name}
        </div>
        <div className='' style={{flex: 1, alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {professional.email}
        </div>
        <div className='' style={{flex: 1, alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {professional.phoneNumber}
        </div>
        <div className='' style={{flex: 1, alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {professional.comission}%
        </div>
        <button onClick={()=>{deleteProfessional(index)}} type='button' className='' style={{outline: "0", border: "0", cursor: "pointer", backgroundColor: "transparent", color: "red", fontSize: "24px", alignItems: "center"}}>
            <MdDeleteForever/>
        </button>
    </div>
  )
}

export default ProfessionalItem;