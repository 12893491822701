import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "../../../../node_modules/axios/index";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import LoadingPopup from "../../../components/commom/loading-pop-up/LoadingPopUp";
import RedefinePassPopup from "../../../components/commom/redefine-pass-popup/RedefinePassPopup";
import CheckIcon from '../../../utils/images/check_black.gif';
import "./SigninCustomer.css";
import { AuthInput } from '../../../components/commom/form-input/FormInput';
import bgcustomer from "../../../utils/images/b1.webp";
import { Colors } from '../../../utils/Colors';
import { useAuth } from '../../../context/AuthContext';
import logoGray from "../../../utils/images/logo_gray.svg";

const SigninCustomer = () => {

  const { user, businessRules, subscription, updateData } = useAuth();
  const token = localStorage.getItem("token");

    const navigate = useNavigate();

    const notifyError = (message) => toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    const [state, setState] = useState("empty");
    const [loginState, setLoginState] = useState("empty");

    const [userData, setUserData] = useState({

        email: "",
        password: "",
        maintainConnected: false
    });

    const theme = localStorage.getItem("theme");

    const [recoverPassTrigger, setRecoverPassTrigger] = useState(false);

    const redirect = () => {

        setState("empty");
        setRecoverPassTrigger(false);
      }

    const urlParams = new URLSearchParams(window.location.search);
    const pc = urlParams.get('pc');

    const handleInputs = (e) => {

        e.preventDefault();

        if(e.target.name == "maintainConnected"){

          setUserData({...userData, [e.target.name] : !userData.maintainConnected})
      
          }else{
      
            setUserData({...userData, [e.target.name] : [e.target.value]})
      
          }
    }

    const login = (e) => {

      setLoginState("loading");

      e.preventDefault();

      axios.post("/api/customer-signin", userData).then(res=>{

          if(res.data.success){

              setTimeout(()=>{

                  setLoginState("success");
                  setTimeout(()=>{

                      setLoginState("");
                      localStorage.setItem("token", res.data.token);

                      setUserData({
                          ...userData,
                          email: "",
                          password: ""
                      });

                      if(pc != null){

                          navigate(`/fazer-agendamento?pc=${pc}`);

                      }else{

                          navigate("/fazer-agendamento");
                      }

                  }, 1500);
              }, 1500);
          }
      }).catch(err=>{

        setTimeout(()=>{
            setLoginState("")

            if(err.response.status === 401){

              notifyError(err.response.data.message);
              
            }else if(err.response.status === 404){
    
              notifyError(err.response.data.message);
      
            }else if(err.response.status === 500){

              notifyError(err.response.data.message);

            }else{
    
              notifyError("Erro ao acessar conta, tente novamente.");
            }
        }, 1500);
      });
    }

    const recoverPass = (e, recEmail) => {

      e.preventDefault();
  
      if(recEmail !== ""){
  
        setState("loading");
        axios.post("/api/recover-password-customer", {recEmail})
        .then(res=>{

          if(res.data.success){
  
            setState("success");
          }
        })
        .catch(err=>{

          setTimeout(()=>{

            setState("error");

            if(err.response.status === 404){
    
              notifyError(err.response.data.message);
      
            }else if(err.response.status === 500){

              notifyError(err.response.data.message);

            }else{
    
              notifyError("Erro ao solicitar redefinição de senha, tente novamente.")
            }
        }, 1500);
  
        });
      }
    }

    useEffect(() => {
      if (token) {
  
          axios.get('/api/token-verification', {
              headers: { 'x-access-token': token, 'x-user-type': "customer" }
          })
          .then(res => {
              updateData(res.data);
              navigate("/", {replace: true});

          })
          .catch(error => {
              localStorage.removeItem("token");
          })
          .finally(() => {
          });
      } else {
  
      }
  }, []);

  return(

    <div className={`signin-customer ${theme}`}>
      {/*<div className='theme-switch'><ThemeSwitch/></div>*/}
      <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                />
      <ToastContainer />
      {
        state === "success"
        ? 
          <div className='redefine-pass-popup'>
            <div className='box'>
              <div className='title'><img src={CheckIcon}></img><h3>E-mail enviado com sucesso</h3></div>
              <span>Acesse seu e-mail e clique no link de recuperação para criar uma nova senha!</span>
              <button type='button' onClick={()=>{redirect()}}>Ok</button>
            </div>
          </div>
        : state === "error"
          ? <LoadingPopup state={"error"} message={"Erro ao enviar e-mail, tente novamente."}/>
          : state === "loading"
            ? <LoadingPopup state={"loading"} message={"Enviando e-mail de recuperação, aguarde..."}/>
            : state === "empty"
                ? <></>
                : <></>
      }
      {

        loginState === "loading"
          ? <LoadingPopup state={"loading"} message={"Entrando..."}/>
          : loginState === "success"
            ? <LoadingPopup state={"success"} message={"Sucesso!"}/>
            : null
      }
  
      { 
        !recoverPassTrigger ?
          <>
            <div className='left'>
              <img className='bg' src={bgcustomer}></img>
              {/*<img src={signinbg}></img>*/}
            </div>
            <div className='right'>
              <div className='box'>

                <img className='logo' src={logoGray}></img>

                <h1>Acessar conta</h1>

                <form onSubmit={(e)=>{login(e)}} className='inputs'>
                  <AuthInput
                    data={{
                      iconName: "envelope",
                      label: "E-mail",
                      placeholder: "E-mail",
                      name: "email",
                      type: "email",
                      value: userData.email,
                      required: true,
                      autoComplete: "email",
                      handleInput: handleInputs
                    }}
                    iconStyle={{

                      color: Colors.PRIMARY_COLOR_LIGHT,
                      width: "20px",
                      height: "20px"
                    }}
                  />

                  <AuthInput
                    data={{
                      iconName: "lock",
                      label: "Senha",
                      placeholder: "Senha",
                      name: "password",
                      type: "password",
                      value: userData.password,
                      required: true,
                      handleInput: handleInputs
                    }}
                    iconStyle={{

                      color: Colors.PRIMARY_COLOR_LIGHT,
                      width: "20px",
                      height: "20px"
                    }}
                  />

                  <div className="maintain-connected">
                    <input className={userData.maintainConnected === true ? "checked" : "unchecked"} checked={userData.maintainConnected} type='checkbox' name="maintainConnected" onClick={(e)=>{handleInputs(e)}} ></input>
                    <span>Manter conectado por 7 dias</span>
                  </div>
                  <button type='submit'>Acessar conta</button>

                </form>
                <span className='redefine-pass'>Esqueceu sua senha? <div className='link' onClick={()=>{setRecoverPassTrigger(true)}}>Clique aqui.</div></span>
                <span className='no-account'>Não tem conta?<span className='link' onClick={()=>{navigate("/criar-conta-cliente")}}>Cadastre-se aqui.</span></span>
              </div>
            </div>
          </>
        : <RedefinePassPopup setRecoverPassTrigger={setRecoverPassTrigger} active={recoverPassTrigger} recoverPass={recoverPass}/>
      }
    </div>
  )
}

export default SigninCustomer;