import axios from "axios";
import { useEffect, useState } from 'react';
import { FaCopy, FaRegPlusSquare } from "react-icons/fa";
import { IoIosCloudUpload } from 'react-icons/io';
import { MdDeleteForever } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import DeleteConfirmPopUp from '../../../../components/commom/delete-confirm-pop-up/DeleteConfirmPopUp';
import { useAuth } from '../../../../context/AuthContext';
import "./Marketing.css";

const Marketing = () => {

  const { user } = useAuth();

  const [images, setImages] = useState([]);
  const [recImages, setRecImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletePopUpStatus, setDeletePopUpStatus] = useState(false);
  const [link, setLink] = useState(user != null ? user.profileLink : null);
  const [avaibleImagesSlots, setAvaibleImagesSlots] = useState(0);
  const [imageToDelete, setImageToDelete] = useState({

    e: "",
    image: ""
  });

  const notifySuccess = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = (message) => toast.error(message, {
      position: "top-right",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });

  const handleInput = (e) =>{


    let array = [...images];
    let avaibleImagesSlot = 10 - recImages.length;
    array.push(...e.target.files);

    setAvaibleImagesSlots(avaibleImagesSlot);
    if(array.length > 10 ){

      notifyError("Adicione no máximo 10 imagens!");
    }else if(array.length <= 10){

      if(array.length <= avaibleImagesSlot){

        setImages(array);


      }else{

        notifyError("Você só pode adicionar mais " + avaibleImagesSlot + " imagens!");

      }

    }

  }

  const deleteImageBeforeSave = (e, i) => {

    e.preventDefault();

    let array = images;
    let nArray = array.filter((image, index)=>{

      return index != i;
    })
    setImages(nArray)
  }

  const deleteImageTrigger = (e, image) => {

    setDeletePopUpStatus(true);

    setImageToDelete({

      e: e,
      image: image
    })
  }

  const deleteImage = (e, data) => {

    if(e){

      e.preventDefault();

      axios.delete(`/api/account/marketing/delete-image/${data.image.id}`, {params:{

        id: data.image.id,
        image: data.image.image,
        barberId: user.id
      }})
      .then(res=>{

        if(res.data.success){

          setDeletePopUpStatus(false);
          setImages([]);
          window.location.reload();
        }
      })
      .catch(err=>{

        //DELETE IMAGES

        setTimeout(()=>{
  
          if(err.response.status === 500){
  
              notifyError(err.response.data.message);
          }else if(err.response.status === 404){

              notifyError(err.response.data.message);
          }else{
              
          }
        }, 1500);

      });
    }

  }

  const uploadImages = (e) => {

    setLoading(true);

    e.preventDefault();

    const formdata = new FormData();
    formdata.append("barberId", user.id);
    
    images.map((image)=>{
                
      formdata.append('image', image);
    });

    axios.post('/api/account/marketing/images', formdata).then(res=>{

      if(res.data.success && res.data.images.length > 0){

        res.data.images.map((file)=>{

          axios.post("/api/account/marketing/save-images", {params:{

              id: uuidv4(),
              barberId: user.id,
              image: file.filename
          }}).then(res=>{

            if(res.data.success){

              setTimeout(()=>{

                notifySuccess("Imagens salvas com sucesso!");

                setTimeout(()=>{

                  setLoading(false);
                  setImages([]);
                }, 3000);

              }, 3000);
            }
          }).catch(err=>{

            //SAVE IMAGES IN DB

            setTimeout(()=>{
      
              if(err.response.status === 500){
      
                  notifyError(err.response.data.message);
              }else{
                  
              }
            }, 1500);
          });
        })
      }else{

        setTimeout(()=>{

          notifyError("Erro ao salvar imagens, tente novamente.");

          setTimeout(()=>{

            setLoading(false);
            setImages([]);
          }, 3000);
          
        }, 3000);
      }
    }).catch(err=>{

      //SAVE IMAGES IN FILES

      setTimeout(()=>{

        if(err.response.status === 500){

            notifyError(err.response.data.message);
        }else{
            
        }
      }, 1500);
    });
  }

  const generateLink = () => {

    if(user != null){

      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
  
      for (let i = 0; i < 12; i++) {
          const randomIndex = Math.floor(Math.random() * charactersLength);
          result += characters.charAt(randomIndex);
      }

      setLink(result);

      axios.post(`/api/barber/update-profile-link/${user.id}`, {

          profileLink: result
      })
      .then(res=>{

          if(res.data.success){

            sessionStorage.setItem("userData", JSON.stringify({
              ...user,
              profileLink: result
            }))

            notifySuccess("Novo link salvo!")
          }
      })
      .catch(err=>{

        //SAVE PROFILE LINK

        setTimeout(()=>{

          if(err.response.status === 500){

            notifyError(err.response.data.message);
          }else if(err.response.status === 400){

            notifyError(err.response.data.message);
          }else{
              
          }
        }, 1500);

      });
    }

  }

  const copy = () => {

    navigator.clipboard.writeText(`https://app.srbarberoficial.com.br/fazer-agendamento?pc=${link}`);
    notifySuccess("Link copiado!")
  }

  useEffect(()=>{

    if(user != null){

      axios.get(`/api/account/marketing/images/${user.id}`)
      .then(res=>{

        if(res.data.images.length > 0){

          setRecImages(res.data.images);
        }
      })
      .catch(err=>{

        //IMAGES

        setTimeout(()=>{

          if(err.response.status === 500){

            notifyError(err.response.data.message);
          }else if(err.response.status === 404){

            notifyError(err.response.data.message);
          }else{
              
          }
        }, 1500);

      });
    }
    
  },[images]);

  return (
    <>

      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <ToastContainer />
      {

        deletePopUpStatus
          ?<DeleteConfirmPopUp message={"Deletar essa imagem?"} data={imageToDelete} cancel={setDeletePopUpStatus} action={deleteImage} cancelBtnText={"Cancelar"} actionBtnText={"Deletar"}/> 
          : null
      }
      {
        images.length > 0
          ?
            <div className='images-pop-up'>
              <div className='box'>

                <h2>Adicionar essas imagens ?</h2>
                
                  {
                    images.length <= avaibleImagesSlots
                      ? <div className='images-container'>
                            {
                              images.map((image, index)=>{

                                return <div key={index} className='img-container'>
                                          <button type='button' className='icon-container' onClick={(e)=>{deleteImageBeforeSave(e, index)}}><MdDeleteForever className="icon"/></button>
                                          <img src={URL.createObjectURL(image)}></img>
                                        </div>
                              })
                            } 
                            {
                              images.length < avaibleImagesSlots
                              ? <div className='add-more-container'>
                                  <input multiple type='file' onChange={(e)=>{handleInput(e)}}></input>
                                  <button className=''><FaRegPlusSquare className='icon'/></button>
                                </div>
                              : null
                            }
                            
                        </div>
                      : null
                      
                  }

                  <div className='buttons'>
                    <button type='button' onClick={()=>{setImages([])}} disabled={loading ? true : false} className='cancel'>Cancelar</button>
                    <button type='button' onClick={(e)=>{uploadImages(e)}} disabled={loading ? true : false}  className='add'>
                      <span>{loading ? "Adicionando imagens" : "Adicionar imagens"}</span>
                      {

                        loading
                          ? null
                          : null
                      }
                    </button>
                  </div>
              </div>
            </div>
          : null
      }
    <div className='marketing'>
      <div className="main">
        <div className="box">
          <h3>Link de divulgação</h3>
          <p>Divulgue o link da sua barbearia para que seu cliente possam fazer agendamentos, conferir os históricos de serviços, etc.</p>
          <div className="generate-link-container">
            <div className="input">
              {
                link != null
                ? <span>http://app.srbarberoficial.com.br/fazer-agendamento?pc={link}</span>
                : <span>Clique no botão ao lado para gerar um no link</span>

              }
              <FaCopy className="icon" onClick={()=>{copy()}} />
            </div>
            <button type='button' className='generate-link-btn' onClick={()=>{generateLink()}}>Gerar novo link</button>
          </div>
        </div>
        <div className="box">
          <h3>Imagens</h3>
          <p>Adicione imagens da sua barbearia e serviçoes executados para que seus cliente conheçam mais sobre o trabalho da sua barbearia!</p>
          {

            recImages.length > 0
              ? recImages.length <= 10
                  ? <div className='images-container'>

                        <div className="desktop">
                          <div className="list">
                            {
                              recImages.map((recImage)=>{

                                return <div className='img-container'>
                                        <button type='button' className='icon-container' onClick={(e)=>{deleteImageTrigger(e, recImage)}}><MdDeleteForever className="icon"/></button>
                                        <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/gallery/${user.id}/${recImage.image}`}></img>
                                      </div>
                              })

                            } 
                            {

                              recImages.length < 10
                                ? <div className='add-more-container'>
                                    <input multiple type='file' onChange={(e)=>{handleInput(e)}}></input>
                                    <button className=''><FaRegPlusSquare className='icon'/></button>
                                  </div>
                                : null
                            }
                          </div>
                          
                        </div>
                        <div className="mobile">
                          <div className="list">
                            {
                              recImages.map((recImage)=>{

                                return <div className='img-container'>
                                        <button type='button' className='icon-container' onClick={(e)=>{deleteImageTrigger(e, recImage)}}><MdDeleteForever className="icon"/></button>
                                        <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/gallery/${user.id}/${recImage.image}`}></img>
                                      </div>
                              })
                            } 
                          </div>

                          {
                            recImages.length < 10
                            ? <div className='add-more-container'>
                                <input multiple type='file' onChange={(e)=>{handleInput(e)}}></input>
                                <button className=''>Adicionar imagens</button>
                              </div>
                            : <h3>Você atingiu o limite de 10 imagens!</h3>
                          }
                        </div>
                      
                  </div>
                  : null
                
            : <div className='add-images'>
                <input multiple type='file' onChange={(e)=>{handleInput(e)}}></input>

                <IoIosCloudUpload className='icon' />
                <span>
                    Clique para adicionar imagens da sua barbearia
                </span>
              </div>

            }
        </div>
      </div>
    </div>
  </>

  )
}

export default Marketing