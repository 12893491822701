import { AiFillHome, AiFillCalendar, AiFillCheckCircle, AiFillCloseCircle, AiFillClockCircle } from "react-icons/ai";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BiSolidUserCircle, BiLogOut, BiSolidReport, BiSolidBusiness, BiSupport } from "react-icons/bi";
import { BsFillFilePersonFill } from "react-icons/bs";
import { FaListUl, FaUsers } from "react-icons/fa";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { IoIosInformationCircle, IoMdCard, IoMdSettings } from "react-icons/io";
import { IoMegaphone } from "react-icons/io5";
import { LuSettings2 } from "react-icons/lu";



export const barberNavigationLinks = [

    {id:0, title: 'Home', path: "/", icon: <AiFillHome className={`nav-link-icon`}/>},
    {id:1, title: 'Agenda', path: "/schedule", icon: <AiFillCalendar className={`nav-link-icon`}/>},
    {id:2, title: 'Profissionais', path: "/professionals", icon: <BsFillFilePersonFill className={`nav-link-icon`}/>},
    {id:3, title: 'Serviços', path: "/services", icon: <FaListUl className={`nav-link-icon`}/>},
    {id:4, title: 'Financeiro', path: "/finances", icon: <RiMoneyDollarCircleFill className={`nav-link-icon`}/>},
    {id:5, title: 'Conta', path: "/account", icon: <BiSolidUserCircle className={`nav-link-icon`}/>},
    {id:6, title: 'Sair', path: "/logout", icon: <BiLogOut className={`nav-link-icon`}/>},
]

export const barberMenu = [

    {id:0, title: 'Home', path: "/", icon: <AiFillHome className={`nav-link-icon`}/>},
    {id:1, title: 'Agenda', path: "/agenda", icon: <AiFillCalendar className={`nav-link-icon`}/>},
    {id:2, title: 'Cadastros', path: "/cadastros", icon: <MdFormatListBulletedAdd className={`nav-link-icon`}/>, submenus: [

        {
            id: 1, title: 'Profissionais', path: '/cadastros/profissionais', icon: <FaUsers className={`nav-link-icon`}/>,
        },
        {

            id: 2, title: 'Serviços', path: '/cadastros/servicos', icon: <FaListUl className={`nav-link-icon`}/>
        }

    ]},
    {id:3, title: 'Relatórios', path: "/relatorios", icon: <BiSolidReport className={`nav-link-icon`}/>, submenus: [

        {
            id: 1, title: 'Financeiro', path: '/relatorios/financeiro', icon: <RiMoneyDollarCircleFill className={`nav-link-icon`}/>,
        },
        {

            id: 2, title: 'Profissionais', path: '/relatorios/profissionais', icon: <FaUsers className={`nav-link-icon`}/>
        },
        {

            id: 3, title: 'Serviços', path: '/relatorios/servicos', icon: <FaListUl className={`nav-link-icon`}/>
        }

]},
    {id:4, title: 'Minha barbearia', path: "/minha-barbearia", icon: <BiSolidBusiness className={`nav-link-icon`}/>, submenus: [

        {
            id: 1, title: 'Regras de negócio', path: '/minha-barbearia/regras-de-negocio', icon: <LuSettings2 className={`nav-link-icon`}/>,
        },
        {

            id: 2, title: 'Marketing', path: '/minha-barbearia/marketing', icon: <IoMegaphone className={`nav-link-icon`}/>
        },
        {

            id: 3, title: 'Informações', path: '/minha-barbearia/informacoes', icon: <IoIosInformationCircle className={`nav-link-icon`}/>
        }

    ]},
    {id:5, title: 'Conta', path: "/conta", icon: <BiSolidUserCircle className={`nav-link-icon`}/>, submenus: [

        {
            id: 1, title: 'Meu plano', path: '/conta/meu-plano', icon: <IoMdCard className={`nav-link-icon`}/>,
        },
        {

            id: 2, title: 'Configurações', path: '/conta/configuracoes', icon: <IoMdSettings className={`nav-link-icon`}/>
        },
        {

            id: 3, title: 'Suporte', path: '/conta/suporte', icon: <BiSupport className={`nav-link-icon`}/>
        }

    ]},
    {id:6, title: 'Sair', path: "/sair", icon: <BiLogOut className={`nav-link-icon`}/>},
]

export const customerNavigationLinks = [

    {id:0, title: 'Reservar Horário', path: "/fazer-agendamento", icon: <AiFillClockCircle className={`nav-link-icon`}/>},
    {id:1, title: 'Meus Agendamentos', path: "/meus-agendamentos", icon: <AiFillCalendar className={`nav-link-icon`}/>},
    {id:2, title: 'Minha Conta', path: "/minha-conta-cliente", icon: <BsFillFilePersonFill className={`nav-link-icon`}/>},
    {id:3, title: 'Sair', path: "/sair-cliente", icon: <BiLogOut className={`nav-link-icon`}/>},

]

export const dashboardCardsData = [

    {id:1, title: "Agendamentos", info: "10", icon: <AiFillCalendar className="grid-c-icon"/>, aditionalClass: "booking"},
    {id:2, title: "Receita", info: "R$325,00", icon: <RiMoneyDollarCircleFill className="grid-c-icon"/>, aditionalClass: "revenue"},
    {id:3, title: "Serviços Executados", info: "5", icon: <AiFillCheckCircle className="grid-c-icon"/>, aditionalClass: "services-done"},
]

export const financesCardsData = [

    {id:1, title: "Executados", info: "520", icon: <AiFillCheckCircle className="grid-c-icon"/>, aditionalClass: "done"},
    {id:2, title: "Não Executados", info: "325", icon: <AiFillCloseCircle className="grid-c-icon"/>, aditionalClass: "not-done"},
    {id:3, title: "Em aberto", info: "1024", icon: <AiFillClockCircle className="grid-c-icon"/>, aditionalClass: "open"},
]

export const priceCardDataAnual = [

    {id: 0, title: "1 profissional", price: "19,90", dashedPrice: "R$39,00", totalAmount: "R$238,80"},
    {id: 1, title: "2 à 5 profissionais", price: "35,90", dashedPrice: "R$49,00", totalAmount: "R$430,80"},
    {id: 2, title: "5 à 12 profissionais", price: "59,90", dashedPrice: "R$84,00", totalAmount: "R$718,80"},
    {id: 3, title: "12+ profissionais", price: "99,90", dashedPrice: "R$140,00", totalAmount: "R$1198,80"},
]

export const serviceDurationData = [

    {id: "10", name: "10 minutos"},
    {id: "15", name: "15 minutos"},
    {id: "20", name: "20 minutos"},
    {id: "25", name: "25 minutos"},
    {id: "30", name: "30 minutos"},
    {id: "35", name: "35 minutos"},
    {id: "40", name: "40 minutos"},
    {id: "45", name: "45 minutos"},
    {id: "50", name: "50 minutos"},
    {id: "55", name: "55 minutos"},
    {id: "60", name: "60 minutos"},
    {id: "65", name: "65 minutos"},
    {id: "70", name: "70 minutos"},
    {id: "75", name: "75 minutos"},
    {id: "80", name: "80 minutos"},
    {id: "85", name: "85 minutos"},
    {id: "90", name: "90 minutos"},
];

export const intervalTime = [

    {id: "10", name: "10 minutos"},
    {id: "15", name: "15 minutos"},
    {id: "20", name: "20 minutos"},
    {id: "25", name: "25 minutos"},
    {id: "30", name: "30 minutos"},
    {id: "35", name: "35 minutos"},
    {id: "40", name: "40 minutos"},
    {id: "45", name: "45 minutos"},
    {id: "50", name: "50 minutos"},
    {id: "55", name: "55 minutos"},
    {id: "60", name: "60 minutos"},
];

export const faq = [

    {id: 1, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 2, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 3, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 4, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 5, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 6, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 7, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 8, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 9, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
    {id: 10, question: "Meu cliente consegue fazer agendamento sozinho?", answer: "Claro! Seu cliente poderá ter um dashboard onde fará agendamentos, terá acesso ao histórico de serviços e muito mais!"},
]

export const plans = [

    {id: "price_1PXCMdDq8zUWBlVvzkPwkJsJ", name: "Plano Premium Anual", price: "R$459.00", professionalsNumber: 5},
    {id: "price_1PXCM4Dq8zUWBlVvspTRlNJX", name: "Plano Premium Mensal", price: "R$45.90", professionalsNumber: 5},
    {id: "price_1PXCMJDq8zUWBlVvAJ1gub5L", name: "Plano Diamond Mensal", price: "R$89.90", professionalsNumber: 0},
];

export const planName = (planId) => {

    if(planId === "price_1PXCMdDq8zUWBlVvzkPwkJsJ"){

        return {planTitle: "Plano Premium Anual", slashedPrice: "R$550,80", planPrice: "R$459,00", priceN: "459", centN: "00", professionalsNumber: 5};
    }else if(planId === "price_1PXCM4Dq8zUWBlVvspTRlNJX"){

        return {planTitle: "Plano Premium Mensal", slashedPrice: "R$69,90", planPrice: "R$45,90", priceN: "45", centN: "90", professionalsNumber: 5};
    }else if(planId === "price_1PXCMJDq8zUWBlVvAJ1gub5L"){

        return {planTitle: "Plano Diamond Mensal", slashedPrice: "R$109,90", planPrice: "R$89,90", priceN: "89", centN: "90", professionalsNumber: 0};
    }else if(planId === "free-trial"){

        return {planTitle: "Teste Grátis", planPrice: "R$0,00", professionalsNumber: 0};

    }
}

export const states = [

    { id: 'AC', name: 'Acre' },
    { id: 'AL', name: 'Alagoas' },
    { id: 'AP', name: 'Amapá' },
    { id: 'AM', name: 'Amazonas' },
    { id: 'BA', name: 'Bahia' },
    { id: 'CE', name: 'Ceará' },
    { id: 'DF', name: 'Distrito Federal' },
    { id: 'ES', name: 'Espirito Santo' },
    { id: 'GO', name: 'Goiás' },
    { id: 'MA', name: 'Maranhão' },
    { id: 'MS', name: 'Mato Grosso do Sul' },
    { id: 'MT', name: 'Mato Grosso' },
    { id: 'MG', name: 'Minas Gerais' },
    { id: 'PA', name: 'Pará' },
    { id: 'PB', name: 'Paraíba' },
    { id: 'PR', name: 'Paraná' },
    { id: 'PE', name: 'Pernambuco' },
    { id: 'PI', name: 'Piauí' },
    { id: 'RJ', name: 'Rio de Janeiro' },
    { id: 'RN', name: 'Rio Grande do Norte' },
    { id: 'RS', name: 'Rio Grande do Sul' },
    { id: 'RO', name: 'Rondônia' },
    { id: 'RR', name: 'Roraima' },
    { id: 'SC', name: 'Santa Catarina' },
    { id: 'SP', name: 'São Paulo' },
    { id: 'SE', name: 'Sergipe' },
    { id: 'TO', name: 'Tocantins' }

]
