export const getAddress = async (postalCode) => {

    const value = postalCode.replace("-", "");
    const isNum = /^\d+$/.test(value);

    if(value.length >= 8 && isNum){

        const response = await fetch(`https://viacep.com.br/ws/${postalCode}/json/`)

        const address = await response.json()

        return address;

    }
}

export const getCookie = (name) => {
    
    let cookieArr = document.cookie.split(";");

    for(let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");

        // Remover espaços em branco do nome do cookie e comparar
        if(name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Retornar null se o cookie não for encontrado
    return null;
}
