import AddProfessionalWelcome from '../components/barber/welcome-steps/AddProfessionalWelcome';
import AddServiceWelcome from '../components/barber/welcome-steps/AddServiceWelcome';
import InitialParametersWelcome from '../components/barber/welcome-steps/InitialParametersWelcome';
import EnterpriseInfo from '../generalComponents/fragments/signup-steps/enterprise-info/EnterpriseInfo';

export const useForm = (setProfessionalsList, professionalsList, setServicesList, servicesList, setInitialParameters, initialParameters, setUser, user, handleInput, handleSubmit, setCurrentStep, currentStep, currentUser) => {
    
    const formComponents = [
      <EnterpriseInfo handleInput={handleInput} user={user} setUser={setUser}/>,
      <AddProfessionalWelcome professionalsList={professionalsList} setProfessionalsList={setProfessionalsList} setCurrentStep={setCurrentStep} currentStep={currentStep} currentUser={currentUser}/>,
      <AddServiceWelcome setServicesList={setServicesList} servicesList={servicesList} professionalsList={professionalsList} setCurrentStep={setCurrentStep} currentStep={currentStep} currentUser={currentUser}/>,
      <InitialParametersWelcome setInitialParameters={setInitialParameters} initialParameters={initialParameters} setCurrentStep={setCurrentStep} currentStep={currentStep} handleSubmit={handleSubmit}/>,
  
    ];

    const changeStep = (i, e) => {

        if(e) e.preventDefault();

        if(i < 0 || i >= formComponents.length) return;
        setCurrentStep(i);
    }

  return {
    currentStep,
    currentComponent: formComponents[currentStep],
    changeStep,
    isFirstStep: currentStep === 0 ? true : false,
    isLastStep: currentStep + 1 === formComponents.length ? true : false,
  };
}
