import React, { useEffect, useState } from 'react'
import LoadingPopup from '../../../../components/commom/loading-pop-up/LoadingPopUp';
import axios from 'axios';
import "./MyPlan.css";
import { FaDotCircle } from 'react-icons/fa';
import { planName } from '../../../../utils/Data';
import { useAuth } from '../../../../context/AuthContext';
import loadingBlack from "../../../../utils/images/loading_black.gif";

const MyPlan = () => {

  const { user, subscription } = useAuth();

  const [state, setState] = useState("empty");
  
  const createCustomerPortalSession = async (e) => {

    setState("loading");

    e.preventDefault();

    await axios.get(`/api/subscriptions/${user.id}`)
    .then(async res=>{

        if(res.data.success){

            await axios.post("/api/account/create-customer-portal-session", {

                stripeCustomerId: res.data.data.stripeCustomerId
            })
            .then(resCustomerPortal=>{

                setTimeout(()=>{

                    window.location.href = resCustomerPortal.data.data.url;
                    setTimeout(()=>{
                        setState("empty");

                    }, 1000);
                }, 1500);
            })
            .catch(err=>{

                setTimeout(()=>{

                    setState("error");
                }, 1500);

            }); 
        }
    })
    .catch(err=>{

    });

  }

  if(
    subscription == null &&
    user == null
  ){

    return <div style={{height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", boxSizing: "border-box", gap: "10px"}}>
              <img style={{width: "50px", height: "50px", fontSize: "16px", fontWeight: "700"}} src={loadingBlack}/>
              <span style={{fontSize: "16px", fontWeight: "600"}}>Carregando</span>
            </div>
  }

  return (
    <>
      {

        state === "loading"
          ? <LoadingPopup state={"loading"} message={"Redirecionando..."}/>
          : state === "error"
              ? <LoadingPopup state={"error"} message={"Erro ao acessar portal de gerenciamento de assinaturas, tente novamente."}/>
              : null
        }
        <div className='my-plan'>
          <div className="box">
            <div className='plan-details'>
              {
                  subscription != null && subscription.planId
                  ? <div className='plan-info'>
                  <span className='name'>{subscription.planId != null && subscription.planId != "free-trial" ? planName(subscription.planId).planTitle : 
                    "Teste Grátis"}</span>
                  <span className='price'>{subscription.planId != null && subscription.planId != "free-trial" ? planName(subscription.planId).planPrice : "R$0,00"}/mês</span></div>
                  : null
              }
              <li>{subscription.planId && subscription.planId != "free-trial" && planName(subscription.planId).professionalsNumber != 0 ? "Até " + planName(subscription.planId).professionalsNumber + " profissionais" : "Ilimitado"}</li>
              <li>Cobranças automáticas</li>
              <br/>
              <span className='plan-status-container'><span>Status:</span><FaDotCircle className='icon' /><span className='plan-status'>
  Ativado</span></span>
          </div>

            {

              subscription.planId != "free-trial"
                ? <>
                  <span className="info">Ainda não possuímos um gerenciamento direto das suas faturas aqui na plataforma, usamos o Stripe como gateway de pagamento e ele oferece um portal de gerenciamento completo onde você pode ver suas faturas, baixá-las, baixar os comprovantes de pagamento, cancelar sua assinatura por lá e alterar a forma de pagamento.</span>
                  <h3>Para acessar o portal clique no botão abaixo:</h3>
                  <button className='customer-portal-btn' onClick={(e)=>{createCustomerPortalSession(e)}}>Gerenciar assinatura</button>
                </>
              : null
            }
          </div>
        </div>
    </>
    
  )
}

export default MyPlan