import React from 'react';
import { MdDeleteForever } from "react-icons/md";

const ServiceItem = ({index, service, deleteService }) => {

  return (
    <div className='add-service-service-item' style={{display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: "#FAFAFA", padding: "14px 20px", borderRadius: "4px", marginBottom: "10px", gap: "6px"}}>
        <div className='service-name' style={{flex: 1, alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {service.name}
        </div>
        <div className='service-email' style={{flex: 1, alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {service.price}
        </div>
        <div className='service-phoneNumber' style={{flex: 1, alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {service.duration + " minutos"}
        </div>
        <div className='service-professionals' style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
            {

                service.professionals.map(professional=>{

                  return <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{professional.name}</span>
                })
            }
        </div>
        <button onClick={()=>{deleteService(index)}} type='button' className='delete-service-btn' style={{outline: "0", border: "0", cursor: "pointer", backgroundColor: "transparent", color: "red", fontSize: "24px", alignItems: "center"}}>
            <MdDeleteForever/>
        </button>
    </div>
  )
}

export default ServiceItem;