import axios from "axios";
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaPen, FaTrashAlt, FaUser } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import DeleteConfirmPopUp from "../../../../components/commom/delete-confirm-pop-up/DeleteConfirmPopUp";
import { useAuth } from "../../../../context/AuthContext";
import { GeneralBarsContext } from "../../../../context/generalBarsContext";
import "./Professionals.css";

const Professionals = (props) => {

  const { user } = useAuth();

  const navigate = useNavigate();

  const [professionalsList, setProfessionalsList] = useState();
  const [deletePopUpTrigger, setDeletePopUpTrigger] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();
  const [subscription, setSubscription] = useState([]);
  const [professionalsQuantity, setProfessionalsQuantity] = useState(0);

  const {addProfessionalBarState, setAddProfessionalBarState, setSelectedProfessionalData, selectedProfessionalData, setAddProfessionalBarTriggerName} = useContext(GeneralBarsContext);

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  

  const callFunctions = (row) =>{

    setSelectedProfessionalData(row);
    setAddProfessionalBarState(!addProfessionalBarState);
    setAddProfessionalBarTriggerName("edit");
  }

  const callFunctionsBtn = () =>{

    setSelectedProfessionalData(null);
    setAddProfessionalBarState(!addProfessionalBarState);
    setAddProfessionalBarTriggerName("add");

  }

  const deleteProfessional = (e, row) => {

    e.preventDefault();

    axios.delete(`/api/professionals/delete-professional/${row.id}`).then(res=>{

      if(res.data.success){

        axios.delete("/api/professionals/professionals-to-services/delete-professional", {params:{professionalId: row.id}}).then(res=>{

          if(res.data.success){

            navigate("/cadastros/profissionais");
          }
        }).catch(err=>{

          //DELETE PROFESSIONAL

          setTimeout(()=>{

            if(err.response.status === 500){

              notifyError(err.response.data.message);
            }else{
                
            }
          }, 1500);
        });
      }
    }).catch(err=>{

      //DELETE PROFESSIONAL

      setTimeout(()=>{

        if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else{
            
        }
      }, 1500);
    })
  }

  const deleteCallFunctions = (state, row) => {

    setDeletePopUpTrigger(state);
    setRowToDelete(row);
  }

  const columns = [
    {
        name: 'Imagem',
        selector: row => row.image,
        sortable: false,
        cell: (row)=>{

          return <div style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center"}}>

            {
              row.image != null && row.image != ""
              ? <img style={{width: "60px", height: "60px", objectFit: "cover", borderRadius: "100%"}} src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${user.id}/${row.image}`} />
              : <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgray", borderRadius: "100%", width: "60px", height: "60px"}}><FaUser style={{color: "white", width: "22px", height: "22px"}}/></div>
            }
            
            </div>
        },

    },
    {
      name: 'Nome',
      selector: row => row.name,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 2, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.name}</div>
      }

    },
    {
        name: 'Telefone',
        selector: row => row.phoneNumber,
        sortable: true,
        cell: (row)=>{

          return <div style={{display: "flex", flex: 2, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.phoneNumber}</div>
        }
    },
    {
      name: 'E-mail',
      selector: row => row.email,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 2, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.email}</div>
      }

    },
    {
      name: 'Comissão',
      selector: row => row.comission,
      sortable: true,
      cell: (row)=>{

        return <div style={{display: "flex", flex: 2, justifyContent: "center", fontWeight: "600", fontSize: "14px"}}>{row.comission}%</div>
      }

    },
    {
      name: 'Ação',
      selector: row => row.action,
      sortable: false,
      cell: (row)=>{

          return <div style={{display: "flex", flex: 2, justifyContent: "center", flexDirection: "row", gap: "10px"}}>
                    <button style={{padding: "10px 12px", outline: 0, background: "#5A6B7D", border: 0, color: "white", fontWeight: "600", cursor: "pointer", borderRadius: "4px"}} onClick={()=>{callFunctions(row)}}><FaPen /></button>
                    <button style={{padding: "10px 12px", outline: 0, background: "#EE3F2D", border: 0, color: "white", fontWeight: "600", cursor: "pointer", borderRadius: "4px"}} onClick={()=>{deleteCallFunctions(true, row)}}><FaTrashAlt /></button>
                 </div>
      }
      
    },
  ];

  const customStyles = {
    rows: {
        style: {
          width: "100%",
          borderBox: "box-sizing",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "stretch",
          backgroundColor: "#FAFAFA",
          padding: "10px 0px",
          marginTop: "12px",
          borderRadius: "4px"
        },
    },
    headCells: {
        style: {
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px"
        },
    },
    cells: {
        style: {
            
        },
    },
  };

  useEffect(()=>{

    axios.get(`/api/professionals-by-barber/${user.id}`)
    .then(res=>{

      if(res.data.success){

        setProfessionalsList(res.data.data);
      }
    })
    .catch(err=>{

      //PROFESSIONALS

      setTimeout(()=>{

        if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else if(err.response.status === 404){

          notifyError(err.response.data.message);
        }else{
            
        }
      }, 1500);
    });

    axios.get(`/api/subscriptions/${user.id}`).then(resSub=>{

      if(resSub.data.success){

        axios.get(`/api/professionals-by-barber/${user.id}`).then(resP=>{

          if(resP.data.success){

            setSubscription(resSub.data.data);
            setProfessionalsQuantity(resP.data.data.length);
          }
        }).catch(err=>{

          //PROFESSIONALS NUMBER

          setTimeout(()=>{

            if(err.response.status === 500){

              notifyError(err.response.data.message);
            }else if(err.response.status === 404){
    
              notifyError(err.response.data.message);
            }else{
                
            }
          }, 1500);
        });
      }
    }).catch(err=>{

      //SUBSCRIPTION

      setTimeout(()=>{

        if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else{
            
        }
      }, 1500);
    });

  },[]);

  return (

    <>
      {

        deletePopUpTrigger
          ? <DeleteConfirmPopUp message={"Deletar esse profisisonal?"} data={rowToDelete} cancel={setDeletePopUpTrigger} action={deleteProfessional} cancelBtnText={"Cancelar"} actionBtnText={"Deletar"}/>
          : null
      }
      
      <div className="professionals">
        <div className="top">
          {

            subscription.professionalsNumber > 0
            ? <div className="professionals-number">{professionalsQuantity + " de " + subscription.professionalsNumber}</div>
            : <div className="professionals-number">{professionalsQuantity}</div>
          }
          <button onClick={()=>{callFunctionsBtn()}} className="add-professional-btn"><IoMdAdd style={{fontSize: "22px", color: "white"}}/>ADICIONAR PROFISSIONAL</button>
        </div>
        <div className="professionals-list-container">
        <DataTable
            columns={columns}
            data={professionalsList}
            customStyles={customStyles}
            selectableRows
        />
        </div>
      </div>
    </>
  )
}

export default Professionals;