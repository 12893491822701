import { useContext, useState } from "react";
import { IoMdMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./ContentRoutes.css";
import ChangePassModal from "./components/commom/changePassModal/ChangePassModal";
import LoadingPopup from "./components/commom/loading-pop-up/LoadingPopUp";
import Sidebar from "./components/commom/sidebar/Sidebar";
import { GeneralBarsContext } from "./context/generalBarsContext";
import { barberMenu, customerNavigationLinks } from "./utils/Data";
import { RequireAuth } from "./utils/RequireAuth";
import MyPlan from "./views/barber/account/my-plan/MyPlan";
import Settings from "./views/barber/account/settings/Settings";
import Support from "./views/barber/account/support/Support";
import Dashboard from "./views/barber/dashboard/Dashboard";
import BusinessRules from "./views/barber/my-barber/business-rules/BusinessRules";
import Informations from "./views/barber/my-barber/informations/Informations";
import Marketing from "./views/barber/my-barber/marketing/Marketing";
import ReactiveAccount from "./views/barber/reactive-account/ReactiveAccount";
import Professionals from "./views/barber/registrations/professionals/Professionals";
import AddProfessionalBar from "./views/barber/registrations/professionals/addProfessionalBar/AddProfessionalBar";
import Services from "./views/barber/registrations/services/Services";
import AddServiceBar from "./views/barber/registrations/services/addServiceBar/AddServiceBar";
import Finances from "./views/barber/reports/finances/Finances";
import ProfessionalsReports from "./views/barber/reports/professionals/ProfessionalsReports";
import ServicesReports from "./views/barber/reports/services/ServicesReports";
import ScheduleBarber from "./views/barber/schedule/ScheduleBarber";
import AddScheduleBar from "./views/barber/schedule/add-schedule-bar/AddScheduleBar";
import Upgrade from "./views/barber/upgrade/Upgrade";
import Welcome from "./views/barber/welcome/Welcome";
import ElementsProvider from "./views/commom/checkout/ElementsProvider";
import RedefinePassword from "./views/commom/redefine-password/RedefinePassword";
import SigninCustomer from "./views/commom/signin-customer/SigninCustomer";
import Signin from "./views/commom/signin/Signin";
import SignupCustomer from "./views/commom/signup-customer/SignupCustomer";
import Signup from "./views/commom/signup/Signup";
import MyAccount from "./views/customer/customer-account/MyAccount";
import NewBooking from "./views/customer/customer-new-booking/NewBooking";
import MySchedules from "./views/customer/customer-schedules/MySchedules";
import { useAuth } from "./context/AuthContext";
import { BiImageAdd } from "react-icons/bi";
import RedefinePasswordCustomer from "./views/customer/redefine-password-customer/RedefinePasswordCustomer";
import moment from "moment";

const ContentRoutes = (props) =>{

    const {addServiceBarState, setAddServiceBarState} = useContext(GeneralBarsContext);
    const {addProfessionalBarState, setAddProfessionalBarState} = useContext(GeneralBarsContext);
    const navigate = useNavigate();
    const [currentRoute, setCurrentRoute] = useState();
    const location = useLocation();
    const currentUser = JSON.parse(sessionStorage.getItem("currentUserData"));
    const subscriptionStatus = sessionStorage.getItem("subscriptionStatus");
    const alertBarStateS = JSON.parse(sessionStorage.getItem("alertBarState"));
    const [alertBarState, setAlertBarState] = useState(true);

    const [sidebarState, setSidebarState] = useState(false);

    const Layout = ({userType}) => {

        const updateAlertState = () => {

            sessionStorage.setItem("alertBarState", "false");
            setAlertBarState(false);
        }

        const teste =  sessionStorage.getItem("")


        return(

            <>
                <div className="signed-user-container">

                    {

                        subscriptionStatus === "test" && (alertBarStateS != false && alertBarState != false)
                            ?   <div className={`alert-bar ${"test"}`} style={{}}>
                                    <span>Aproveite o teste grátis por 7 dias, estamos ansiosos em tê-lo como um parceiro! 🚀</span>
                                    <button onClick={()=>{updateAlertState()}}><IoClose/></button>
                                </div>
                            : null
                    }
                    

                    <div className="container">

                        <div className="top-bar">
                            <button type="button" onClick={()=>{setSidebarState(!sidebarState)}}><IoMdMenu className="icon" /></button>
                            {
                                currentUser != null
                                ? currentUser.userType === "barber"
                                    ? <div className="user-info" onClick={()=>{navigate("/account")}}><img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/barber/${currentUser.image}`} alt="perfil"/><span>{currentUser.enterpriseName}</span></div>
                                    : <div className="user-info" onClick={()=>{navigate("/customer-account")}}><img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/customer/${currentUser.image}`} alt="perfil"/><span>{currentUser.name}</span></div>
                                : null
                            }
                        </div>
                        {

                        location.pathname != "/bem-vindo"
                        ?   <>
                                <AddProfessionalBar/>
                                <ChangePassModal/>
                                <AddServiceBar/>
                                <AddScheduleBar/>
                                <LoadingPopup/>

                                {

                                    !sidebarState
                                        ? null
                                        : 
                                            <div className="mobile">
                                                <Sidebar setSideBarState={setSidebarState} sidebarState={sidebarState} navigationLinks={userType == "barber" ? barberMenu : customerNavigationLinks} currentRoute={currentRoute} setCurrentRoute={setCurrentRoute}/>
                                            </div>
                                }

                                            <div className="desktop">
                                                <Sidebar setSideBarState={setSidebarState} sidebarState={sidebarState} navigationLinks={userType == "barber" ? barberMenu : customerNavigationLinks} currentRoute={currentRoute} setCurrentRoute={setCurrentRoute}/>
                                            </div>

                                
                                
                            </>
                        : null
                        }


                        <div className={location.pathname != "/bem-vindo" ? "signed-user-content-container" : "signed-user-content-container-bg-transparent"}>
                            <Outlet/>
                        </div>
                    </div>

                </div>
            </>
            
        );
    }
    

    const Logout = ({userType}) => {

        sessionStorage.clear();
        localStorage.removeItem("token");

        console.log(userType)

        if(userType === "barber"){

            navigate("/acessar-conta", {replace: true});
            
        }else{

            navigate("/acessar-conta-cliente", {replace: true});
        }

    }

    return(
        <Routes>
            {/* Public routes */}
            <Route path="criar-conta" element={<Signup/>}/>
            <Route path="criar-conta-cliente" element={<SignupCustomer/>}/>
            <Route path="redefinir-senha" element={<RedefinePassword/>}/>
            <Route path="redefinir-senha-cliente" element={<RedefinePasswordCustomer/>}/>
            <Route path="checkout" element={<ElementsProvider/>}/>
            <Route path="acessar-conta" element={<Signin/>} />
            <Route path="acessar-conta-cliente" element={<SigninCustomer/>} />
            
            <Route path="barbearia" element={<NewBooking/>}/>
            <Route element={<RequireAuth userTypeR="barber"/>}>
                <Route path="upgrade" element={<Upgrade/>}/>
                <Route path="reativar-conta" element={<ReactiveAccount/>}/>
            </Route>

            {/* PROTECTED ROUTES */}
            {/* Barber routes */}
            <Route element={<Layout userType="barber"/>}>
                <Route element={<RequireAuth userTypeR="barber"/>}>

                    <Route path="bem-vindo" element={<Welcome/>}/>
                    <Route path="/" element={<Dashboard/>}/>
                    <Route path="dashboard" element={<Dashboard/>}/>
                    <Route path="agenda" element={<ScheduleBarber/>}/>
                    <Route 
                        path="/cadastros/servicos" 
                        element={<Services
                            setAddServiceBarState={setAddServiceBarState} 
                            addServiceBarState={addServiceBarState}
                                />}
                    />
                    <Route
                        path="/cadastros/profissionais" 
                        element={<Professionals
                            addProfessionalBarState={addProfessionalBarState}
                            setAddProfessionalBarState={setAddProfessionalBarState}
                                />}
                    />
                    <Route path="relatorios/financeiro" element={<Finances/>}/>
                    <Route path="relatorios/profissionais" element={<ProfessionalsReports/>}/>
                    <Route path="relatorios/servicos" element={<ServicesReports/>}/>

                    <Route path="minha-barbearia/regras-de-negocio" element={<BusinessRules/>}/>
                    <Route path="minha-barbearia/marketing" element={<Marketing/>}/>
                    <Route path="minha-barbearia/informacoes" element={<Informations/>}/>

                    <Route path="conta/meu-plano" element={<MyPlan/>}/>
                    <Route path="conta/configuracoes" element={<Settings/>}/>
                    <Route path="conta/suporte" element={<Support/>}/>
                    
                    <Route path="sair" element={<Logout userType={"barber"}/>}/>
                </Route>
            </Route>
                
            {/* Customer routes */}
            <Route element={<Layout userType="customer"/>}>
                <Route element={<RequireAuth userTypeR="customer"/>}>
                    <Route path="/" element={<NewBooking/>}/>
                    <Route path="fazer-agendamento" element={<NewBooking/>}/>
                    <Route path="meus-agendamentos" element={<MySchedules/>}/>
                    <Route path="minha-conta-cliente" element={<MyAccount/>}/>
                    <Route path="sair-cliente" element={<Logout userType={"customer"}/>}/>
                </Route>
            </Route>
        </Routes>
                
    )
}

export default ContentRoutes;