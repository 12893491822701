import React from 'react'

const ServiceItem = ({key, data}) => {
  return (
    <div key={key} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px", backgroundColor: "#ECEFF190", borderRadius: "6px", border: "1px solid #ECEFF1"}}>
        <span style={{fontWeight: "600", color: "#101418"}}>{data.name}</span>
        <span style={{fontSize: "17px", fontWeight: "600", color: "#00796B"}}>{data.revenue}</span>
    </div>
  )
}

export default ServiceItem;