import {ElementsConsumer, PaymentElement, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Checkout from './Checkout';
import { useEffect, useState } from 'react';
import axios from '../../../../node_modules/axios/index';
import { planName } from '../../../utils/Data';
import { useAuth } from '../../../context/AuthContext';
import { getCookie } from '../../../utils/functions/Others';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51OgM9NDq8zUWBlVvO2dMzW9TLxXhaEkJsCzj1tEGTiVQ9TIcfioFNpnE31mCznEozWCHNQBvutIjWxbu4LvEZegH00a62HYidF');

const ElementsProvider = () => {

  const { user } = useAuth();

  const urlParams = new URLSearchParams(window.location.search);
  const planId = urlParams.get('planId');
  const py = urlParams.get('py');
  const cs = urlParams.get('cs');
  const scid = urlParams.get('scid');
  const src = urlParams.get('src');

  const [plan, setPlan] = useState({

    title: "",
    price: ""
  });

  useEffect(() => {

    setPlan({
      title: planName(planId).planTitle,
      price: planName(planId).planPrice,
    });

    if(src == "upgrade"){

      axios.post(`/api/subscriptions/${user.id}`).then(res => {

        axios.post("/api/pixel/events/initiate-checkout", {

          barberId: user.id,
          email: user.email,
          masterName: user.masterName,
          phoneNumber: user.whatsapp,
          city: user.city,
          state: user.state,
          postalCode: user.postalCode,
          subscriptionId: res.data.data.id,
          planId: planId,
          userAgent: navigator.userAgent,
          source: window.location.href,
          fbc: getCookie("_fbc"),
          fbp: getCookie("_fbp"),
        }).then(res => {
    
    
        }).catch(err => {
    
    
        });
      }).catch(err => {


      });

      
    }
    
  }, [planId]);
  
  const options = {
    clientSecret: cs,
    appearance : {
      variables: {
        colorPrimary: '#0570de',
        colorBackground: 'rgb(240, 240, 240)',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '6px',
        fontSize: "14px"
      }
    }
  };

  return (

    <>
    {stripePromise && cs && (
      <Elements stripe={stripePromise} options={options} >
          <Checkout plan={plan} planId={planId} clientSecret={cs} paymentIntent={py} stripeCustomerId={scid} src={src}/>
      </Elements>)}
    </>
    
  );
};

export default ElementsProvider;
