import axios from "axios";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { FormInput, SelectIpt, SelectIptMulti } from "../../../../../components/commom/form-input/FormInput";
import { GeneralBarsContext } from "../../../../../context/generalBarsContext";
import { serviceDurationData } from "../../../../../utils/Data";
import LoadingIcon from "../../../../../utils/images/loading_1.gif";
import "./AddServiceBar.css";
import { useAuth } from "../../../../../context/AuthContext";

const AddServiceBar = () => {

    const notifySuccess = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    const notifyError = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    const {addServiceBarState, setAddServiceBarState, selectedServiceData, addServiceBarTriggerName} = useContext(GeneralBarsContext);

    const { user } = useAuth();

    const [professionalsList, setProfessionalsList] = useState([]);
    const [pListIpt, setPListIpt] = useState([]);

    const [serviceData, setServiceData] = useState({

        id: addServiceBarTriggerName == "edit"
        ? selectedServiceData.id
        : uuidv4(),
        image: "",
        name: addServiceBarTriggerName == "edit"
            ? selectedServiceData.name
            : "",
        price: addServiceBarTriggerName == "edit"
            ? selectedServiceData.price
            : "",
        duration: addServiceBarTriggerName == "edit"
            ? selectedServiceData.duration
            : "",

        professionals: [],

    });  

    const [loading, setLoading] = useState(false);

    const handleInput = (e) => {

        e.preventDefault();

        if(e.target.name == "image"){

            setServiceData({...serviceData, [e.target.name] : e.target.files[0]});
        }else if(e.target.name == "price"){

            let n1 = parseInt(e.target.value.replace(/[\D]+/g,''));

            let tmp = n1+"";
            tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
            if(tmp === "NaN"){

                tmp = "0,00"
            }else if(tmp.length > 6 && tmp.length <= 9){

                tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

            }else if(tmp.length > 9){

                tmp = tmp.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");

            }
            let formattedPrice = "R$ "+tmp;


            setServiceData({...serviceData, [e.target.name] : formattedPrice});


        }else{

            setServiceData({...serviceData, [e.target.name] : e.target.value});

        }
    }

    const handleInputProfessionals = (professionals) => {

        let professionalsArray = [];

        professionals.map((professional)=>{


                const professionalData = professionalsList.filter(professionalItem=>{

                    return professionalItem.id == professional.value
                })

                professionalsArray.push(professionalData[0]);


        });
        setServiceData({...serviceData, professionals: professionalsArray})
    }

    const handleInputServiceDuration = (serviceDuration) => {

        setServiceData({...serviceData, duration: serviceDuration.value});
    }

    const addService = (e) => {

        setLoading(true);

        e.preventDefault();

        const serviceId = uuidv4();

        if(user != null){

            if(addServiceBarTriggerName == "edit"){

                axios.put(`/api/services/edit-service/${serviceData.id}`, {

                    id: serviceData.id,
                    barberId: user.id,
                    name: serviceData.name, 
                    price: serviceData.price, 
                    duration: serviceData.duration,
                    modifiedAt: moment().tz('America/Sao_Paulo').format()
                })
                .then(res=>{
    
                    if(res.data.success === true){

                        axios.delete(`/api/services/professionals-to-services/delete-professionals/${serviceData.id}`)
                        .then(res=>{

                            serviceData.professionals.map((professional)=>{

                                axios.post('/api/services/professionals-to-services/add-professional', {

                                    id: uuidv4(),
                                    professionalId: professional.id,
                                    serviceId: serviceData.id,
                                    barberId: user.id,
                                    createdAt: moment().utc()
                                })
                                .then(res=>{

                                    if(res.data.success === true){
                                        
                                        setTimeout(()=>{

                                            setLoading(false);
                                            notifySuccess('Adicionado com sucesso!');
            
                                            setTimeout(()=>{
            
                                                setAddServiceBarState(!addServiceBarState);
                            
                                                setServiceData({
                            
                                                    id: "",
                                                    image: "",
                                                    name: "",
                                                    price: "",
                                                    duration: "",
                                                    professionals: []
                                                });  
                                            }, 1500);
                                        }, 1500);

                                    }else{


                                    }

                                })
                                .catch(err=>{

                                    //ADD PROFESSIONAL TO SERVICE

                                    setTimeout(()=>{

                                        if(err.response.status === 500){
                                
                                            notifyError(err.response.data.message);
                                        }else{
                                            
                                        }
                                    }, 1500);
                                    setLoading(false);
                                });
                            })
                        })
                        .catch(err=>{

                            //DELETE PROFESSIONALS

                            setTimeout(()=>{

                                if(err.response.status === 500){
                        
                                    notifyError(err.response.data.message);
                                }else{
                                    
                                }
                            }, 1500);
                            setLoading(false);

                        });

                    }
                })
                .catch(err=>{

                    //EDIT SERVICE

                    setTimeout(()=>{

                        if(err.response.status === 500){
                
                            notifyError(err.response.data.message);
                        }else if(err.response.status === 400){
                
                            notifyError(err.response.data.message);
                        }else{
                            
                        }
                      }, 1500);
                    setLoading(false);
                });     
            }else{

                axios.post('/api/services/add-service', {

                    id: serviceId,
                    barberId: user.id,
                    name: serviceData.name, 
                    price: serviceData.price, 
                    duration: serviceData.duration,
                    createdAt: moment().tz('America/Sao_Paulo').format()
                })
                .then(res=>{
    
                    if(res.data.success === true){

                        serviceData.professionals.map((professional)=>{

                            axios.post('/api/services/professionals-to-services/add-professional', {

                                id: uuidv4(),
                                professionalId: professional.id,
                                serviceId: serviceId,
                                barberId: user.id,
                                createdAt: moment().utc()
                            })
                            .then(res=>{

                                if(res.data.success === true){
                                    
                                    setTimeout(()=>{

                                        setLoading(false);
                                        notifySuccess('Adicionado com sucesso!');
        
                                        setTimeout(()=>{
        
                                            setAddServiceBarState(!addServiceBarState);
                        
                                            setServiceData({
                        
                                                id: "",
                                                image: "",
                                                name: "",
                                                price: "",
                                                duration: "",
                                                professionals: []
                                            });  
                                        }, 1500);
                                    }, 1500);

                                }else{

                                }

                            })
                            .catch(err=>{

                                //ADD PROFESSIONAL TO SERVICE

                                setTimeout(()=>{

                                    if(err.response.status === 500){
                            
                                        notifyError(err.response.data.message);
                                    }else{
                                        
                                    }
                                }, 1500);
                                setLoading(false);
                            });
                        })
                    }
                })
                .catch(err=>{

                    //ADD SERVICE

                    setTimeout(()=>{

                        if(err.response.status === 500){
                
                            notifyError(err.response.data.message);
                        }else{
                            
                        }
                      }, 1500);
                    setLoading(false);
                });
            }
        }
    }

    useEffect(()=>{

        const fetchData = async () => {

            if(addServiceBarTriggerName == "edit"){

                let p2s = [];

                const requestProfessionalsToServices = await axios.get(`/api/professionals-to-services/${serviceData.id}`);

                //Need to push into an array because the direct response cannot be mapped
                p2s.push(requestProfessionalsToServices.data.data);
                
                Promise.all(


                    p2s[0].map(async(item)=>{

                        let res;

                        res = await axios.get(`/api/professionals/${item.professionalId}`);

                        return res.data;
                    })
                ).then(res=>{

                    let professionals = [];

                    res.map((professionalData)=>{

                        professionals.push(professionalData.data);
                    });                    

                    serviceData.professionals = professionals;
                    setPListIpt(professionals);
                });

                const allProfessionalsReq = await axios.get(`/api/professionals-by-barber/${user.id}`);
                setProfessionalsList(allProfessionalsReq.data.data);
            }else{

                const requestProfessionals = await axios.get(`/api/professionals-by-barber/${user.id}`);

                setProfessionalsList(requestProfessionals.data.data)
            }
        }
        if(user){
            fetchData();
        }
    },[]);

  return (
    <>
        {
            addServiceBarState
            ?
            <div className="add-service-bar-bg">
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* Same as */}
            <ToastContainer />
                <div className="container">
                    <div className="top-container">
                        <span className="title">Adicionar Serviço</span>
                        <IoClose onClick={()=>{setAddServiceBarState(!addServiceBarState)}} className="icon"/>
                    </div>
                    {/* 
                        IMPLEMENT AFTER
                        <div className="add-service-bar-service-image-main-container">
                            <input type="file" className="add-service-bar-service-image-container" onChange={(e)=>handleInput(e)} name="image"/>
                            {

                                addServiceBarTriggerName == "edit"
                                ? serviceData.image != ""
                                    ? <img id="1" src={URL.createObjectURL(serviceData.image)} className="add-service-bar-service-image" />
                                    : <img id="1" src={`https://srbarberoficial.com.br/uploads/services/${user.id}/${selectedServiceData.image}`} className="add-service-bar-service-image" />
                                    
                                : serviceData.image != ""
                                    ? <img id="1" src={URL.createObjectURL(serviceData.image)} className="add-service-bar-service-image" />
                                    : <BiImageAdd className="add-service-bar-image-icon"/>
                            }

                        </div>
                    */}
                    <div className="fields">
                        <FormInput
                            inputData={{

                                id: 2,
                                name: "name",
                                label: "Nome do serviço *",
                                placeholder: "Nome do serviço",
                                pattern: "^[A-Za-z_ ]{3,50}$",
                                error: "O nome da empresa deve ter entre 3 e 50 caractéres!",
                                value: serviceData.name,
                                required: true,
                                
                            }}
                            handleInput={handleInput}
                        />

                        <FormInput
                            inputData={{

                                id: 3,
                                name: "price",
                                label: "Preço do serviço *",
                                placeholder: "Preço do serviço",
                                value: serviceData.price,
                                required: true,
                                
                            }}
                            handleInput={handleInput}
                        />

                        <SelectIpt

                            inputData={{
                                handleInput: handleInputServiceDuration,
                                list: serviceDurationData,
                                label: "Tempo de duração",
                                placeholder: "Selecione...",
                            }}
                            recInputData={addServiceBarTriggerName == "edit" ? {value: serviceData.duration, label: serviceData.duration + " minutos"} : null}
                        />

                        {

                            professionalsList.length > 0 ? <SelectIptMulti
                            inputData={{
                                handleInput: handleInputProfessionals,
                                list: professionalsList.length > 0 ? professionalsList : null,
                                label: "Profissional(ais)",
                                placeholder: "Selecione..."
                            }}
                            recInputData={addServiceBarTriggerName == "edit" ? pListIpt.map(professional=>({

                                value: professional.id,
                                label: professional.name
                            })) : null}
                        /> : null
                        }
                        
                    </div>
                    <div className="bottom-container">

                        {

                            addServiceBarTriggerName == "edit"
                                ? !loading
                                    ? serviceData.name != "" && serviceData.price != "" && serviceData.duration != "" && serviceData.professionals.length > 0
                                        ? <button onClick={(e)=>{addService(e)}} className="add-service-btn">Editar Serviço</button>
                                        : <button onClick={(e)=>{addService(e)}} className="add-service-btn" disabled>Editar Serviço</button>
                                    : <button style={{gap: "10px"}} className="add-service-btn"><img style={{width: "20px", height: "20px"}} src={LoadingIcon}/>Salvando Alterações</button>
                                : !loading
                                    ? serviceData.name != "" && serviceData.price != "" && serviceData.duration != "" && serviceData.professionals.length > 0
                                        ? <button onClick={(e)=>{addService(e)}} className="add-service-btn">Adicionar Serviço</button>
                                        : <button onClick={(e)=>{addService(e)}} className="add-service-btn" disabled>Adicionar Serviço</button>
                                    : <button style={{gap: "10px"}} className="add-service-btn"><img style={{width: "20px", height: "20px"}} src={LoadingIcon}/>Salvando Alterações</button>

                        }
                    </div>
                </div>
            </div>
            : null
        }
    </>
    
  )
}

export default AddServiceBar;