import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react'

export const AuthContext = createContext();

export const useAuth = () =>{

    return useContext(AuthContext);
}

export const AuthProvider = (props) => {

  const [user, setUser] = useState(null);
  const [businessRules, setBusinessRules] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const updateData = (data) => {

    setUser(data.userData);
    setBusinessRules(data.businessRules);
    setSubscription(data.subscription);
    setIsAuthenticated(true)
  };

    return(

        <AuthContext.Provider value={{user, businessRules, isAuthenticated, subscription, updateData}}>
            {props.children}
        </AuthContext.Provider>
    )
}