import React, { useState } from 'react';
import "./Settings.css";
import { MainInput } from '../../../../components/commom/form-input/FormInput';
import { Colors } from '../../../../utils/Colors';
import axios from 'axios';
import LoadingPopup from '../../../../components/commom/loading-pop-up/LoadingPopUp';
import { useAuth } from '../../../../context/AuthContext';

const Settings = () => {

  const { user } = useAuth();

  const [data, setData] = useState({

    currentPass: "",
    newPass: "",
    confirmNewPass: ""
  });

  const [state, setState] = useState("empty");

  const handleInputs = (e) => {

    setData({...data, [e.target.name] : e.target.value});
  }

  const changePassword = (e) => {

    setState("loading");

    e.preventDefault();

    if(data.newPass === data.confirmNewPass){

      axios.post("/api/account/settings/reset-password", {

        ...data,
        id: user.id
      }).then(res=>{

        if(res.data.success === true){

          axios.post("/api/email/redefine-pass-success", {

            masterName: user.masterName,
            email: user.email
          })
          .then(res=>{

            if(res.data.success === true){

              setTimeout(()=>{

                setState("success");
    
                setData({
    
                  currentPass: "",
                  newPass: "",
                  confirmNewPass: ""
                });
    
                setTimeout(()=>{
    
                  setState("empty");
              
                }, 1500);
              }, 1500);
            }else{

              setTimeout(()=>{

                setState("success");
    
                setData({
    
                  currentPass: "",
                  newPass: "",
                  confirmNewPass: ""
                });
    
                setTimeout(()=>{
    
                  setState("empty");
              
                }, 1500);
              }, 1500);
            }
          })
          .catch(err=>{

            setTimeout(()=>{

              setState("success");
  
              setData({
  
                currentPass: "",
                newPass: "",
                confirmNewPass: ""
              });
  
              setTimeout(()=>{
  
                setState("empty");
            
              }, 1500);
            }, 1500);
          });

        }
      })
      .catch(err=>{

        if(err.status === 401){


        }else{


        }

        setTimeout(()=>{

          setState("error");
          setData({

            currentPass: "",
            newPass: "",
            confirmNewPass: ""
          });

          setTimeout(()=>{

            setState("empty");
          
          }, 1500);
        }, 1500);

        
      });
    }
  }

  const validatePassword = (name) => {

    const passRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

    if(name === "password"){

      if(data.newPass != "" && passRegex.test(data.newPass)){

        return false;
      }else{

        return true;
      }
    }else if(name === "confirmPassword"){

      if(data.confirmNewPass != "" && data.newPass === data.confirmNewPass){

        return false;
      }else{

        return true;
      }
    }
  }

  const validateFields = () => {

    if(
      data.currentPass != "" &&
      !validatePassword("password") &&
      !validatePassword("confirmPassword") &&
      data.newPass === data.confirmNewPass
    ){

      return true
    }else
    
    return false;


  }

  
  return (
    <div className='settings'>
      {

        state === "loading"
        ? <LoadingPopup state={"loading"} message={"Alterando senha..."}/>
        : state === "error"
            ? <LoadingPopup state={"error"} message={"Erro ao alterar senha, tente novamente."}/>
            : state === "success"
                ? <LoadingPopup state={"success"} message={"Senha alterada!"}/>
                : null
      }
      <div className="box">
        <div className="access">
          <h3>Configurações de acesso</h3>
            <MainInput
              data={{
                iconName: "envelope",
                type: "email",
                name: "email",
                label: "E-mail",
                value: user.email,
                disabled: true,
              }}
              iconStyle={{

                color: Colors.MAIN_ICON_COLOR,
                width: "20px",
                height: "20px"
              }}
            />
            <div className="change-password">
              <h4>Mudar senha</h4>
              <MainInput
                data={{
                  type: "password",
                  name: "currentPass",
                  placeholder: "Senha atual",
                  label: "Senha atual",
                  value: data.currentPass,
                  required: true,
                  handleInput: handleInputs
                }}
              />
              <MainInput
                data={{
                  type: "password",
                  name: "newPass",
                  placeholder: "Nova senha",
                  label: "Nova senha",
                  value: data.newPass,
                  required: true,
                  hasError: data.newPass != null ? validatePassword("password").toString() : null,
                  error: "A senha deve conter no mínimo 8 caracters, pelo menos 1 letra, 1 número e 1 carctere especial.",
                  handleInput: handleInputs
                }}
              />
              <MainInput
                data={{
                  type: "password",
                  name: "confirmNewPass",
                  placeholder: "Confirmar senha",
                  label: "Confirmar senha",
                  value: data.confirmNewPass,
                  required: true,
                  pattern: data.newPass,
                  hasError: data.confirmNewPass != null ? validatePassword("confirmPassword").toString() : null,
                  error: "As senhas não coincidem.",
                  handleInput: handleInputs
                }}
              />

              {

                data.currentPass != "" &&
                !validatePassword("password") &&
                !validatePassword("confirmPassword") &&
                data.newPass === data.confirmNewPass
                  ? <button onClick={(e)=>{changePassword(e)}}>Alterar Senha</button>
                  : <button disabled={true} onClick={(e)=>{changePassword(e)}}>Alterar Senha</button>
                  
              }
            </div>
        </div>
      </div>
    </div>
  )
}

export default Settings