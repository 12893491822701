import { useEffect, useState } from 'react';
import axios from 'axios';
import { FormInput, MainInput } from '../../../components/commom/form-input/FormInput';
import "./RedefinePasswordCustomer.css";
import LoadingPopup from '../../../components/commom/loading-pop-up/LoadingPopUp';
import CheckIcon from "../../../utils/images/check_black.gif";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const RedefinePasswordCustomer = () => {

    const navigate = useNavigate();

    const [data, setData] = useState({

        pass: "",
        confirmPass: "",
        token: ""
    });

    const [state, setState] = useState("empty");

    const notifyError = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {
        
        // Extract token from URL when component did mount
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = urlParams.get('token');
    
        if (tokenFromUrl) {
          setData({
            ...data,
            token: tokenFromUrl
          });
        }
      }, []);

    const handleInputs = (e) => {

        setData({...data, [e.target.name] : e.target.value});
    }
    
    const redefinePassword = (e) => {

        setState("loading");

        e.preventDefault();

        if(data.pass === data.confirmPass){

            axios.post("/api/redefine-password-customer", {data})
            .then(res=>{

                if(res.status === 200 && res.data.success == true && res.data.email == true){

                    setTimeout(()=>{

                        setState("success");
                        setTimeout(()=>{

                            setState("empty");
                            navigate("/acessar-conta-cliente");
                        }, 2500);
                    }, 2500);
                }
            })
            .catch(err=>{

                setTimeout(()=>{

                    if(err.response.status === 500){

                        notifyError(err.response.data.message);
                    }else if(err.response.status === 404){

                        notifyError(err.response.data.message);
                    }else{
                        
                    }
                }, 1500);

                setState("error");
            });
        }
    }

  return (
    <div className='redefine-password'>
        <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        <ToastContainer />
        {

            state === "loading"
                ? <LoadingPopup state={"loading"} message={"Alterando sua senha, aguarde..."}/>
                : state === "success"
                    ? <LoadingPopup state={"success"} message={"Senha alterada com sucesso!"}/>
                    : state === "error"
                        ? <LoadingPopup state={"error"} message={"Erro ao alterar a senha, tente novamente."}/>
                        : <></>
        }
        <div className='box'>
            <h2>Redefina a senha da sua conta</h2>
            <span className='subtitle'>Crie uma nova senha segura para sua conta!</span>
            <MainInput
                data={{

                    placeholder: "Senha",
                    name: "pass",
                    value: data.pass,
                    required: true,
                    type: "password",
                    pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
                    error: 'A senha precisa ter no mínimo 8 caracteres, dentre eles 1 letra, 1 número e 1 caractere especial.',
                    handleInput: handleInputs
                }}
            />

            <MainInput
                data={{

                    placeholder: "Confirmar senha",
                    name: "confirmPass",
                    value: data.confirmPass,
                    required: true,
                    type: "password",
                    pattern: data.pass,
                    error: "As senhas digitadas não conferem.",
                    handleInput: handleInputs

                }}
            />
            {

                data.pass != "" && 
                data.confirmPass != "" &&
                data.pass == data.confirmPass
                    ? <button className='redefine-password-btn' onClick={(e)=>{redefinePassword(e)}}>Redefinir senha</button>
                    : <button disabled={true} className='redefine-password-btn' onClick={(e)=>{redefinePassword(e)}}>Redefinir senha</button>
            }
            
        </div>
        
    </div>
  )
}

export default RedefinePasswordCustomer;