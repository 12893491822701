import axios from 'axios';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { AuthInput, MaskedInput } from "../../../components/commom/form-input/FormInput";
import LoadingPopup from "../../../components/commom/loading-pop-up/LoadingPopUp";
import logoGray from "../../../utils/images/logo_gray.svg";
import "./Signup.css";
import moment from 'moment-timezone';
import barber2 from "../../../utils/images/background-signup-no-bg.png";
import background from "../../../utils/images/bg-sn.png";
import { Colors } from '../../../utils/Colors';
import ReactCodeInput from 'react-code-input';
import ReactPixel from 'react-facebook-pixel';
import { FaArrowLeft } from 'react-icons/fa';
import { getCookie } from '../../../utils/functions/Others';

const Signup = () => {

  ReactPixel.pageView();

  const barberD = JSON.parse(localStorage.getItem("barber"));
  
  const [barber, setBarber] = useState({

    id: "",
    userType: "barber",
    firstAccess: "true",
    masterName: barberD ? barberD.masterName : "",
    whatsapp: barberD ? barberD.whatsapp : "",
    email: barberD ? barberD.email : "",
    password: barberD ? barberD.password : "",
    confirmPassword: barberD ? barberD.confirmPassword : "",
    createdAt: "",
    terms: barberD ? barberD.terms : false,
    confirmationCode: "",
    token: barberD ? barberD.token : "",
    step: barberD ? barberD.step : 1
  });

  const [state, setState] = useState("empty");

  const navigate = useNavigate();

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifySuccess = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const backStep = () => {

    setBarber({...barber, step: 1});
    localStorage.setItem("barber", JSON.stringify({

      ...barber,
      step: 1
    }));
  }

  const handleInput = (e) => {

    if(e.target.name === "terms"){

      setBarber({...barber, [e.target.name]: !barber.terms});
      localStorage.setItem("barber", JSON.stringify({

        ...barber,
        [e.target.name] : !barber.terms
      }));
      
    }else{

      setBarber({...barber, [e.target.name]: e.target.value});
      localStorage.setItem("barber", JSON.stringify({

        ...barber,
        [e.target.name] : e.target.value
      }));
    }
  };

  const sendConfirmationCode = (e) => {

    if(e != undefined){

      e.preventDefault();

    }

    setState("sending");

    axios.post("/api/email/send-confirmation-code-email", {

      masterName: barber.masterName,
      email: barber.email
    })
    .then(res=>{

      if(res.data.success === true){

        setTimeout(()=>{

          setState("sended");
          setBarber({...barber, token: res.data.data.token, step: 2});
          localStorage.setItem("barber", JSON.stringify({

            ...barber,
            token : res.data.data.token,
            step: 2
          }));
          setTimeout(()=>{
            setState("empty");
          }, 1500);

        }, 1500);
      }
    })
    .catch(err=>{

      if(err.response.status === 500){

        notifyError("Erro no servidor, tente novamente.");
      }
    });
  }

  const register = (e) => {

    setState("loading")

    const id = uuidv4();
    const subscriptionId = uuidv4();
    const createdAt = moment().utc();
    const endDate = moment().utc().add(14, 'days');

    axios.post("/api/signup/verify-email-confirmation-code", {

      confirmationCode: barber.confirmationCode,
      token: barber.token
    })
    .then(res=>{

      if(res.data.success === true){

      axios.post("/api/signup", {
        
        id: id,
        userType: barber.userType,
        firstAccess: barber.firstAccess,
        masterName: barber.masterName,
        whatsapp: barber.whatsapp,
        email: barber.email,
        password: barber.password,
        createdAt: createdAt,
        userAgent: navigator.userAgent
      })
      .then(async res=>{

        if(res.data.success){

          axios.post("/api/first-register/business-rules", {

            barberId: id,
            minTimeService: null,
            initialWorkTime: null,
            endWorkTime: null,
            initialLunchTime: null,
            endLunchTime: null,
            intervalTime: null,
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
            createdAt: createdAt
          })
          .then(async resBusinessRules=>{

            if(resBusinessRules.data.success){

              await axios.post(`/api/signup/subscription/${subscriptionId}`, {

                barberId: id,
                email: barber.email,
                masterName: barber.masterName,
                planId: "free-trial",
                status: "free-trial-active",
                startDate: createdAt,
                endDate: endDate,
                createdAt: createdAt
              }).then(async res=>{

                if(res.data.success){

                  axios.post("/api/pixel/events/complete-registration", {

                    barberId: id,
                    email: barber.email,
                    phoneNumber: barber.whatsapp,
                    masterName: barber.masterName,
                    userAgent: navigator.userAgent,
                    source: window.location.href,
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp")
                  }).then(res => {
        
                    if(res.data.success){
        
                    }
        
        
                  }).catch(err => {
        
        
                  });

                  await axios.post("/api/signup/send-welcome-email", {email: barber.email, masterName: barber.masterName})
                  .then(resWelcomeEmail=>{

                    if(resWelcomeEmail.data.success){

                      setTimeout(()=>{

                        setState("success");
                        setTimeout(()=>{

                          setState("empty");
                          setBarber({
                            id: "",
                            userType: "barber",
                            firstAccess: "true",
                            masterName: "",
                            whatsapp: "",
                            email: "",
                            password: "",
                            confirmPassword: "",
                            createdAt: "",
                            terms: false,
                            confirmationCode: "",
                            token: "",
                            step: 1
                          });
                          localStorage.removeItem("barber");
                          navigate("/acessar-conta");
                        }, 1500);
                      }, 1500);
                    }
                  }).catch(err=>{

                    setState("empty");

                    if(err.response.status === 500){

                      notifyError("Erro no servidor, tente novamente.");
                    }else{
    
                      notifyError("Erro no servidor, tente novamente.");
                    }
                  }); 
                }
              }).catch(err=>{

                //SUBSCRIPTIONS

                if(err.response.status === 500){

                  notifyError("Erro no servidor, tente novamente.");
                }else{

                  notifyError("Erro no servidor, tente novamente.");
                }

              });
            }
          }).catch(err=>{

            //BUSINESS RULES

            if(err.response.status === 500){

              notifyError("Erro no servidor, tente novamente.");
            }

          });
        }
      })
      .catch(err=>{

        //SIGNUP
        setTimeout(()=>{

          setState("empty");

          if(err.response.status === 500){

            notifyError("Erro no servidor, tente novamente.");

          }else if(err.response.status === 409){

            notifyError("Já existe um cadastro com esse e-mail.");

            setTimeout(()=>{

              setBarber({...barber, step: 1});
              localStorage.setItem("barber", JSON.stringify({

                ...barber,
                step : 1
              }));
            }, 1500);
          }
        }, 1500);
      })
    }
    }).catch(err=>{

      //TOKEN VERIFICATION

      if(err.status === 400){

        notifyError("Código de confirmação inválido!");
      }

    });

  };

  const validateFields = (name) =>{

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

    if(name === "masterName"){

      if(barber.masterName != "" && barber.masterName.length >= 3 && barber.masterName.length <= 50){

        return false;
      }else{

        return true;
      }
    } else if(name == "whatsapp"){


        let str1 = barber.whatsapp.replace("(", "").replace(")", "").replace("-", "").replace("_", "").replace(" ", "");
        if(barber.whatsapp != "" && str1.length == 11){
          return false;
        }else{
    
          return true;
        }
      } else if(name == "email"){

        if(barber.email != "" && emailRegex.test(barber.email)){

          return false;
        }else{

          return true;
        }
      } else if(name === "password"){

        if(barber.password != "" && passRegex.test(barber.password)){

          return false;
        }else{

          return true;
        }
      }else if(name === "confirmPassword"){

        if(barber.confirmPassword != "" && barber.password === barber.confirmPassword){

          return false;
        }else{

          return true;
        }
      }else if(name === "terms") {

        if(barber.terms === true){

          return false;
        }else{

          return true;
        }
      }else if(name === "confirmationCode"){

        if(barber.confirmationCode.length == 6){

          return false;
        }else{

          return true;
        }
      }
  
  }
  
  return(

    <div className='signup-barber'>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <ToastContainer />
        {

          state === "loading"
            ? <LoadingPopup state={"loading"} message={"Criando conta..."}/>
            : state === "success"
              ? <LoadingPopup state={"success"} message={"Conta criada com sucesso!"}/>
              : state === "sending"
                ? <LoadingPopup state={"loading"} message={"Enviando..."}/>
                : state === "sended"
                  ? <LoadingPopup state={"success"} message={"Novo código enviado!"}/>
                  : null
        }
      {/*<div className='theme-switch'><ThemeSwitch/></div>*/}
      <div className='left'>
        {

          barber.step === 1
            ?<div className='box'>

              <img className='logo' src={logoGray}></img>
              <h1>Crie uma conta e teste grátis</h1>

              <form onSubmit={(e)=>{sendConfirmationCode(e)}} className='inputs'>

                <AuthInput
                  data={{
                    error: "O nome deve ter entre 3 e 50 caracteres.",
                    hasError: barber.masterName != null ? validateFields("masterName").toString() : null,
                    iconName: "person",
                    label: "Nome do responsável",
                    placeholder: "Nome do responsável",
                    name: "masterName",
                    type: "text",
                    required: true,
                    value: barber.masterName,
                    handleInput: handleInput
                  }}
                  iconStyle={{

                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                <MaskedInput
                  data={{
                    error: "Número inválido!",
                    hasError: barber.whatsapp != null ? validateFields("whatsapp").toString() : null,
                    iconName: "phone",
                    aditionalClass: "",
                    label: "Whatsapp",
                    placeholder: "whatsapp",
                    name: "whatsapp",
                    type: "text",
                    mask: "(99) 99999-9999",
                    required: true,
                    value: barber.whatsapp,
                    handleInput: handleInput
                  }}
                  iconStyle={{

                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                  fetchAddress={null}
                />
                <AuthInput
                  data={{
                    error: "E-mail inválido!",
                    hasError: barber.email != null ? validateFields("email").toString() : null,
                    iconName: "envelope",
                    label: "E-mail",
                    placeholder: "E-mail",
                    name: "email",
                    type: "email",
                    required: true,
                    value: barber.email,
                    handleInput: handleInput
                  }}
                  iconStyle={{

                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                <AuthInput
                  data={{
                    error: "A senha deve conter no mínimo 8 caracters, pelo menos 1 letra, 1 número e 1 carctere especial.",
                    hasError: barber.password != null ? validateFields("password").toString() : null,
                    iconName: "lock",
                    label: "Senha",
                    placeholder: "Senha",
                    name: "password",
                    type: "password",
                    required: true,
                    value: barber.password,
                    handleInput: handleInput
                  }}
                  iconStyle={{

                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                <AuthInput
                  data={{
                    error: "As senhas digitadas não conferem.",
                    hasError: barber.confirmPassword != null ? validateFields("confirmPassword").toString() : null,
                    iconName: "lock",
                    label: "Confirmar senha",
                    placeholder: "Confirmar senha",
                    name: "confirmPassword",
                    type: "password",
                    required: true,
                    value: barber.confirmPassword,
                    handleInput: handleInput
                  }}
                  iconStyle={{

                    color: Colors.PRIMARY_COLOR_LIGHT,
                    width: "20px",
                    height: "20px"
                  }}
                />
                <div className='agreement-terms'>
                  <input className={barber.terms === true ? "checked" : "unchecked"} checked={barber.terms} type='checkbox' name="terms" onClick={(e)=>{handleInput(e)}} ></input>
                  <span className='text'>Concordo que li e estou de acordo com os <a href='https://srbarberoficial.com.br/termos-de-uso/' className='link' target='_blank'>Termos de Uso</a>.</span>
                </div>
                {
                  !validateFields("masterName") &&
                  !validateFields("whatsapp") &&
                  !validateFields("email") &&
                  !validateFields("password") &&
                  !validateFields("confirmPassword") &&
                  !validateFields("terms")
                  ? <button type="submit">Confirmar e-mail e continuar</button>
                
                  : <button disabled={true} type="submit">Confirmar e-mail e continuar
          </button>

                }
              </form>
              
              <span className='have-account'>Já tem conta? <span className='link' onClick={()=>{navigate("/acessar-conta")}}>Acesse aqui.</span></span>
            </div>
          : <div className='box-email-confirmation'>
              <h2>Confirme seu e-mail</h2>
              <span>Enviamos um código de verificação de 6 dígitos para seu e-mail, digite o código para continuar o cadastro.</span>
              <ReactCodeInput type='text' fields={6} inputStyle={{}} onChange={(e)=>{handleInput({target:{value: e, name: "confirmationCode"}})}} />
              <span className='resend-code' onClick={()=>{sendConfirmationCode()}}>Reenviar código</span>
              {
                  !validateFields("masterName") &&
                  !validateFields("whatsapp") &&
                  !validateFields("email") &&
                  !validateFields("password") &&
                  !validateFields("confirmPassword") &&
                  !validateFields("terms") &&
                  !validateFields("confirmationCode") 
                  ? <button type="button" onClick={(e)=>{register(e)}}>Confirmar e criar conta</button>
                
                  : <button disabled={true} >Verificar e criar conta</button>

                }
                <span className='back-btn' onClick={()=>{backStep()}}><FaArrowLeft className='icon'/>Voltar </span>
          </div>
      }
      </div>
      <div className='right' style={{backgroundImage: `url(${background})`, objectFit: "cover"}}>
        <img className='bg' src={barber2}></img>
        {/*<img src={signinbg}></img>*/}
      </div>
    </div>
  )
};

export default Signup;
