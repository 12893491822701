import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SidebarProvider } from './context/sidebarContext';
import { initFacebookPixel } from './FacebookPixel';

initFacebookPixel();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <SidebarProvider>

      <App />
    </SidebarProvider>
);
