import axios from 'axios';
import { ArcElement, BarController, BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LineController, LineElement, LinearScale, PointElement, Tooltip } from "chart.js";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Doughnut, Line } from 'react-chartjs-2';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilter } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { DatePickerCustom } from '../../../components/commom/form-input/FormInput';
import Profile from '../../../components/commom/profile/Profile';
import { useAuth } from '../../../context/AuthContext';
import { grossRevenue, totalComissions, totalComissionsForEachProfessional } from '../../../utils/functions/CalcFunctions';
import { calcProfessionalsServicesForBarChart, calcRevenueForLineChart, calcTotalOfServicesDone } from '../../../utils/functions/ChartsFunctions';
import { formatIntoCurrency } from '../../../utils/functions/Formatters';
import loadingBlack from "../../../utils/images/loading_black.gif";
import ComissionReportItem from './ComissionReportItem';
import "./Dashboard.css";
import { ToastContainer, toast } from 'react-toastify';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineController, LineElement, Filler, BarElement, BarController);


const Dashboard = () => {

  const { user } = useAuth();
  const lineChartFilterPeriod = JSON.parse(sessionStorage.getItem("dashboardPeriod"));

  const [schedules, setSchedules] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [services, setServices] = useState([]);
  const [startDate, setStartDate] = useState(lineChartFilterPeriod != null ? moment(lineChartFilterPeriod.startDate).utc() : moment().utc());
  const [endDate, setEndDate] = useState(lineChartFilterPeriod != null ? moment(lineChartFilterPeriod.endDate).utc() :moment().utc());
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [filtersTrigger, setFiltersTrigger] = useState(false);
  const [lineChartData, setLineChartData] = useState([]);
  const [professionalsBarChartData, setProfessionalsBarChartData] = useState([]);
  const [totalServicesBarChartData, setTotalServicesBarChartData] = useState([]);
  const [totalGrossRevenue, setTotalGrossRevenue] = useState("0,00");
  const [totalComissionsS, setTotalComissionsS] = useState("0,00");
  const [professionalsComissionsList, setProfessionalsComissionsList] = useState([]);
  const [schedulesStatus, setSchedulesStatus] = useState({

    pending: 0,
    canceled: 0,
    done: 0,
  });

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const notifyAlert = (message) => toast.info(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  
  const filter = () => {

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    setShowDateFilter(!showDateFilter);
    setFiltersTrigger(!filtersTrigger);

    sessionStorage.setItem("dashboardPeriod", JSON.stringify({startDate: startDate, endDate: endDate}));

    axios.get("/api/schedules", {
      params:{
        barberId: user.id,
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD")
      }
    })
    .then(res=>{

      if(res.data.success === true){

        if(res.data.data.length >= 1){

          setLineChartData(calcRevenueForLineChart(res.data.data, startDate, endDate));
          setProfessionalsBarChartData(calcProfessionalsServicesForBarChart(res.data.data, professionals));
          setTotalServicesBarChartData(calcTotalOfServicesDone(res.data.data, services));
          setTotalGrossRevenue(grossRevenue(res.data.data));
          setProfessionalsComissionsList(totalComissionsForEachProfessional(professionals, res.data.data));
          setSchedulesStatus(calcSchedulesByStatus(res.data.data));
          setTotalComissionsS(totalComissions(professionals, res.data.data))
        }
      }
    })
    .catch(err=>{

      if(err.response.status === 404){

        notifyAlert(err.response.data.message);
      }else if(err.response.status === 500){

        notifyError(err.response.data.message);
      }else{

      }
    });
  }

  const calcSchedulesByStatus = (schedules) => {

    let pending = 0;
    let canceled = 0;
    let done = 0;

    schedules.map(schedule=>{

      if(schedule.status === "pending"){

        pending += 1;
      }else if(schedule.status === "canceled"){

        canceled += 1;
      }else if(schedule.status === "done"){

        done += 1;
      }
    });

    return {

      pending,
      canceled,
      done
    };
  }

  useEffect(()=>{

    setLineChartData(calcRevenueForLineChart(schedules, startDate, endDate));
    setProfessionalsBarChartData(calcProfessionalsServicesForBarChart(schedules, professionals));
    setTotalServicesBarChartData(calcTotalOfServicesDone(schedules, services));
    setTotalGrossRevenue(grossRevenue(schedules));
    setProfessionalsComissionsList(totalComissionsForEachProfessional(professionals, schedules));
    setTotalComissionsS(totalComissions(professionals, schedules))
    setSchedulesStatus(calcSchedulesByStatus(schedules));

  }, [professionals, schedules, services]);

  useEffect(()=>{

    const fetchData = () => {

      const start = moment.tz(startDate, userTimezone).startOf('day');
      const end = moment.tz(endDate, userTimezone).endOf('day');

      axios.get("/api/schedules", {
        params:{
          barberId: user.id,
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD")
        }
      })
      .then(res=>{

        if(res.data.success === true){

          setSchedules(res.data.data);
        }
      })
      .catch(err=>{

        if(err.response.status === 404){

        }else if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else{

        }
      });

      axios.get(`/api/professionals-by-barber/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          let pArray = res.data.data;

          setProfessionals(pArray);
        }
      })
      .catch(err=>{

        if(err.response.status === 404){

        }else if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else{

        }

      });

      axios.get(`/api/services/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          setServices(res.data.data);
        }
      })
      .catch(err=>{

        if(err.response.status === 404){

        }else if(err.response.status === 500){

          notifyError(err.response.data.message);
        }else{
          
        }
      });
    }

    if(user){

      fetchData();
    }

  }, []);

  if(
    professionals.length === 0 &&
    services.length === 0
  ){

    return <div style={{height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", boxSizing: "border-box", gap: "10px"}}>
            <img style={{width: "50px", height: "50px", fontSize: "16px", fontWeight: "700"}} src={loadingBlack}/>
            <span style={{fontSize: "16px", fontWeight: "600"}}>Carregando</span>
          </div>
  }

  return (

    <>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      <ToastContainer />
      {
        filtersTrigger
          ? <div className="filters-mobile-container">
              <div className="top">
                <IoMdClose onClick={()=>{setFiltersTrigger(!filtersTrigger)}} />
              </div>
              <div className="date">
                <span>Data:</span>
                <div className="row">
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
                </div>
              </div>
              
              <button className="filter" onClick={()=>{filter()}}>Filtrar</button>
          </div>
          : null
      }

      <div className="dashboard-container">
        
          <div className="top">
            <div className="filters-desktop">
              <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
              <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
              <button className='search' onClick={()=>{filter()}}>Pesquisar</button>
            </div>
            
            <button className="filters-mobile" onClick={()=>{setFiltersTrigger(!filtersTrigger)}}>
              <FaFilter />
            </button>
            <Profile/>
          </div>

          <div className='dashboard'>

            <div className="total-gross-renevue-mobile">
              <span>Visão geral</span>
              <span className='gross-revenue'>{formatIntoCurrency(parseFloat(totalGrossRevenue))}</span>
            </div>

            <div className="left">
                <div className="row-1">
                    <div className="card done">
                        <div className="info">
                            <h4>Finalizados</h4>
                            <span>{schedulesStatus.done}</span>
                        </div>
                    </div>
                    <div className="card pending">
                        <div className="info">
                            <h4>Pendentes</h4>
                            <span>{schedulesStatus.pending}</span>
                        </div>
                    </div>
                    <div className="card canceled">
                        <div className="info">
                            <h4>Cancelados</h4>
                            <span>{schedulesStatus.canceled}</span>
                        </div>
                    </div>
                </div>
                <div className="row-2">
                    <div className="top">
                        <h3>Faturamento financeiro bruto</h3>
                        <span className='subtitle-d'>Faturamento financeiro bruto por dia, por período</span>
                    </div>
                    <div className="chart">

                      {

                        lineChartData.every(item => item.sum == 0)
                        ? (

                            <span style={{alignSelf: "center", justifySelf: "center"}}>Sem dados!</span>
                          )
                        : (
                          <Line
                            data={{
                              labels: lineChartData.map(item=>{

                                return moment(item.range.date1).format("DD/MM/YYYY") + " - " + moment(item.range.date2).format("DD/MM/YYYY")
                              }),
                              datasets: [{
                                fill: true,
                                label: 'Receita',
                                data: lineChartData.map(item=>{

                                  return item.sum
                                }),
                                borderColor: '#EE3F2D',
                                backgroundColor: ({chart: {ctx}}) => {
                                  const bg = ctx.createLinearGradient(0, 50, 0, 350);
                                  bg.addColorStop(0, "rgba(238, 63, 45, 0.8)");
                                  bg.addColorStop(0.5, "rgba(238, 63, 45, 0.5)");
                                  bg.addColorStop(1, "rgba(238, 63, 45, 0)");
                                  return bg;
                                }
                              }]
                            }}
                            options={{
                              responsive: true,
                              maintainAspectRatio: true,
                              scales: {
                                y: {
                                  
                                  ticks: {
                                    callback: function(value) {
                                      return formatIntoCurrency(value);
                                    }
                                  }
                                },
                                x: {

                                  display: false
                                }
                              },
                              plugins: {
                                tooltip: {
                                  callbacks: {
                                    label: function(context) {
                                      let label = context.dataset.label || '';
                                      if (label) {
                                        label += ': ';
                                      }
                                      label += formatIntoCurrency(parseFloat(context.raw));
                                      return label;
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        )
                      }
                        
                    </div>
                    <div className="mobile">
                      <h1>{formatIntoCurrency(parseFloat(totalGrossRevenue))}</h1>
                    </div>
                </div>
                <div className="row-3">
                    <div className="box">
                        <div className="top">
                            <h3>Desempenho dos profissionais</h3>
                            <span>Quantidade total de serviços executados por cada profissional no período</span>
                        </div>
                        <div className="chart">

                          {

                            professionalsBarChartData.every(item => item.sum == 0)
                            ? (
                                <span style={{alignSelf: "center", justifySelf: "center"}}>Sem dados!</span>
                              )
                            : (
                                <Doughnut
                                  data={{
                                    labels: professionalsBarChartData.map(item=>{


                                      return item.professional.name;
                                    }),
                                    datasets: [{
                                      label: 'Quantidade',
                                      data: professionalsBarChartData.map(item=>{

                                        return item.sum
                                      }),
                                      borderColor: 'transparent',
                                      backgroundColor: [

                                        "#EE3F2D",
                                        "#B8BDE3",
                                        "#DCDCDC",
                                        "#101418",
                                      ]
                                    }]
                                  }}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    plugins: {
                                      tooltip: {
                                        callbacks: {
                                          label: function(context) {
                                            let label = context.dataset.label || '';
                                            if (label) {
                                              label += ': ';
                                            }
                                            label += context.raw;
                                            return label;
                                          }
                                        }
                                      },
                                      legend: {

                                        position: 'bottom',
                                        align: 'start',
                                        labels: {

                                          pointStyle: 'circle',
                                          usePointStyle: true
                                        }
                                      },
                                      
                                    }
                                  }}
                                />
                              )

                          }
                            
                        </div>
                    </div>
                    <div className="box">
                        <div className="top">
                            <h3>Serviços executados</h3>
                            <span>Quantidade total de cada tipo de serviço executado no período</span>
                        </div>
                        <div className="chart">

                          {

                            totalServicesBarChartData.every(item => item.sum == 0 )
                            ? (
                                <span style={{alignSelf: "center", justifySelf: "center"}}>Sem dados!</span>
                              )
                            : (
                                <Doughnut
                                  data={{
                                    labels: totalServicesBarChartData.map(item=>{


                                      return item.service.name;
                                    }),
                                    datasets: [{
                                      label: 'Quantidade',
                                      data: totalServicesBarChartData.map(item=>{

                                        return item.sum;
                                      }),
                                      borderColor: 'transparent',
                                      backgroundColor: [

                                        "#EE3F2D",
                                        "#B8BDE3",
                                        "#DCDCDC",
                                        "#101418",
                                      ]
                                    }]
                                  }}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    plugins: {
                                      tooltip: {
                                        callbacks: {
                                          label: function(context) {
                                            let label = context.dataset.label || '';
                                            if (label) {
                                              label += ': ';
                                            }
                                            label += context.raw;
                                            return label;
                                          }
                                        }
                                      },
                                      legend: {

                                        position: 'bottom',
                                        align: 'start',
                                        labels: {

                                          pointStyle: 'circle',
                                          usePointStyle: true
                                        }
                                      }
                                    }
                                  }}
                                />
                              )
                          }
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="right">
                <div className="row-1">
                    <div className="card total-gross-revenue">
                        <h1>{formatIntoCurrency(parseFloat(totalGrossRevenue))}</h1>
                        <span>Faturamento bruto total no período</span>
                    </div>
                    <div className="card total-comissions">
                      <h3>Total de comissões no período</h3>
                        <h1>{formatIntoCurrency(parseFloat(totalComissionsS))}</h1>
                        <span>Total de comissões no período</span>
                    </div>
                </div>
                <div className="row-2">
                    <div className="top">
                        <h3>Comissões a serem pagas</h3>  
                    </div>
                    <div className='list'>

                      {

                        professionalsComissionsList.map((professional, index)=>{

                          return <ComissionReportItem
                          key={index}
                          comissionReport={{value:  formatIntoCurrency(professional.totalComission), professionalName: professional.professional.name, professionalImage: professional.professional.image,  barberId: user.id}}
                      />
                        })
                      }
                            
                    </div>
                </div>
                <div className="row-3">
                </div>

            </div>
          </div>
      </div>

    </>
  )

}

export default Dashboard;