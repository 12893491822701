
import { useContext, useEffect, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { GeneralBarsContext } from "../../../context/generalBarsContext";
import { SidebarContext } from "../../../context/sidebarContext";
import "./Sidebar.css";
import logoWhite from "../../../utils/images/logo_white.svg";
import "../../../utils/Main.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useAuth } from "../../../context/AuthContext";

const Sidebar  = (props) => {

    const { user } = useAuth();

    const openedSubMenusS = JSON.parse(sessionStorage.getItem("openedSubMenus"))

    const [openedSubmenus, setOpenedSubmenus] = useState(openedSubMenusS != null ? openedSubMenusS : {});
    const [sidebarClass, setSidebarClass] = useState("");
    const {isSidebarOpen} = useContext(SidebarContext);
    const {addServiceBarState, setAddServiceBarState} = useContext(GeneralBarsContext);

    const toggleSubmenu = (menuIndex) => {

        setOpenedSubmenus({
            ...openedSubmenus,
            [menuIndex]: !openedSubmenus[menuIndex]
          });

    }


    const returnMenu = (menu, index) => {

        if(menu.submenus){

            return <div className="menu-item-container" key={menu.id}>
                        <div className="menu-item" >
                            <button
                                key={menu.id}
                                className=""
                                onClick={()=>{toggleSubmenu(index)}}>
                                
                                    {menu.icon}
                                    <span className={`nav-link-title`}>{menu.title}</span>
                                {

                                    menu.submenus
                                    ? <button className="toggle-btn" onClick={()=>{toggleSubmenu(index)}}>{

                                        openedSubmenus[index] === false || !openedSubmenus[index]
                                        ? <IoIosArrowDown />
                                        : <IoIosArrowUp />
                                    }</button>
                                    : null
                                }

                            </button>
                        </div>
                    
                    {
                        openedSubmenus[index] === true
                        ? menu.submenus.map(submenu=>{

                            return <div className="submenu-item" key={submenu.id}>
                                    <NavLink 
                                        to={{pathname: submenu.path}}
                                        key={submenu.id}
                                        className="nav-link" 
                                        onClick={()=>{callFunctions(menu)}}>
                                        
                                        {submenu.icon}
                                        <span className={`nav-link-title`}>{submenu.title}</span>                      
                                    </NavLink>
                                </div>
                        })
                        : null
                        
                    }

                </div>
        }else{

            return <div className="menu-item" key={menu.id}>
                        <NavLink 
                            state={{from: "test"}}
                            to={{pathname: menu.path}}
                            key={menu.id}
                            className="nav-link" 
                            onClick={()=>{callFunctions(menu)}}>
                            
                            {menu.icon}
                            <span className={`nav-link-title`}>{menu.title}</span>

                        </NavLink>
                    </div>
        }
    }
    useEffect(()=>{

        sessionStorage.setItem("openedSubMenus", JSON.stringify(openedSubmenus));

    }, [openedSubmenus]);

    const callFunctions = (data) => {

        props.setCurrentRoute(data.title);
        props.setSideBarState(!props.sidebarState);
    }

    return(

        <div className={`sidebar ${sidebarClass}`}>
            
            <img className='logo' src={logoWhite}></img>

            <nav className="navigation">

                <ul className="nav-list">
                    {

                        props.navigationLinks.map((navigationLink, index)=>{


                                return returnMenu(navigationLink, index)
                            
                        })
                    }
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar;