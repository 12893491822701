import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { AuthInput } from '../../../components/commom/form-input/FormInput';
import LoadingPopup from '../../../components/commom/loading-pop-up/LoadingPopUp';
import RedefinePassPopup from '../../../components/commom/redefine-pass-popup/RedefinePassPopup';
import { useAuth } from '../../../context/AuthContext';
import { Colors } from '../../../utils/Colors';
import barber2 from "../../../utils/images/background-signin-no-bg.png";
import background from "../../../utils/images/bg-sn.png";
import CheckIcon from '../../../utils/images/check_black.gif';
import logoGray from "../../../utils/images/logo_gray.svg";
import "./Signin.css";

const Signin = () => {

  const { updateData } = useAuth();

  const [values, setValues] = useState({

    email: "",
    password: "",
    maintainConnected: false
  });

  const theme = localStorage.getItem("theme");

  const token = localStorage.getItem("token");

  const [state, setState] = useState("empty");
  const [loginState, setLoginState] = useState("empty");

  const [recoverPassTrigger, setRecoverPassTrigger] = useState(false);

  const navigate = useNavigate();

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleInput = (e) =>{

    e.preventDefault();

    if(e.target.name == "maintainConnected"){

    setValues({...values, [e.target.name] : !values.maintainConnected})

    }else{

      setValues({...values, [e.target.name] : [e.target.value]})

    }

  }

  const login = (e) => {

    e.preventDefault();

    setLoginState("loading");

    axios.post(`/api/signin/${values.email}`, values)
    .then(res=>{


        if(res.data.success){

          setTimeout(()=>{

            setLoginState("success");

            setTimeout(()=>{

              localStorage.setItem("token", res.data.token);

              navigate("/dashboard", {replace: true});
              setValues({
      
                email: "",
                password: "",
                maintainConnected: false
              });
              
            }, 1500);
          }, 1500);
        }

    })
    .catch(err=>{

      setTimeout(()=>{

        setLoginState("empty");

        if(err.response.status === 401){

          notifyError("Senha incorreta, tente novamente.")
          
        }else if(err.response.status === 404){

          notifyError("Usuário não encontrado.");
  
        }else if(err.response.status === 500){

          notifyError("Erro no servidor, tente novamente.");

        }else{

          notifyError("Erro ao acessar conta, tente novamente.")
        }
      }, 1500);
      
    })
    
  }

  const recoverPass = (e, recEmail) => {

    e.preventDefault();

    if(recEmail !== ""){

      setState("loading");
      axios.post("/api/recover-password", {recEmail}).then(res=>{

        if(res.data.success === true){

          setState("success");
        }
      }).catch(err=>{

        if(err.response.status === 500){

          notifyError("Erro no servidor, tente novamente.");
        }else if(err.response.status === 404){

          notifyError("Usuário não encontrado.");
        }else{

          notifyError("Erro no servidor, tente novamente.");

        }

        setState("error");
      });
    }

  }

  const redirect = () => {

    setState("empty");
    setRecoverPassTrigger(false);
  }

  useEffect(() => {
    if (token) {

        axios.get('/api/token-verification', {

            headers: { 'x-access-token': token, 'x-user-type': "barber" }

        }).then(res => {

            updateData(res.data);
            navigate("/dashboard", {replace: true});

        }).catch(err => {
          
            localStorage.removeItem("token");

        }).finally(() => {
        });
    } else {

    }
}, []);

  return(

    <div className={`signin-barber ${theme}`}>
      {/*<div className='theme-switch'><ThemeSwitch/></div>*/}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {
        state === "success"
        ? 
          <div className='redefine-pass-popup'>
            <div className='box'>
              <div className='title'><img src={CheckIcon}></img><h3>E-mail enviado com sucesso</h3></div>
              <span>Acesse seu e-mail e clique no link de recuperação para criar uma nova senha!</span>
              <button onClick={()=>{redirect()}}>Ok</button>
            </div>
          </div>
        : state === "error"
          ? <LoadingPopup state={"error"} message={"Erro ao enviar e-mail, tente novamente."}/>
          : state === "loading"
            ? <LoadingPopup state={"loading"} message={"Enviando e-mail de recuperação, aguarde..."}/>
            : state === "empty"
                ? <></>
                : <></>
      }
      {

        loginState === "loading"
          ? <LoadingPopup state={"loading"} message={"Entrando..."}/>
          : loginState === "success"
            ? <LoadingPopup state={"success"} message={"Sucesso!"}/>
            : null
      }

      { 
        !recoverPassTrigger ?
          <>
            <div className='left' style={{backgroundImage: `url(${background})`, objectFit: "cover"}}>
              <img className='bg' src={barber2}></img>
            </div>
            <div className='right'>
              <div className='box'>

                <img className='logo' src={logoGray}></img>
                <h1>Acessar conta</h1>

                <form onSubmit={(e)=>{login(e)}} className='inputs'>
                  <AuthInput
                    data={{
                      iconName: "envelope",
                      label: "E-mail",
                      placeholder: "E-mail",
                      name: "email",
                      type: "email",
                      value: values.email,
                      required: true,
                      autoComplete: "email",
                      handleInput: handleInput
                    }}
                    iconStyle={{

                      color: Colors.PRIMARY_COLOR_LIGHT,
                      width: "20px",
                      height: "20px"
                    }}
                  />

                  <AuthInput
                    data={{
                      iconName: "lock",
                      label: "Senha",
                      placeholder: "Senha",
                      name: "password",
                      type: "password",
                      value: values.password,
                      required: true,
                      handleInput: handleInput
                    }}
                    iconStyle={{

                      color: Colors.PRIMARY_COLOR_LIGHT,
                      width: "20px",
                      height: "20px"
                    }}
                  />

                  <div className="maintain-connected">
                    <input className={values.maintainConnected === true ? "checked" : "unchecked"} checked={values.maintainConnected} type='checkbox' name="maintainConnected" onClick={(e)=>{handleInput(e)}} ></input>
                    <span>Manter conectado por 7 dias</span>
                  </div>

                  <button type='submit'>Acessar conta</button>

                </form>
                <span className='redefine-pass'>Esqueceu sua senha? <div className='link' onClick={()=>{setRecoverPassTrigger(true)}}>Clique aqui.</div></span>
                <span className='no-account'>Não tem conta?<span className='link' onClick={()=>{navigate("/criar-conta")}}>Cadastre-se aqui.</span></span>
              </div>
            </div>
          </>
        : <RedefinePassPopup setRecoverPassTrigger={setRecoverPassTrigger} active={recoverPassTrigger} recoverPass={recoverPass}/>
      }
    </div>
  )
}

export default Signin;