import { useEffect, useState } from "react";
import "./ProfessionalsReports.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerCustom } from "../../../../components/commom/form-input/FormInput";
import { FaFilter, FaUser } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { calcPerformance, totalComissionsForEachProfessional, totalServicesForEachProfessional } from "../../../../utils/functions/CalcFunctions";
import ProfessionalItem from "../finances/ProfessionalItem";
import { formatIntoCurrency } from "../../../../utils/functions/Formatters";
import { Doughnut } from "react-chartjs-2";
import { useAuth } from "../../../../context/AuthContext";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';

const ProfessionalsReports = () => {

  const { user } = useAuth();

  const professionalFilterDate = JSON.parse(sessionStorage.getItem("professionalsReportsPeriod"));

  const [showDateFilter, setShowDateFilter] = useState(false);
  const [startDate, setStartDate] = useState(professionalFilterDate != null ? moment(professionalFilterDate.startDate).utc() : moment().utc());
  const [endDate, setEndDate] = useState(professionalFilterDate != null ? moment(professionalFilterDate.endDate).utc() :moment().utc());
  const [filtersTrigger, setFiltersTrigger] = useState(false);

  const [professionals, setProfessionals] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [services, setServices] = useState([]);

  const [totalComissionForEachProfessional, setTotalComissionForEachProfessional] = useState([]);
  const [totalServicesForEachProfessionalS, setTotalServicesForEachProfessionalS] = useState([]);
  const [performance, setPerformance] = useState({

    heigherPerformance: null,
    lowerPerformance: null
  });

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  useEffect(()=>{

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    const fetchData = () => {

      axios.get("/api/schedules", {
        params:{
          barberId: user.id,
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD")
        }
      })
      .then(res=>{

        if(res.data.success === true){

          setSchedules(res.data.data);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });

      axios.get(`/api/professionals-by-barber/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          let pArray = res.data.data;

          setProfessionals(pArray);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){

            notifyError(err.response.data.message);
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });

      axios.get(`/api/services/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          setServices(res.data.data);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
            
            notifyError(err.response.data.message);
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });
    }

    if(user){

      fetchData();
    }

  }, []);

  useEffect(()=>{

    setTotalComissionForEachProfessional(totalComissionsForEachProfessional(professionals, schedules));
    setTotalServicesForEachProfessionalS(totalServicesForEachProfessional(professionals, schedules));
    setPerformance(calcPerformance(totalServicesForEachProfessional(professionals, schedules)));

    

  }, [professionals, services, schedules]);

  const filter = () => {

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    setShowDateFilter(!showDateFilter);

    sessionStorage.setItem("professionalsReportsPeriod", JSON.stringify({startDate: startDate, endDate: endDate}))

      axios.get("/api/schedules", {
        params:{
          barberId: user.id,
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD")
        }
      })
      .then(res=>{
        
        if(res.data.success === true){

          setSchedules(res.data.data);
          setTotalComissionForEachProfessional(totalComissionsForEachProfessional(professionals, res.data.data));
          setTotalServicesForEachProfessionalS(totalServicesForEachProfessional(professionals, res.data.data));
          setPerformance(calcPerformance(totalServicesForEachProfessional(professionals, res.data.data)));

        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });
        
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {
        filtersTrigger
          ? <div className="filters-mobile-container">
              <div className="top">
                <IoMdClose onClick={()=>{setFiltersTrigger(!filtersTrigger)}} />
              </div>
              <div className="date">
                <span>Data:</span>
                <div className="row">
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
                </div>
              </div>
              
              <button className="filter" onClick={()=>{filter()}}>Filtrar</button>
          </div>
          : null
      }
      <div className="professionals-reports">
        <div className="top">
          <div className="filters-desktop">
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
            <button className="search" onClick={()=>{filter()}}>Pesquisar</button>
          </div>
          <button className="filters-mobile" onClick={()=>{setFiltersTrigger(!filtersTrigger)}}>
            <FaFilter />
          </button>
        </div>

        <div className="main">
          <div className="left">
            <div className="row-1">
              <div className="card">
                <h3>Melhor desempenho</h3>
                {

                  performance.heigherPerformance != null
                  ?<div className="professional-info">
                    {

                      performance.heigherPerformance.professional.image != ""
                        ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${user.id}/${performance.heigherPerformance.professional.image}`}></img>
                        :<div style={{width: "70px", height: "70px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "lightgray"}}><FaUser style={{width: "22px", height: "22px", color: "white"}}/></div>
                    }
                    <div className="info">
                      <h4>{performance.heigherPerformance.professional.name}</h4>
                      <div className="qty"><span>Quantidade: </span><h4>{performance.heigherPerformance.total}</h4></div>
                      </div>
                  </div>
                  : null
                }
                
              </div>
              <div className="card">
                <h3>Pior desempenho</h3>
                {

                  performance.lowerPerformance != null
                  ?<div className="professional-info">
                    {

                      performance.heigherPerformance.professional.image != ""
                        ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${user.id}/${performance.lowerPerformance.professional.image}`}></img>
                        :<div style={{width: "70px", height: "70px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "100%", backgroundColor: "lightgray"}}><FaUser style={{width: "22px", height: "22px", color: "white"}}/></div>
                    }
                    <div className="info">
                      <h4>{performance.lowerPerformance.professional.name}</h4>
                      <div className="qty"><span>Quantidade: </span><h4>{performance.lowerPerformance.total}</h4></div>
                    </div>
                  </div>
                  : null
                  }
              </div>
            </div>
            <div className="row-2">
              <h3>Resumo total</h3>
              <div className="chart">

                {

                  totalComissionForEachProfessional.every(item => item.totalComission == 0)
                  ? (

                      <span style={{alignSelf: "center", justifySelf: "center"}}>Sem dados!</span>
                    )
                  : (

                      <Doughnut
                        data={{
                          labels: totalComissionForEachProfessional.map(item => item.professional.name),
                          datasets: [{
                            label: 'R$',
                            data: totalComissionForEachProfessional.map(item => item.totalComission),
                            borderColor: 'transparent',
                            backgroundColor: [

                              "#EE3F2D",
                              "#B8BDE3",
                              "#DCDCDC",
                              "#101418",
                            ]
                          }]
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: function(context) {
                                  let label = context.dataset.label || '';
                                  if (label) {
                                    label += ' ';
                                  }
                                  label += context.raw;
                                  return label;
                                }
                              }
                            },
                            legend: {

                              position: 'bottom',
                              align: 'start',
                              labels: {

                                pointStyle: 'circle',
                                usePointStyle: true
                              }
                            }
                          }
                        }}
                      />
                    )
                }

                
              </div>

            </div>
          </div>
          <div className="right">
            <div className="row">
              <h3>Comissões</h3>
              <div className="list">
                {

                  totalComissionForEachProfessional.map((item, index)=>{

                    return <ProfessionalItem key={index} data={{barberId: user.id, professionalId: item.professional.id, image: item.professional.image, name: item.professional.name, revenue: formatIntoCurrency(item.totalComission)}}/>
                  })
                }
              </div>
            </div>
            <div className="row">
              <h3>Quantidade de serviços</h3>
              <div className="list">
              {

                totalServicesForEachProfessionalS.map((item, index)=>{

                  return <ProfessionalItem key={index} data={{barberId: user.id, professionalId: item.professional.id, image: item.professional.image, name: item.professional.name, revenue: item.total}}/>
                })
              }
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default ProfessionalsReports