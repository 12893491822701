// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-pop-up-container{

    z-Index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-pop-up-container .box{

    height: 160px;
    width: 300px;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: white;
}

.delete-pop-up-container .box .btns-container{

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.delete-pop-up-container .box .btns-container .cancel{

    width: 72px;
    height: 30px;
    border: 0;
    outline: solid 1px lightgray;
    color: #696969;
    font-weight: 600;
    border-radius: 2px;
    cursor: pointer;
}

.delete-pop-up-container .box .btns-container .delete{

    width: 72px;
    height: 30px;
    background-color: red;
    border: 0;
    outline: 0;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
}

.delete-pop-up-container .box .btns-container .delete:hover{

    background-color: rgb(165, 0, 0)

}`, "",{"version":3,"sources":["webpack://./src/components/commom/delete-confirm-pop-up/DeleteConfirmPopUp.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;;IAEI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,4BAA4B;IAC5B,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI;;AAEJ","sourcesContent":[".delete-pop-up-container{\n\n    z-Index: 1;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.delete-pop-up-container .box{\n\n    height: 160px;\n    width: 300px;\n    padding: 20px;\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-direction: column;\n    background-color: white;\n}\n\n.delete-pop-up-container .box .btns-container{\n\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.delete-pop-up-container .box .btns-container .cancel{\n\n    width: 72px;\n    height: 30px;\n    border: 0;\n    outline: solid 1px lightgray;\n    color: #696969;\n    font-weight: 600;\n    border-radius: 2px;\n    cursor: pointer;\n}\n\n.delete-pop-up-container .box .btns-container .delete{\n\n    width: 72px;\n    height: 30px;\n    background-color: red;\n    border: 0;\n    outline: 0;\n    color: white;\n    font-weight: 600;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.delete-pop-up-container .box .btns-container .delete:hover{\n\n    background-color: rgb(165, 0, 0)\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
