import { useEffect, useState } from "react";
import "./ServicesReports.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerCustom } from "../../../../components/commom/form-input/FormInput";
import { FaFilter } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import { calcPerformance, totalQuantityForEachServiceType, totalRevenueForEachServiceType, totalServicesForEachProfessional } from "../../../../utils/functions/CalcFunctions";
import ServiceItem from "../finances/ServiceItem";
import { formatIntoCurrency } from "../../../../utils/functions/Formatters";
import { useAuth } from "../../../../context/AuthContext";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';

const ServicesReports = () => {

  const { user } = useAuth();
  const servicesFilterDate = JSON.parse(sessionStorage.getItem("servicesReportsPeriod"));

  const [showDateFilter, setShowDateFilter] = useState(false);
  const [startDate, setStartDate] = useState(servicesFilterDate != null ? moment(servicesFilterDate.startDate).utc() : moment().utc());
  const [endDate, setEndDate] = useState(servicesFilterDate != null ? moment(servicesFilterDate.endDate).utc() :moment().utc());
  const [filtersTrigger, setFiltersTrigger] = useState(false);

  const [professionals, setProfessionals] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [services, setServices] = useState([]);

  const [totalRevenueForEachServiceTypeS, setTotalRevenueForEachServiceTypeS] = useState([]);
  const [totalQuantityForEachServiceTypeS, setTotalQuantityForEachServiceTypeS] = useState([]);
  const [performance, setPerformance] = useState({

    heigherPerformance: null,
    lowerPerformance: null
  });

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const filter = () => {

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    setShowDateFilter(!showDateFilter);

    sessionStorage.setItem("servicesReportsPeriod", JSON.stringify({startDate: startDate, endDate: endDate}))

    axios.get("/api/schedules", {
      params:{
        barberId: user.id,
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD")
      }
    })
      .then(res=>{

        if(res.data.success === true){

          setTotalRevenueForEachServiceTypeS(totalRevenueForEachServiceType(services, res.data.data));
          setTotalQuantityForEachServiceTypeS(totalQuantityForEachServiceType(services, res.data.data));
          setPerformance(calcPerformance(totalQuantityForEachServiceType(services, res.data.data)));

        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });
        
  }

  useEffect(()=>{

    const start = moment.tz(startDate, userTimezone).startOf('day');
    const end = moment.tz(endDate, userTimezone).endOf('day');

    const fetchData = () => {

      axios.get("/api/schedules", {
      params:{
        barberId: user.id,
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD")
      }
    })
      .then(res=>{

        if(res.data.success === true){

          setSchedules(res.data.data);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
    
          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);
      });

      axios.get(`/api/professionals-by-barber/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          let pArray = res.data.data;

          setProfessionals(pArray);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
    
            notifyError(err.response.data.message);

          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);

      });

      axios.get(`/api/services/${user.id}`)
      .then(res=>{

        if(res.data.success === true){

          setServices(res.data.data);
        }
      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 404){
    
            notifyError(err.response.data.message);

          }else if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);

      });
    }

    if(user){

      fetchData();
    }

  }, []);

  useEffect(()=>{

    setTotalRevenueForEachServiceTypeS(totalRevenueForEachServiceType(services, schedules));
    setTotalQuantityForEachServiceTypeS(totalQuantityForEachServiceType(services, schedules));
    setPerformance(calcPerformance(totalQuantityForEachServiceType(services, schedules)));

    

  }, [professionals, services, schedules]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {
        filtersTrigger
          ? <div className="filters-mobile-container">
              <div className="top">
                <IoMdClose onClick={()=>{setFiltersTrigger(!filtersTrigger)}} />
              </div>
              <div className="date">
                <span>Data:</span>
                <div className="row">
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
                  <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
                </div>
              </div>
              
              <button className="filter" onClick={()=>{filter()}}>Filtrar</button>
          </div>
          : null
      }
      <div className="services-reports">
        <div className="top">
          <div className="filters-desktop">
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={startDate.toDate()} onChange={(date) => setStartDate(moment(date).utc())} />
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<DatePickerCustom/>} selected={endDate.toDate()} onChange={(date) => setEndDate(moment(date).utc())} />
            <button className="search" onClick={()=>{filter()}}>Pesquisar</button>
          </div>
          <button className="filters-mobile" onClick={()=>{setFiltersTrigger(!filtersTrigger)}}>
            <FaFilter />
          </button>
        </div>

        <div className="main">
          <div className="left">
            <div className="row-1">
              <div className="card">
                <h3>Mais requisitado</h3>
                {

                  performance.heigherPerformance != null
                  ?<div className="service-info">
                    <div className="info">
                      <h4>{performance.heigherPerformance.service.name}</h4>
                      <div className="qty"><span>Quantidade: </span><h4>{performance.heigherPerformance.total}</h4></div>
                      </div>
                  </div>
                  : null
                }
                
              </div>
              <div className="card">
                <h3>Menos requisitado</h3>
                {

                  performance.lowerPerformance != null
                  ?<div className="service-info">
                    <div className="info">
                      <h4>{performance.lowerPerformance.service.name}</h4>
                      <div className="qty"><span>Quantidade: </span><h4>{performance.lowerPerformance.total}</h4></div>
                    </div>
                  </div>
                  : null
                  }
              </div>
            </div>
            <div className="row-2">
              <h3>Resumo</h3>
              <div className="chart">

                {

                  totalRevenueForEachServiceTypeS.every(item => item.total == 0)
                  ? (

                      <span style={{alignSelf: "center", justifySelf: "center"}}>Sem dados!</span>
                    )
                  : (

                      <Doughnut
                        data={{
                          labels: totalRevenueForEachServiceTypeS.map(item => item.service.name),
                          datasets: [{
                            label: 'R$',
                            data: totalRevenueForEachServiceTypeS.map(item => item.total),
                            borderColor: 'transparent',
                            backgroundColor: [

                              "#EE3F2D",
                              "#B8BDE3",
                              "#DCDCDC",
                              "#101418",
                            ]
                          }]
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: function(context) {
                                  let label = context.dataset.label || '';
                                  if (label) {
                                    label += ' ';
                                  }
                                  label += context.raw;
                                  return label;
                                }
                              }
                            },
                            legend: {

                              position: 'bottom',
                              align: 'start',
                              labels: {

                                pointStyle: 'circle',
                                usePointStyle: true
                              }
                            }
                          }
                        }}
                      />
                    )
                }

                
              </div>

            </div>
          </div>
          <div className="right">
            <div className="row">
              <h3>Receita por tipo de serviço</h3>
              <div className="list">
                {

                  totalRevenueForEachServiceTypeS.map((item, index)=>{

                    return <ServiceItem key={index} data={{name: item.service.name, revenue: formatIntoCurrency(parseFloat(item.total))}}/>
                  })
                }
              </div>
            </div>
            <div className="row">
              <h3>Quantidade de serviços</h3>
              <div className="list">
                {

                  totalQuantityForEachServiceTypeS.map((item, index)=>{

                    return <ServiceItem key={index} data={{name: item.service.name, revenue: item.total}}/>
                  })
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default ServicesReports