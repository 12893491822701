import React from 'react';
import './DeleteConfirmPopUp.css';

const DeleteConfirmPopUp = ({message, data, cancel, action, cancelBtnText, actionBtnText}) => {
  
  return (
    <div className='delete-pop-up-container'>
        <div className='box'>
          <span style={{fontSize: '16px', fontWeight: '600'}}>{message}</span>
          <div className='btns-container'>
              <button className='cancel' type='button' onClick={()=>{cancel(false)}}>{cancelBtnText}</button>
              <button className='delete' type='button' onClick={(e)=>{action(e, data)}}>{actionBtnText}</button>
          </div>
        </div>
    </div>
  )
}

export default DeleteConfirmPopUp;