import axios from "axios";
import moment from "moment/moment";
import { useContext, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { FormInput, MaskedFormInput } from "../../../../../components/commom/form-input/FormInput";
import { GeneralBarsContext } from "../../../../../context/generalBarsContext";
import LoadingIcon from "../../../../../utils/images/loading_1.gif";
import "./AddProfessionalBar.css";
import { useAuth } from "../../../../../context/AuthContext";
import { FaUser } from "react-icons/fa";

const AddProfessionalBar = () => {

    const notifySuccess = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyError = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const {addProfessionalBarState, setAddProfessionalBarState, selectedProfessionalData, addProfessionalBarTriggerName} = useContext(GeneralBarsContext);

    const { user } = useAuth();

    const [loading, setLoading] = useState(false);

    const [professionalData, setProfessionalData] = useState({

        id: addProfessionalBarTriggerName == "edit"
            ? selectedProfessionalData.id
            : uuidv4(),
        image: null,
        name: addProfessionalBarTriggerName == "edit"
            ? selectedProfessionalData.name
            : "",
        phoneNumber: addProfessionalBarTriggerName == "edit"
                ? selectedProfessionalData.phoneNumber
                : "",
        email: addProfessionalBarTriggerName == "edit"
            ? selectedProfessionalData.email
            : "",

        comission: addProfessionalBarTriggerName == "edit"
            ? selectedProfessionalData.comission
            : "",
    });

    const validateFields = (name) =>{

        if(name == "phoneNumber"){

            let str1 = professionalData.phoneNumber.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", "");
            if(str1.length == 11){
            return false;
            }else{
        
            return true;
            }
        }
        
    }

    const handleInput = (e) =>{

        e.preventDefault();

        if(e.target.name == "image"){

            setProfessionalData({...professionalData, [e.target.name] : e.target.files[0]});

        }else{

            setProfessionalData({...professionalData, [e.target.name] : e.target.value});
        }
    }

    const handleSubmit = (e) => {

        setLoading(true);

        e.preventDefault()

        if(user != null){
        
            if(addProfessionalBarTriggerName == "edit"){

                if(professionalData.image && professionalData.image.type.startsWith("image/")){

                    const formdata = new FormData();
                    formdata.append('professionalId', professionalData.id);
                    formdata.append('barberId', user.id);
                    formdata.append('image', professionalData.image);

                    axios.post('/api/professionals/add-professional/add-professional-image', formdata)
                    .then(res=> {
                        if(res.data.success == true){

                            axios.put(`/api/professionals/edit-professional/${professionalData.id}`, {

                                image: res.data.image,
                                name: professionalData.name, 
                                phoneNumber: professionalData.phoneNumber, 
                                comission: professionalData.comission, 
                                email: professionalData.email,
                                modifiedAt: moment().utc()
                            })
                            .then(res=>{

                                if(res.data.success === true){

                                    setTimeout(()=>{

                                        setLoading(false);
                                        notifySuccess('Editado com sucesso!');

                                        setTimeout(()=>{

                                            setAddProfessionalBarState(!addProfessionalBarState);
                        
                                            setProfessionalData({
                                                ...professionalData,
                                                image: "",
                                                name: "",
                                                phoneNumber: "",
                                                email: ""
                                            });  
                                        }, 3000);
                                    }, 3000);  
                                }
                            })
                            .catch(err=>{

                                //EDIT PROFESSIONAL WITH IMAGE

                                setTimeout(()=>{

                                    if(err.response.status === 500){
                        
                                        notifyError(err.response.data.message);
                                    }else if(err.response.status === 400){
                        
                                        notifyError(err.response.data.message);
                                    }else{
                                        
                                    }
                                }, 1500);
                                setLoading(false);
                            });
                        }
                    })
                    .catch(err=>{

                        //EDIT PROFESSIONAL IMAGE

                        setTimeout(()=>{

                            if(err.response.status === 500){
                
                                notifyError(err.response.data.message);
                            }else{
                                
                            }
                        }, 1500);
                        setLoading(false);
                    });
                }else{

                    axios.put(`/api/professionals/edit-professional/${professionalData.id}`, {
                        
                        image: selectedProfessionalData.image,
                        name: professionalData.name, 
                        phoneNumber: professionalData.phoneNumber, 
                        comission: professionalData.comission, 
                        email: professionalData.email,
                        modifiedAt: moment().utc()
                    })
                    .then(res=>{
        
                        if(res.data != null && res.data != "Error"){

                            setTimeout(()=>{

                                setLoading(false);
                                notifySuccess('Editado com sucesso!');

                                setTimeout(()=>{

                                    setAddProfessionalBarState(!addProfessionalBarState);
                
                                    setProfessionalData({
                                        ...professionalData,
                                        image: "",
                                        name: "",
                                        phoneNumber: "",
                                        email: ""
                                    });  
                                }, 3000); 
                            }, 3000);
                        }
                    })
                    .catch(err=>{

                        //EDIT PROFESSIONAL WITHOUT IMAGE

                        setTimeout(()=>{

                            if(err.response.status === 500){
                
                                notifyError(err.response.data.message);
                            }else if(err.response.status === 400){
                
                                notifyError(err.response.data.message);
                            }else{
                                
                            }
                        }, 1500);
                        setLoading(false);
                    });
                }            
            }else{

                if(professionalData.image && professionalData.image.type.startsWith("image/")){

                    const formdata = new FormData();
                    formdata.append('professionalId', professionalData.id);
                    formdata.append('barberId', user.id);
                    formdata.append('image', professionalData.image);

                    axios.post('/api/professionals/add-professional/add-professional-image', formdata)
                    .then(res=> {

                        if(res.data.success){

                            axios.post('/api/professionals/add-professional', {
                                id: professionalData.id,
                                barberId: user.id,
                                image: res.data.image,
                                name: professionalData.name, 
                                phoneNumber: professionalData.phoneNumber, 
                                comission: professionalData.comission, 
                                email: professionalData.email,
                                createdAt: moment().utc()
                            })
                            .then(res=>{
                
                                if(res.data.success){

                                    setTimeout(()=>{

                                        setLoading(false);
                                        notifySuccess('Adicionado com sucesso!');

                                        setTimeout(()=>{

                                            setAddProfessionalBarState(!addProfessionalBarState);
                                            setProfessionalData({
                                                ...professionalData,
                                                image: "",
                                                name: "",
                                                phoneNumber: "",
                                                email: ""
                                            });  
                                        }, 1500);
                                    }, 1500);

                                }
                            })
                            .catch(err=>{

                                //ADD PROFESSIONAL WITH IMAGE

                                setTimeout(()=>{

                                    if(err.response.status === 500){
                        
                                    notifyError(err.response.data.message);
                                    }else{
                                        
                                    }
                                }, 1500);
                                setLoading(false);
                            });
                        }
                    })
                    .catch(err=>{

                        //SAVE PROFESSIONAL IMAGE

                        setTimeout(()=>{

                            if(err.response.status === 500){
                
                              notifyError(err.response.data.message);
                            }else{
                                
                            }
                          }, 1500);
                        setLoading(false);
                    });

                }else{

                    axios.post('/api/professionals/add-professional', {
                        id: professionalData.id,
                        barberId: user.id,
                        image: "",
                        name: professionalData.name, 
                        phoneNumber: professionalData.phoneNumber, 
                        comission: professionalData.comission, 
                        email: professionalData.email,
                        createdAt: moment().utc()
                    })
                    .then(res=>{
                        
                        if(res.data.success){

                            setTimeout(()=>{

                                setLoading(false);
                                notifySuccess('Adicionado com sucesso!');

                                setTimeout(()=>{

                                    setAddProfessionalBarState(!addProfessionalBarState);
                                    setProfessionalData({
                                        ...professionalData,
                                        image: "",
                                        name: "",
                                        phoneNumber: "",
                                        email: ""
                                    });  
                                }, 1500);
                            }, 1500);

                        }
                    })
                    .catch(err=>{

                        //ADD PROFESSIONAL WITHOUT IMAGE

                        setTimeout(()=>{

                            if(err.response.status === 500){
                
                              notifyError(err.response.data.message);
                            }else{
                                
                            }
                          }, 1500);
                        setLoading(false);
                    });
                        
                }
            }
        }
        
    }

  return (

    <>
    
        {

            addProfessionalBarState
            ?
            <form className="add-professional-bar-bg">

                <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                />
                <ToastContainer />
                <div className="container">
                    <div className="top-container">
                        <span className="title">Adicionar Profissional</span>
                        <IoClose onClick={()=>{setAddProfessionalBarState(!addProfessionalBarState)}} className="icon"/>
                    </div>

                    <div className="image-container">
                        <input type="file" id="change-image-p" onChange={(e)=>handleInput(e)} name="image"/>
                        {

                            selectedProfessionalData != null
                                ? professionalData.image != null
                                    ? <img src={URL.createObjectURL(professionalData.image)} />
                                    : selectedProfessionalData.image != null
                                        ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${user.id}/${selectedProfessionalData.image}`} />
                                        : <div className="icon-container"><FaUser className="icon"/></div> 

                                : professionalData.image != null
                                    ? <img src={URL.createObjectURL(professionalData.image)} />
                                    : <div className="icon-container"><FaUser className="icon"/></div>
                        }

                        <span onClick={()=>{document.getElementById("change-image-p").click()}}>{(selectedProfessionalData != null && selectedProfessionalData.image != "") || professionalData.image != null ? "Alterar imagem" : "Adicionar imagem"}</span>
                    
                    </div>
                    <div className="fields">
                        <FormInput
                            inputData={{

                                id: 2,
                                name: "name",
                                label: "Nome *",
                                placeholder: "Nome do prossional",
                                pattern: "^[A-Za-z0-9_ ]{0,50}$",
                                error: "O nome do profissional deve ter entre 3 e 50 letras e/ou números!",
                                value: professionalData.name,
                                required: true
                            }}
                            handleInput={handleInput}
                        />

                        <MaskedFormInput
                            inputData={{

                                id: 3,
                                name: "phoneNumber",
                                label: "Telefone *",
                                placeholder: "Telefone",
                                value: professionalData.phoneNumber,
                                required: true,
                                mask: "(99) 99999-9999",
                                hasError: validateFields("phoneNumber").toString(),
                                error: "Número inválido!"
                            }}
                            handleInput={handleInput}
                        />

                        <FormInput
                            inputData={{

                                id: 4,
                                name: "email",
                                label: "E-mail *",
                                placeholder: "E-mail",
                                value: professionalData.email,
                                type: "email",
                                error: "Email inválido!",
                                required: true
                            }}
                            handleInput={handleInput}
                        />

                        <FormInput
                            inputData={{

                                id: 5,
                                name: "comission",
                                label: "Comissão (%) *",
                                placeholder: "0%",
                                value: professionalData.comission,
                                type: "number",
                                error: "",
                                required: true
                            }}
                            handleInput={handleInput}
                        />

                    </div>
                    <div className="bottom-container">
                        {

                            addProfessionalBarTriggerName == "edit"
                                ? !loading
                                    ? <button onClick={(e)=>{handleSubmit(e)}} className="add-professional-btn">Editar Profissional</button>
                                    : <button style={{gap: "10px"}} className="add-professional-btn"><img style={{width: "20px", height: "20px"}} src={LoadingIcon}/>Salvando Alterações</button>
                                : !loading
                                    ? professionalData.name != "" &&
                                        professionalData.phoneNumber != "" &&
                                        professionalData.email != "" &&
                                        professionalData.comission != "" 
                                            ?   <button onClick={(e)=>{handleSubmit(e)}} className="add-professional-btn">Adicionar Profissional</button>
                                            :   <button disabled={true} onClick={(e)=>{handleSubmit(e)}} className="add-professional-btn">Adicionar Profissional</button>
                                         
                                    : <button style={{gap: "10px"}} className="add-professional-btn"><img style={{width: "20px", height: "20px"}} src={LoadingIcon}/>Salvando Alterações</button>
        
                        }
                    </div>
                </div>
            </form>
            : null
        }
    </>
    
  )
}

export default AddProfessionalBar;