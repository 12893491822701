import React, { useState } from 'react'
import { AuthInput, FormInput } from '../form-input/FormInput'
import { Colors } from '../../../utils/Colors';
import { FaArrowLeft } from 'react-icons/fa';
import logoGray from "../../../utils/images/logo_gray.svg";

const RedefinePassPopup = ({setRecoverPassTrigger, active, recoverPass}) => {

    const [recEmail, setRecEmail] = useState();

    const handleInput = (e) => {

        setRecEmail(e.target.value);
    }

    return (
        <>
            {
                active
                    ?<form action='submit' className='recover-box'>

                        <img className='logo' src={logoGray}></img>

                        <h1>Redefinir sua senha</h1>
                        <span className='recover-message'><strong>Esqueceu sua senha?</strong> Só digitar o <strong>e-mail</strong> que você usou no cadastro que iremos enviar um link para você criar uma nova.</span>
                        <AuthInput
                        data={{

                            iconName: "envelope",
                            label: "Digite seu e-mail",
                            placeholder: "Digite seu e-mail aqui",
                            name: "email",
                            type: "email",
                            error: "Digite um e-mail válido",
                            value: recEmail,
                            require: true,
                            handleInput: handleInput
                        }}
                        iconStyle={{

                            color: Colors.PRIMARY_COLOR_LIGHT,
                            width: "20px",
                            height: "20px"
                        }}
                        />
                        <button type='submit' onClick={(e)=>{recoverPass(e, recEmail)}} className='recover-pass-button'>Redefinir senha</button>
                        <div className='signin-divider'></div>
                        <strong onClick={()=>{setRecoverPassTrigger(false)}} className='cancel-recover'><FaArrowLeft />
                        voltar para Login</strong>
                    </form>
                    : ""
            }
        </>
    )
}

export default RedefinePassPopup;