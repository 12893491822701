// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-popup{

    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    scrollbar-width: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/commom/loading-pop-up/LoadingPopUp.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,oCAAoC;IACpC,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".loading-popup{\n\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    z-index: 1;\n    background-color: rgba(0, 0, 0, 0.6);\n    overflow: hidden;\n    scrollbar-width: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
