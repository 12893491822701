import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaEnvelope, FaUser, FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../../../node_modules/axios/index';
import { useAuth } from '../../../../context/AuthContext';
import { toClientDateTime } from '../../../../utils/functions/Formatters';
import { UpdateSlotsBasedOnSelected } from '../../../../utils/functions/SlotsManager';
import './MakeBooking.css';
import MakeBookingPopUp from './make-booking-popup/MakeBookingPopUp';

const MakeBooking = ({barberData, businessRules}) => {

  const { user } = useAuth();

  const navigate = useNavigate();

  const [barberImages, setBarberImages] = useState(null);

  const [servicesList, setServicesList] = useState([]);
  const [professionalsList, setProfessionalsList] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({

    index: "",
    slot: ""
  });
  const [slots, setSlots] = useState([]);
  const [updatedSlots, setUpdatedSlots] = useState([]);
  const [scheduleData, setScheduleData] = useState({

    id: uuidv4(),
    barberId: barberData.id,
    customerId: user.id,
    customerName: user.name,
    customerEmail: user.email,
    customerPhoneNumber: user.phoneNumber,
    professionalId : "",
    serviceId : "",
    serviceName : "",
    servicePrice : "",
    serviceDuration : "",
    date : moment().format("YYYY-MM-DD"),
    time : "",
    status : "pending",
    createdAt: moment().utc()
  });

  const [trigger, setTrigger] = useState(false);

  const [tab, setTab] = useState("services");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '450px'
  }
  
  const notifySuccess = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = (message) => toast.error(message, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });

  const handleInput = (e,) => {

    if(e.target.name == "date"){

      setScheduleData({...scheduleData, [e.target.name] : e.target.value});
    }else{

    }
  }

  const handleInputService = (service) => {

    if(service){

      const item = servicesList.filter((item)=>{


        return item.id == service;
      });
  
      setScheduleData({...scheduleData, serviceId : item[0].id, serviceName: item[0].name, servicePrice: item[0].price, serviceDuration: item[0].duration});
    }
  }

  const handleInputProfessional = (professional) => {

    if(professional){

      const item = professionalsList.filter((item)=>{

        return item.id == professional;
      });
      
      if(item.length !== 0) setScheduleData({...scheduleData, professionalId : item[0].id});

    }

  }
  const handleSubmit = async (e) => {

    setLoading(true);

    e.preventDefault();

    const scheduleDateTimeF = moment(scheduleData.date).hour(parseInt(scheduleData.time.split(":")[0])).minute(parseInt(scheduleData.time.split(":")[1])).utc();

    await axios.post("/api/schedules/add-schedule", {
      
      ...scheduleData,
      scheduleDateTime: scheduleDateTimeF
    })
    .then(res=>{

      if(res.data.success){

        axios.post("/api/email/customer-schedule-notification", {

          email: user.email,
          userName: user.name,
          scheduleDate: toClientDateTime(scheduleDateTimeF, "DD/MM/YYYY"),
          scheduleTime: toClientDateTime(scheduleDateTimeF, "HH:mm"),
          enterpriseName: barberData.enterpriseName,
          barberAddress: barberData.street + ", nº" + barberData.streetNumber + ", " + barberData.neighborhood + ", " + barberData.city + " - " + barberData.state,
          barberPhone: barberData.whatsapp,
      })
      .then(res=>{

          if(res.data.success === true){

            axios.post("/api/email/barber-schedule-notification", {

                email: barberData.email,
                userName: barberData.masterName,
                scheduleDate: toClientDateTime(scheduleDateTimeF, "DD/MM/YYYY"),
                scheduleTime: toClientDateTime(scheduleDateTimeF, "HH:mm"),
                serviceName: scheduleData.serviceName,
                customerName: user.name,
                customerEmail: user.email,
                customerPhone: user.phoneNumber
            })
            .then(res=>{

                if(res.data.success){

                    setTimeout(()=>{

                        setLoading(false);
                        notifySuccess("Agendamento executado com sucesso!");
    
                        setTimeout(()=>{

                          navigate("/meus-agendamentos")
    
                            setTrigger(!trigger);
    
                            setScheduleData({
                              ...scheduleData,
                                id: "",
                                barberId: "",
                                createdAt: moment().utc(),
                                customerId: "",
                                customerName: "",
                                customerEmail: "",
                                customerPhoneNumber: "",
                                professionalId: "",
                                serviceId: "",
                                serviceName: "",
                                servicePrice: "",
                                serviceDuration: "",
                                time: "",
                                status: ""
                            });
                        
                        }, 1500);
                    }, 1500);
                }
            })
            .catch(err=>{

              setTimeout(()=>{

                if(err.response.status === 500){
        
                  notifyError(err.response.data.message);
        
                }else{
        
                }
              }, 1500);
            });
          }

      })
      .catch(err=>{

        setTimeout(()=>{

          if(err.response.status === 500){
  
            notifyError(err.response.data.message);
  
          }else{
  
          }
        }, 1500);

      });

      }

    })
    .catch(err=>{

      setTimeout(()=>{

        if(err.response.status === 500){

          notifyError(err.response.data.message);

        }else{

        }
      }, 1500);
    });

  }

  const getTab = (tab) => {

    if(tab === "services"){

      return <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "10px"}}>
        {
          servicesList.map(service=>{

            return <div style={{display: "flex", padding: "20px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#ECEFF1", borderRadius: "6px"}}>
              <span style={{flex: 1, fontWeight: "600", fontSize: "16px"}}>{service.name}</span>
              <span style={{flex: 1, textAlign: "center"}}>{service.duration} minutos</span>
              <span style={{flex: 1, textAlign: "end", fontWeight: "600"}}>{service.price}</span>
            </div>
          })
        }
      </div>

    }else if(tab === "professionals"){

      return <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "10px"}}>
      {
        professionals.map(professional=>{

          return <div style={{display: "flex", padding: "20px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#ECEFF1", borderRadius: "6px", gap: "10px"}}>

            {

              professional.image != null
                ? <img style={{width: "60px", height: "60px", borderRadius: "100%", objectFit: "cover"}} src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/professionals/${barberData.id}/${professional.image}`}></img>
                : <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "50px", height: "50px", backgroundColor: "#CBCBCB", borderRadius: "100%", fontSize: "20px", color: "white"}}><FaUser/></div>
            }
            
            <span style={{flex: 1, fontWeight: "600", fontSize: "16px"}}>{professional.name}</span>
          </div>
        })
      }
    </div>
    }
  }

  useEffect(()=>{

    const fetchData = async () => {

      const servicesReq = await axios.get(`/api/services/${barberData.id}`);

      setServicesList(servicesReq.data.data);

      const professionalsReq = await axios.get(`/api/professionals-by-barber/${barberData.id}`);

      setProfessionals(professionalsReq.data.data);

      const resImages = await axios.get(`/api/barbers/marketing-images/${barberData.id}`);

      setBarberImages(resImages.data.images)
    }

    fetchData()

  }, []);

  useEffect(()=>{

    const fetchData = async () => {

      if(scheduleData.serviceId != ""){

        const p2sReq = await axios.get(`/api/professionals-to-services/${scheduleData.serviceId}`);

        Promise.all(


          p2sReq.data.data.map(async(p2s)=>{

            let res;

            res = await axios.get(`/api/professionals/${p2s.professionalId}`);

            return res.data;
          })
        )
        .then(res=>{

          let professionalsArray = [];
          res.map((professional)=>{

            professionalsArray.push(professional.data)
          });

          setProfessionalsList(professionalsArray);
        });
      }
    }

    fetchData()

  },[scheduleData.serviceId]);

  useEffect(()=>{

    setScheduleData({...scheduleData, time : selectedSlot.slot})

    let newSlots = [...slots]

    UpdateSlotsBasedOnSelected(selectedSlot, newSlots, scheduleData.serviceDuration, barberData != null ? barberData.minTimeService : null, setUpdatedSlots)
},[selectedSlot]);

  return (

    <div className='mb-container'>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {

        trigger
        ? <MakeBookingPopUp setSelectedSlot={setSelectedSlot} selectedSlot={selectedSlot} handleInput={handleInput} handleInputService={handleInputService} handleInputProfessional={handleInputProfessional} scheduleData={scheduleData} services={servicesList} barberData={barberData} businessRules={businessRules} setTrigger={setTrigger} setProfessionalsList={setProfessionalsList}/>
        : null
      }
      {

        selectedSlot.slot != ""
        ? <div className='confirm-add-schedule'>
            <div className='box'>
              <span className='title'>Confira os detalhes do agendamento:</span>
              <div className='row'><strong>Data:</strong><span>{new Date(scheduleData.date).toLocaleDateString('pt-BR', {timeZone: "UTC"})}</span></div>
              <div className='row'><strong>Serviço:</strong><span>{scheduleData.serviceName}</span></div>
              <div className='row'><strong>Profissional:</strong><span>{
                
                  professionalsList != null && professionalsList.length > 0
                    ? professionalsList.find(professional => professional.id === scheduleData.professionalId)?.name
                    : null
                }</span></div>
              <div className='row'><strong>Horário:</strong><span>{scheduleData.time}</span></div>
              <div className='btns-container'>
                <button className='cancel' type='button' onClick={()=>{setSelectedSlot({slot: "", index: ""})}}>Cancelar</button>
                {
                  !loading
                    ? <button className={`add-schedule`} type='button' onClick={(e)=>{handleSubmit(e)}} disabled={success ? true : false}>{!success ? "Agendar" : "Concluído!"}</button>
                 
                    : <button className='add-schedule' type='button' onClick={(e)=>{handleSubmit(e)}} disabled>Agendando...</button>

                }
              </div>
            </div>
          </div>
        : null
      }
      <div className='barber-profile'>
        <div className="main">
          <div className="left">
            <div className="top">
              <div className="barber-info">

                {

                  barberData.image != null
                    ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/barber/${barberData.image}`} alt="" />
                    : <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgray", borderRadius: "100%", width: "60px", height: "60px"}}><FaUser style={{color: "white", width: "22px", height: "22px"}}/></div>
                }
                
                <h2>{barberData.enterpriseName}</h2>
              </div>
              <button onClick={()=>{setTrigger(true)}}>Agendar serviço</button>
            </div>
             {

              barberImages != null && barberImages.length > 0
              ?<Slide cssClass='images'>
                {
                  barberImages.map((item, index)=>{

                    return <div key={index} style={{ ...divStyle, 'backgroundImage': `url(${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/gallery/${barberData.id}/${item.image})` }}>
                            </div>
                  })
              }
                </Slide>
                : null
             }
              
            <div className="tabs">
              <div className="top">
                <button className={tab === "services" ? "active" : null} onClick={()=>{setTab("services")}}>Serviços</button>
                <button className={tab === "professionals" ? "active" : null} onClick={()=>{setTab("professionals")}}>Profissionais</button>
              </div>
              <div className="main">
                {

                  getTab(tab)

                }
              </div>
            </div>

          </div>
          <div className="right">
            <div className="box">
              <h3>Localização</h3>
              <div>
                {

                  barberData.postalCode + " - " +
                  barberData.street + ", " +
                  barberData.streetNumber + ", " +
                  barberData.neighborhood + ", " +
                  barberData.city + " - " +
                  barberData.state
                }
              </div>
            </div>
            <div className="divider"></div>
            <div className="box">
              <h3>Horário de atendimento</h3>
              {
                businessRules 
                  ?<>
                    {
                      businessRules.monday == "1" 
                        ? <div className='day'>
                            <span>Segunda</span>
                            <div className='times'>
                              <span>{businessRules.initialWorkTime + " - " + businessRules.initialLunchTime}</span>
                              <span>{businessRules.endLunchTime + " - " + businessRules.endWorkTime}</span>
                            </div>
                          </div>
                        : null
                    }
                    {
                      businessRules.tuesday == "1" 
                        ? <div className='day'>
                            <span>Terça</span>
                            <div className='times'>
                              <span>{businessRules.initialWorkTime + " - " + businessRules.initialLunchTime}</span>
                              <span>{businessRules.endLunchTime + " - " + businessRules.endWorkTime}</span>
                            </div>
                          </div>
                        : null
                    }
                    {
                      businessRules.wednesday == "1" 
                        ? <div className='day'>
                            <span>Quarta</span>
                            <div className='times'>
                              <span>{businessRules.initialWorkTime + " - " + businessRules.initialLunchTime}</span>
                              <span>{businessRules.endLunchTime + " - " + businessRules.endWorkTime}</span>
                            </div>
                          </div>
                        : null
                    }
                    {
                      businessRules.thursday == "1" 
                        ? <div className='day'>
                            <span>Quinta</span>
                            <div className='times'>
                              <span>{businessRules.initialWorkTime + " - " + businessRules.initialLunchTime}</span>
                              <span>{businessRules.endLunchTime + " - " + businessRules.endWorkTime}</span>
                            </div>
                          </div>
                        : null
                    }
                    {
                      businessRules.friday == "1" 
                        ? <div className='day'>
                            <span>Sexta</span>
                            <div className='times'>
                              <span>{businessRules.initialWorkTime + " - " + businessRules.initialLunchTime}</span>
                              <span>{businessRules.endLunchTime + " - " + businessRules.endWorkTime}</span>
                            </div>
                          </div>
                        : null
                    }
                    {
                      businessRules.saturday == "1" 
                        ? <div className='day'>
                            <span>Sábado</span>
                            <div className='times'>
                              <span>{businessRules.initialWorkTime + " - " + businessRules.initialLunchTime}</span>
                              <span>{businessRules.endLunchTime + " - " + businessRules.endWorkTime}</span>
                            </div>
                          </div>
                        : null
                    }
                    {
                      businessRules.sunday == "1" 
                        ? <div className='day'>
                            <span>Domingo</span>
                            <div className='times'>
                              <span>{businessRules.initialWorkTime + " - " + businessRules.initialLunchTime}</span>
                              <span>{businessRules.endLunchTime + " - " + businessRules.endWorkTime}</span>
                            </div>
                          </div>
                        : null
                    }
                  </>
                : null
              }

            </div>
            <div className="divider"></div>
            <div className="box">
              <h3>Contato</h3>
              <div className="row"><FaEnvelope className='icon' /> <span>atendimento@srbarberoficial.com.br</span></div>
              <div className="row"><FaWhatsapp className='icon'/><span>(31) 99624-6328</span></div>
            </div>
          </div>
        </div>
        {/*<div className='images-list'>

            {
              barberImages != undefined && barberImages.length > 0
                ? barberImages.map(image=>{

                    return <Zoom><img src={`/api/public/uploads/gallery/${image.barberId}/${image.image}`}/></Zoom>
                  })
                : ""
            }
          </div>*/}
      </div>
    </div>
  )
}

export default MakeBooking;