import "./Upgrade.css";
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import { CiImageOn } from "react-icons/ci";
import { useAuth } from "../../../context/AuthContext";
import { ImPower } from "react-icons/im";
import logoGray from "../../../utils/images/logo_gray.svg";
import { ToastContainer, toast } from 'react-toastify';

const Upgrade = () => {

    const notifyError = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const { user } = useAuth();

    const navigate = useNavigate();

    const getCheckout = async (planId, amount) => {

        const stripeCustomerRes = await axios.post("/api/upgrade/create-stripe-customer", {

        barberId: user.id,
        email: user.email,
        name: user.masterName,
        phone: user.whatsapp,
        });

        await axios.get(`/api/subscriptions/${user.id}`)
        .then(async resSub=>{

            if(
                resSub.data.data.subscriptionId === "" ||
                resSub.data.data.status === "incomplete" ||
                resSub.data.data.status === "free-trial-over"
            ){

                //Create a new subscription o Stripe

                await axios.post('/api/upgrade/create-subscription', {

                    stripeCustomerId: stripeCustomerRes.data.stripeCustomer.id,
                    planId: planId
                })
                .then(async res=>{

                    await axios.put(`/api/upgrade/save-subscription-id/${resSub.data.data.id}`, {

                        subscriptionId: res.data.subscriptionId,
                        stripeCustomerId: stripeCustomerRes.data.stripeCustomer.id,
                        status: "incomplete"
                    }).then(resSaveSub=>{


                        if(resSaveSub.data.success){

                            navigate(`/checkout?planId=${planId}&py=${res.data.paymentIntent}&cs=${res.data.clientSecret}&scid=${stripeCustomerRes.data.stripeCustomer.id.stripeCustomerId}&src=upgrade`);
                        }
                    })
                    .catch(err=>{

                        console.log(err);

                        if(err.response.status === 500){

                        }
                    });

                })
                .catch(err=>{

                    console.log(err);
                });

            }else{

                if(resSub.data.data.subscriptionId !== ""){

                    axios.post("/api/checkout/send-abandoned-cart-event", {

                        id: user.id,
                        email: user.email,
                        phoneNumber: user.whatsapp,
                        masterName: user.masterName,
                        planId: planId,
                        userAgent: navigator.userAgent
                        }).then(res => {
                    
                    
                        }).catch(err => {
                    
                        setTimeout(()=>{

                            if(err.response.status === 500){
                    
                                notifyError(err.response.data.message);
                    
                            }else{
                    
                            }
                            }, 1500);
                        });

                    await axios.get('/api/upgrade/stripe-subscription', { params:{

                        subscriptionId: resSub.data.data.subscriptionId
                    }})
                    .then(async resStripeSubscription=>{

                        if(resStripeSubscription.data.success){

                            await axios.post('/api/stripe/invoice', {invoiceId: resStripeSubscription.data.subscription.latest_invoice})
                            .then(async resInvoice => {

                                if(resInvoice.data.invoice.status === "draft"){

                                    await axios.post('/api/stripe/finalize-invoice', {invoiceId: resInvoice.data.invoice.id})
                                    .then(async resInvoiceEdited=>{

                                        await axios.post('/api/stripe/payment-intent', {paymentIntentId: resInvoiceEdited.data.invoice.payment_intent})
                                        .then(resPaymentIntent=>{

                                            if(resPaymentIntent.data.success === true){

                                                navigate(`/checkout?planId=${planId}&py=${resInvoiceEdited.data.invoice.payment_intent}&cs=${resPaymentIntent.data.paymentIntent.client_secret}&scid=${resSub.data.data.stripeCustomerId}&src=upgrade`);
                                            }

                                        })
                                        .catch(err=>{

                                            console.log(err);

                                        });

                                    })
                                    .catch(err=>{

                                        console.log(err);

                                    });
                                }else if(resInvoice.data.invoice.status === "open"){

                                    await axios.post('/api/stripe/payment-intent', {paymentIntentId: resInvoice.data.invoice.payment_intent})
                                        .then(resPaymentIntent=>{

                                            if(resPaymentIntent.data.success === true){

                                                navigate(`/checkout?planId=${planId}&py=${resInvoice.data.invoice.payment_intent}&cs=${resPaymentIntent.data.paymentIntent.client_secret}&scid=${resSub.data.data.stripeCustomerId}&src=upgrade`);
                                            }

                                        })
                                        .catch(err=>{

                                            console.log(err);

                                        });

                                }


                                /*await axios.post('/api/stripe/finalize-invoice', {invoiceId: resInvoice.data.invoice.id})
                                .then(resInvoiceEdited=>{

                                    console.log(resInvoiceEdited)
                                })
                                .catch(err=>{


                                })*/

                                /*await axios.post('/api/stripe/payment-intent', {paymentIntentId: resInvoice.data.invoice.payment_intent})
                                .then(resPaymentIntent=>{

                                    console.log(resPaymentIntent.data)
                                })
                                .catch(err=>{


                                });*/
                            })
                            .catch(err=>{

                                setTimeout(()=>{

                                    if(err.response.status === 500){
                            
                                        notifyError(err.response.data.message);
                            
                                    }else{
                            
                                    }
                                    }, 1500);
                            });


                        }
                    })
                    .catch(err=>{

                        setTimeout(()=>{

                            if(err.response.status === 500){
                    
                                notifyError(err.response.data.message);
                    
                            }else{
                    
                            }
                        }, 1500);

                    });
                }

            }

        })
        .catch(err=>{

            setTimeout(()=>{

                if(err.response.status === 500){

                    notifyError(err.response.data.message);

                }else if(err.response.status === 404){

                    notifyError(err.response.data.message);

                }else{

                }
            }, 1500);

        });

    }

    const logout = () => {

    localStorage.removeItem("token");
    navigate("/acessar-conta");
    }

  return (
    <div className='upgrade-container'>
        <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        <ToastContainer />
      <div className='top-bar'>

        <img className='logo' src={logoGray}></img>

        {

          user != null
              ? <div className="user-info">
                  {
                    user.image != ""
                      ? <img src={`${process.env.REACT_APP_STATIC_FILES_BASE_URL}/uploads/barber/${user.image}`}></img>
                      : <div className="icon-container"><CiImageOn/></div>
                  }
                  <span>{user.enterpriseName}</span>
                  <button onClick={()=>{logout()}} className="logout">
                    <BiLogOut className="icon"/>
                    <span>Sair</span>
                  </button>
                </div>
            : null
        }
        
        
      </div>

      <div className="main">
        <h1>⚠️ Opa {user.masterName}, o seu período de teste chegou ao fim! ⚠️</h1>
        <h4>Escolha um plano e continue usando o Sr Barber com todas as funcionalidades!</h4>

        <div className='plans'>
          <div className='card'>
              <div className='container'>
                <h1>Plano Premium Anual</h1>
                  <span className='p-number'>*Até 5 profissionais</span>
                  <div className="divider"></div>
                  <span className='line-through'>De R$550,80</span>
                  <span className='only'></span>
                  <span className='price'>R$<span className='bigger'>459</span>,00<span style={{fontSize: "18px", letterSpacing: -1, margin: 0, padding: 0}}></span></span>
                  <div className="divider"></div>
                  <button onClick={()=>{getCheckout("price_1PXCMdDq8zUWBlVvzkPwkJsJ", 45900)}}>Testar grátis</button>
                  <span className="free-test-text">Cobrança anual*</span>
              </div>
          </div>
          <div className='card' id='middle'>
            <div className="header">
                <h3>MAIS POPULAR</h3>
                <ImPower />
            </div>
              <div className='container'>
                <h1>Plano Premium Mensal</h1>
                  <span className='p-number'>*Até 5 profissionais</span>
                  <div className="divider"></div>
                  <span className='line-through'>De R$69,90</span>
                  <span className='only'>Por apenas</span>
                  <span className='price'>R$<span className='bigger'>45</span>,90<span style={{fontSize: "18px", letterSpacing: -1, margin: 0, padding: 0}}></span></span>
                  <div className="divider"></div>
                  <button onClick={()=>{getCheckout("price_1PXCM4Dq8zUWBlVvspTRlNJX", 4590)}}>Testar grátis</button>
                  <span className="free-test-text">Cobrança mensal*</span>
              </div>

          </div>
          <div className='card'>
              <div className='container'>
                <h1>Plano Diamond Mensal</h1>
                  <span className='p-number'>*Acima de 5 profissionais</span>
                  <div className="divider"></div>
                  <span className='line-through'>De R$109,90</span>
                  <span className='only'>Por apenas</span>
                  <span className='price'>R$<span className='bigger'>89</span>,90<span style={{fontSize: "18px", letterSpacing: -1, margin: 0, padding: 0}}></span></span>
                  <div className="divider"></div>
                  <button onClick={()=>{getCheckout("price_1PXCMJDq8zUWBlVvAJ1gub5L", 8990)}}>Testar grátis</button>
                  <span className="free-test-text">Cobrança mensal*</span>
              </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Upgrade;