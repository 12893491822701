import axios from 'axios';
import { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';
import "./NewBooking.css";
import GetBarber from './get-barber/GetBarber';
import MakeBooking from './make-booking/MakeBooking';
import { useAuth } from '../../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';

const NewBooking = () => {

  const { user } = useAuth();

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const [selectedBarber, setSelectedBarber] = useState(null);
  const [businessRules, setBusinessRules] = useState(null);
  const pc = urlParams.get('pc');

  const notifyError = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
});

  useEffect(()=>{

    const fecthData = async () => {

      if(pc != null){
        if(user != null && user.userType === "customer"){

          await axios.get("/api/barbers", {params:{profileLink: pc}})
          .then(async resBarberData=>{

            if(resBarberData.data.success){

              await axios.get(`/api/business-rules/${resBarberData.data.data[0].id}`)
              .then(resRules=>{

                if(resRules.data.success === true){

                  setSelectedBarber(resBarberData.data.data[0]);
                  setBusinessRules(resRules.data.data[0]);
                }
              })
              .catch(err=>{

                setTimeout(()=>{

                  if(err.response.status === 500){
      
                    notifyError(err.response.data.message);
                  }else if(err.response.status === 404){
        
                    notifyError(err.response.data.message);
                  }
                }, 1500);
              });
            }
          })
          .catch(err=>{

            setTimeout(()=>{

              if(err.response.status === 500){
  
                notifyError(err.response.data.message);
              }else{

              }
            }, 1500);
          });
        }else{
          navigate(`/acessar-conta-cliente/${pc}`);

        }
      }else{

      }
    }

    fecthData()
  }, [pc]);

  useEffect(()=>{

    const fecthData = async () => {


      if(selectedBarber){

        //navigate(`/fazer-agendamento?pc=${selectedBarber.profileLink}`);

        await axios.get(`/api/business-rules/${selectedBarber.id}`)
        .then(resRules=>{

          if(resRules.data.success === true){

            setBusinessRules(resRules.data.data[0]);

          }
        })
        .catch(err=>{

          setTimeout(()=>{

            if(err.response.status === 500){

              notifyError(err.response.data.message);
            }else if(err.response.status === 404){
  
              notifyError(err.response.data.message);
            }else{


            }
          }, 1500);

        });
      }
    }

    fecthData()
  }, [selectedBarber]);

  return (
    <div className='new-booking-box-container'>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {

        selectedBarber != null
          ? <MakeBooking barberData={selectedBarber} businessRules={businessRules}/>
          : <GetBarber setSelectedBarber={setSelectedBarber}/>
      }
    </div>
  )
}

export default NewBooking