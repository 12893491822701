import { useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { serviceDurationData } from '../../../utils/Data';
import "./AddServiceWelcome.css";
import ServiceItem from './ServiceItem';
import { FormInput, MainInput, MainSelect, SelectInput, SelectIpt, SelectIptMulti } from '../../commom/form-input/FormInput';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';
import { FaPlus } from 'react-icons/fa';

const AddServiceWelcome = ({setServicesList, servicesList, professionalsList, setCurrentStep, currentStep, currentUser}) => {

    let professionalsArray = [{name: "Selecione"}, ...professionalsList]

    let servicesArray = servicesList != null ? servicesList : [];

    const [service, setService] = useState({

        id: "",
        name: "",
        price: "",
        duration: "10",
        professionals: [],
        barberId: "",
        createdAt: ""

    });

    const [addServiceTrigger, setAddServiceTrigger] = useState(false);

    const [selectedProfessionalsList, setSelectedProfessionalsList] = useState([])

    const addService = () =>{

        if(
            service.name != "" && 
            service.name.length > 3 && 
            service.name.length < 50 && 
            service.price != "" && 
            service.duration != "" &&
            service.duration != "Selecione" &&
            service.professionals != undefined &&
            service.professionals.length > 0
        ){

            servicesArray.push({

                id: uuidv4(),
                name: service.name,
                price: service.price,
                duration: service.duration,
                professionals: service.professionals,
                barberId: currentUser.id,
                createdAt: moment().utc()
            });
            setServicesList(servicesArray);
            setService({

                id: "",
                name: "",
                price: "",
                duration: "",
                professionals: [],
                barberId: "",
                createdAt: ""

            });
            setAddServiceTrigger(false);
        }
    }


    const deleteService = (index) =>{

        servicesArray.splice(index, 1);
        setServicesList(servicesArray);
        setService({

            id: "",
            name: "",
            price: "",
            duration: "",
            professionals: [],
            barberId: currentUser.id,
            createdAt: ''

        });
    }

    const handleInput = (e) =>{

        if(e.target.name == "price"){

            let n1 = parseInt(e.target.value.replace(/[\D]+/g,''));

            let tmp = n1+"";
            tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
            if(tmp === "NaN"){

                tmp = "0,00"
            }else if(tmp.length > 6 && tmp.length <= 9){

                tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

            }else if(tmp.length > 9){

                tmp = tmp.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");

            }
            let formattedPrice = "R$ "+tmp;


            setService({...service, [e.target.name] : formattedPrice});


        }else{  
            setService({...service, [e.target.name] : e.target.value});
        }
    }

    const handleInputProfessionals = (professionals) => {

        let professionalsArray = [];

        professionals.map((professional)=>{


                const professionalData = professionalsList.filter(professionalItem=>{

                    return professionalItem.id == professional.value
                })

                professionalsArray.push(professionalData[0]);


        });
        setService({...service, professionals: professionalsArray})
    }

  return (
    <div className='welcome-add-service'>

        {

            addServiceTrigger
            ? <div className='mobile'>
                    <div className='box'>

                        <div className='fields'>

                            <FormInput
                                inputData={{

                                    id: 2,
                                    name: "name",
                                    label: "Nome do serviço *",
                                    placeholder: "Nome do serviço",
                                    pattern: "^[A-Za-z_ ]{3,50}$",
                                    error: "O nome da empresa deve ter entre 3 e 50 caractéres!",
                                    value: service.name,
                                    
                                    
                                }}
                                aditionalClass="stretched"
                                handleInput={handleInput}
                            />

                            <FormInput
                                inputData={{

                                    id: 3,
                                    name: "price",
                                    label: "Preço do serviço *",
                                    placeholder: "Preço do serviço",
                                    value: service.price,
                                    
                                    
                                }}
                                aditionalClass="stretched"
                                handleInput={handleInput}
                            />

                            <MainSelect
                                data={{
                                    handleInput: handleInput,
                                    list: serviceDurationData,
                                    label: "Tempo de duração",
                                    placeholder: "Selecione...",
                                    aditionalClass: "stretched",
                                    name: "duration"

                                }}

                            />
                            <SelectIptMulti
                                inputData={{
                                    handleInput: handleInputProfessionals,
                                    list: professionalsList.length > 0 ? professionalsList : null,
                                    label: "Profissional",
                                    placeholder: "Selecione...",
                                }}
                                aditionalClass="stretched"
                            />
                        </div>
                        <div className='buttons'>
                            <button type='button' className='cancel' onClick={(e)=>{setAddServiceTrigger(false)}}>Cancelar</button>
                            <button type='button' className='add' onClick={()=>{addService()}} >Adicionar</button>
                        </div>
                    </div>
                </div>
            : null
        }

        <h3 className='welcome-add-service-title'>Adicionar Serviço</h3>
        <span className='subtitle-desktop'>Adicione pelo menos 1 serviço...</span>
        <span className='subtitle-mobile'>Adicione pelo menos 1 serviço, basta clicar no botão abaixo:</span>

        <button type='button' className='trigger' onClick={(e)=>{setAddServiceTrigger(true)}}><FaPlus className='icon'/></button>

        <div className='desktop'>
            <div className='row'>

                <div className='fields'>

                    <MainInput
                        data={{

                            id: 2,
                            name: "name",
                            label: "Nome do serviço *",
                            placeholder: "Nome do serviço",
                            pattern: "^[A-Za-z_ ]{3,50}$",
                            error: "O nome da empresa deve ter entre 3 e 50 caractéres!",
                            value: service.name,
                            aditionalClass: "stretched",
                            handleInput: handleInput
                        }}
                    />

                    <MainInput
                        data={{

                            id: 3,
                            name: "price",
                            label: "Preço do serviço *",
                            placeholder: "Preço do serviço",
                            value: service.price,
                            aditionalClass: "stretched",
                            handleInput: handleInput
                            
                        }}
                    />

                    <MainSelect
                        data={{
                            handleInput: handleInput,
                            list: serviceDurationData,
                            label: "Tempo de duração",
                            placeholder: "Selecione...",
                            aditionalClass: "stretched",
                            name: "duration"

                        }}
                    />
                    <SelectIptMulti
                        inputData={{
                            handleInput: handleInputProfessionals,
                            list: professionalsList.length > 0 ? professionalsList : null,
                            label: "Profissional",
                            placeholder: "Selecione...",
                        }}
                        aditionalClass={"stretched"}

                    />

                </div>
                <IoMdAdd className='welcome-add-service-add-btn' onClick={()=>{addService()}} />
            </div>
        </div>

        <div className='welcome-add-service-services-list'>

            {servicesList.length > 0
                ? servicesList.map((service, index)=>{
                    return <ServiceItem key={index} index={index} service={service} deleteService={deleteService}/>
                })
                : null
            }
        </div>
        <div className='add-service-navigate-buttons-container'>
            <button type='button' className={`add-service-back`} onClick={(e)=>{setCurrentStep(currentStep-1)}}>Anterior</button>
            {servicesList.length > 0
                ? <button type="submit" className="add-service-next">Próxima </button>
                : <button disabled type="button" className={`add-service-next ${"disabled"}`}>Próxima </button>
            }
        </div>
            

    </div>
  )
}

export default AddServiceWelcome;