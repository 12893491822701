import React from 'react';
import "./InitialParametersWelcome.css";
import { intervalTime, serviceDurationData } from '../../../utils/Data';
import { MainSelect } from '../../commom/form-input/FormInput';

const InitialParametersWelcome = ({setInitialParameters, initialParameters, setCurrentStep, currentStep, handleSubmit}) => {

  const handleInput = (e) =>{


    let name = e.target.name;

    if(
        name == "Segunda" ||
        name == "Terça" ||
        name == "Quarta" ||
        name == "Quinta" ||
        name == "Sexta" ||
        name == "Sábado" ||
        name == "Domingo"
  
      ){
  
        setInitialParameters((prevRules) => ({
          ...prevRules,
          days: {
            ...prevRules.days,
            [name]: !initialParameters.days[name],
          },
        }));
  
      }else{

        setInitialParameters({...initialParameters, [e.target.name] : e.target.value});

      }

  }

  const checkData = () => {

    if(
        initialParameters.minTimeService != "" && 
        initialParameters.intervalTime != "" && 
        initialParameters.initialWorkTime != "" && 
        initialParameters.endWorkTime != "" && 
        initialParameters.initialLunchTime != "" && 
        initialParameters.endLunchTime != ""
    ){

        return true;
    }else{

        return false;
    }
  }

  console.log(initialParameters)

  return (
    <div className='welcome-initial-parameters-container'>
        <h3 className='welcome-initial-parameters-title'>Regras de negócio</h3>
        <span className='welcome-initial-parameters-subtitle'>Configure algumas regras de negócio, isso afetará nas funcionalidade do sistema</span>

        <div className='welcome-initial-parameters-list'>
            <div className='welcome-initial-parameters-list-item'>
                <span className='welcome-initial-parameters-list-item-title'>Tempo padrão para agendamento {`(Ex: 10 em 10 ou 20 em 20 min)`}:</span>
                <select name='intervalTime' className='welcome-initial-parameters-list-item-select' value={initialParameters.intervalTime} onChange={(e)=>{handleInput(e)}}>

                    {

                        intervalTime.map((option, index)=>{

                            return <option selected={index === 0 ? true : false}  key={index} value={option.id} label={option.name}></option>
                        })
                    }
                </select>
            </div>
            <div className='welcome-initial-parameters-list-item'>
                <span className='welcome-initial-parameters-list-item-title'>Tempo mínimo de duração {`(Ex: 40 minutos)`}:</span>
                <select name='minTimeService' className='welcome-initial-parameters-list-item-select' value={initialParameters.minTimeService} onChange={(e)=>{handleInput(e)}}>

                    {

                        serviceDurationData.map((option, index)=>{

                            return <option selected={index === 0 ? true : false}  key={index} value={option.id} label={option.name}></option>
                        })
                    }
                </select>
            </div>
            <div className='welcome-initial-parameters-list-item'>
                <span className='welcome-initial-parameters-list-item-title'>Horário de trabalho:</span>
                <div className='rows'>
                    <div className='row'>

                        <span>Início: </span>
                        <input name='initialWorkTime' className={`welcome-initial-parameters-list-item-input ${"first"}`} type='time' value={initialParameters.initialWorkTime} onChange={(e)=>{handleInput(e)}}></input>
                    </div>
                    <div className='row'>

                        <span>Fim: </span>
                        <input name='endWorkTime' className='welcome-initial-parameters-list-item-input' type='time' value={initialParameters.endWorkTime} onChange={(e)=>{handleInput(e)}}></input>
                    </div>

                </div>
            </div>

            <div className='welcome-initial-parameters-list-item'>
                <span className='welcome-initial-parameters-list-item-title'>Horário de almoço</span>
                <div className='rows'>
                    <div className="row">
                        <span>Início: </span>
                        <input name='initialLunchTime' className={`welcome-initial-parameters-list-item-input ${"first"}`} type='time' value={initialParameters.initialLunchTime} onChange={(e)=>{handleInput(e)}}></input>
                    </div>

                    <div className="row">
                        <span>Fim: </span>
                        <input name='endLunchTime' className='welcome-initial-parameters-list-item-input' type='time' value={initialParameters.endLunchTime} onChange={(e)=>{handleInput(e)}}></input>
                    </div>
                </div>
            </div>

            <div className="welcome-initial-parameters-list-item">
                <span className='welcome-initial-parameters-list-item-title'>Dias de funcionamento</span>

                {
                    Object.keys(initialParameters.days).map((day) => (
                    <label key={day} className="checkbox-container">
                        <input
                        type="checkbox"
                        name={day}
                        checked={initialParameters.days[day] == true ? true : false}
                        onChange={handleInput}
                        className={`custom-checkbox ${initialParameters.days[day] ? "checked" : "unchecked"}`}
                        />
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                    </label>
                    ))
                }
            </div>
        </div>

        <div className='initial-parameters-navigate-buttons-container'>
            <button type='button' className={`initial-parameters-back`} onClick={(e)=>{setCurrentStep(currentStep-1)}}>Anterior</button>
            {
                checkData()
                    ? <button type="submit" onClick={(e)=>{handleSubmit(e)}} className="initial-parameters-next">Cadastrar</button>
                    : <button disabled type="submit" className={`initial-parameters-next ${"disabled"}`}>Cadastrar</button>
            }
        </div>
    </div>
  )
}

export default InitialParametersWelcome;