import axios from 'axios';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoClose } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { DatePickerInput, FormInput, MaskedFormInput, SelectIpt } from '../../../../components/commom/form-input/FormInput';
import { useAuth } from '../../../../context/AuthContext';
import { GeneralBarsContext } from '../../../../context/generalBarsContext';
import { GetSlotsForAnyProfessional, GetSlotsForSpecificProfessional, UpdateSlotsBasedOnSelected } from '../../../../utils/functions/SlotsManager';
import LoadingIcon from "../../../../utils/images/loading_1.gif";
import "./AddScheduleBar.css";
import AddProfessionalBar from '../../registrations/professionals/addProfessionalBar/AddProfessionalBar';
import { toClientDateTime } from '../../../../utils/functions/Formatters';
registerLocale('br', ptBR);

const AddScheduleBar = () => {

  const { user, businessRules } = useAuth();

    const notifySuccess = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyError = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const [date, setDate] = useState();

    const [professionalsList, setProfessionalsList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [schedulesList, setSchedulesList] = useState([]);

    const {setAddScheduleBarState, addScheduleBarState, selectedScheduleData, addScheduleBarTriggerName, scheduleBarTriggerName} = useContext(GeneralBarsContext);

    const [scheduleData, setScheduleData] = useState({

        id: addScheduleBarTriggerName == "edit" ? selectedScheduleData.id : uuidv4(),
        barberId: user != null ? user.id : "",
        customerId: addScheduleBarTriggerName == "edit" ? selectedScheduleData.customerId == undefined ? "" : selectedScheduleData.customerId : "",
        customerName: addScheduleBarTriggerName == "edit" ? selectedScheduleData.customerName : "",
        customerEmail: addScheduleBarTriggerName == "edit" ? selectedScheduleData.customerEmail : "",
        customerPhoneNumber: addScheduleBarTriggerName == "edit" ? selectedScheduleData.customerPhoneNumber : "",
        professionalId : addScheduleBarTriggerName == "edit" ? selectedScheduleData.professionalId : "",
        serviceId : addScheduleBarTriggerName == "edit" ? selectedScheduleData.serviceId : "",
        serviceName : addScheduleBarTriggerName == "edit" ? selectedScheduleData.serviceName : "",
        servicePrice : addScheduleBarTriggerName == "edit" ? selectedScheduleData.servicePrice : "",
        serviceDuration : addScheduleBarTriggerName == "edit" ? selectedScheduleData.serviceDuration : "",
        date: addScheduleBarTriggerName == "edit" ? moment(selectedScheduleData.scheduleDateTime).format("YYYY-MM-DD") : "",
        time: "",
        scheduleDateTime : addScheduleBarTriggerName == "edit" ? selectedScheduleData.scheduleDateTime : "",
        status : addScheduleBarTriggerName == "edit" ? selectedScheduleData.status : "pending",
        createdAt: moment().utc(),
        modifiedAt: moment().utc()
    });

    const [updatedSlots, setUpdatedSlots] = useState([]);
    const [slots, setSlots] = useState([]);

    const [selectedSlot, setSelectedSlot] = useState({

        index: "",
        slot: ""
    });

    const formatDate = (date) => {

        const year = date.toLocaleString('default', {year: 'numeric'});
        const month = date.toLocaleString('default', {
            month: '2-digit',
        });
        const day = date.toLocaleString('default', {day: '2-digit'});

        return [year, month, day].join('-');
    }

    const handleInput = (e, index, slot, date, name) => {

        if(name == "date"){

            setDate(date)
            setScheduleData({...scheduleData, date : formatDate(date)});

        }else if(e.target.value != "0" && e.target.name == "service" || e.target.name == "professional"){

            if(e.target.name == "professional"){

                const professional = professionalsList.filter((el)=>{return el.id == e.target.value} );

                setScheduleData({...scheduleData, professionalId : professional[0].id});


            }else if(e.target.name == "service" || e.target.name == "servicePrice"){

                const service = servicesList.filter((el)=>{return el.id == e.target.value} );

                setScheduleData({...scheduleData, serviceId: service[0].id, serviceName : service[0].name, servicePrice : service[0].price, serviceDuration: service[0].duration});

            }else{

                setScheduleData({...scheduleData, [e.target.name] : e.target.value});
            }
        }else if(e.target.name == "service"){

            setScheduleData({...scheduleData, servicePrice : "", serviceName : ""});

        }else if(e.target.name == "professional"){
        }else if(e.target.name == "time"){

            setSelectedSlot({

                index: index,
                slot: slot
            });

            setScheduleData({...scheduleData, time : slot});
        }else{

            setScheduleData({...scheduleData, [e.target.name] : e.target.value});
        }
    }

    const handleInputService = (value) =>{


        const item = servicesList.filter((service)=>{

            return service.id == value.value
        });

        setScheduleData({...scheduleData, serviceId: item[0].id, serviceName: item[0].name, servicePrice: item[0].price, serviceDuration: item[0].duration});
    }

    const handleInputProfessional = (value) => {

        const item = professionalsList.filter((professional)=>{

            return professional.id == value.value;
        })

        setScheduleData({...scheduleData, professionalId: item[0].id});

    }

    const validateFields = (name) =>{

        if(name == "customerPhoneNumber"){

            let str1 = scheduleData.customerPhoneNumber.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", "");
            if(str1.length == 11){
            return false;
            }else{
        
            return true;
            }
        }
        
    }

    const handleSubmit = (e) => {

        setLoading(true);

        e.preventDefault();

        const scheduleDateTimeF = moment(scheduleData.date).hour(parseInt(scheduleData.time.split(":")[0])).minute(parseInt(scheduleData.time.split(":")[1])).utc();

        if(addScheduleBarTriggerName == "edit"){

            axios.put(`/api/schedules/edit-schedule/${scheduleData.id}`, {

            customerName: scheduleData.customerName,
            customerEmail: scheduleData.customerEmail,
            customerPhoneNumber: scheduleData.customerPhoneNumber,
            serviceId: scheduleData.serviceId,
            serviceName: scheduleData.serviceName,
            servicePrice: scheduleData.servicePrice,
            serviceDuration: scheduleData.serviceDuration,
            professionalId: scheduleData.professionalId,
            scheduleDateTime: scheduleDateTimeF,
            status: scheduleData.status,
            modifiedAt: scheduleData.modifiedAt,
            })
            .then(res=>{
    
                if(res.data != null && res.data != "Error"){
    
                    
                    setTimeout(()=>{

                        setLoading(false);
                        notifySuccess("Agendamento editado com sucesso!");
    
                        setTimeout(()=>{
    
                            setAddScheduleBarState(!addScheduleBarState);
    
                            setScheduleData({
                                id: "",
                                barberId: "",
                                createdAt: "",
                                customerId: "",
                                customerName: "",
                                customerEmail: "",
                                customerPhoneNumber: "",
                                professionalId: "",
                                serviceId: "",
                                serviceName: "",
                                servicePrice: "",
                                serviceDuration: "",
                                status: ""
                            });
                        
                        }, 1500);
                    }, 1500);
                }else{
    
                    notifyError("Erro ao editar serviço, tente novamente!")
                    setLoading(false);
                }
            })
            .catch(err=>{

                setTimeout(()=>{

                    if(err.response.status === 400){
            
                        notifyError(err.response.data.message);
            
                    }else if(err.response.status === 500){
            
                        notifyError(err.response.data.message);
            
                    }else{
            
                    }
                }, 1500);
                setLoading(false);
    
            });
        }else{

            axios.post("/api/schedules/add-schedule", {

                ...scheduleData,
                scheduleDateTime: scheduleDateTimeF
            })
            .then(res=>{

                if(res.data.success === true){

                    axios.post("/api/email/customer-schedule-notification", {

                        email: scheduleData.customerEmail,
                        userName: scheduleData.customerName,
                        scheduleDate: toClientDateTime(scheduleDateTimeF, "DD/MM/YYYY"),
                        scheduleTime: toClientDateTime(scheduleDateTimeF, "HH:mm"),
                        enterpriseName: user.enterpriseName,
                        barberAddress: user.street + ", nº" + user.streetNumber + ", " + user.neighborhood + ", " + user.city + " - " + user.state,
                        barberPhone: user.whatsapp,
                    })
                    .then(res=>{

                        if(res.data.success){

                            axios.post("/api/email/barber-schedule-notification", {

                                email: user.email,
                                userName: user.masterName,
                                scheduleDate: toClientDateTime(scheduleDateTimeF, "DD/MM/YYYY"),
                                scheduleTime: toClientDateTime(scheduleDateTimeF, "HH:mm"),
                                serviceName: scheduleData.serviceName,
                                customerName: scheduleData.customerName,
                                customerEmail: scheduleData.customerEmail,
                                customerPhone: scheduleData.customerPhoneNumber
                            })
                            .then(res=>{
        
                                if(res.data.success){
        
                                    setTimeout(()=>{
        
                                        setLoading(false);
                                        notifySuccess("Agendamento executado com sucesso!");
                    
                                        setTimeout(()=>{

                                            setAddScheduleBarState(!addScheduleBarState);
                                            setScheduleData({
                                                id: "",
                                                barberId: "",
                                                createdAt: "",
                                                customerId: "",
                                                customerName: "",
                                                customerEmail: "",
                                                customerPhoneNumber: "",
                                                professionalId: "",
                                                serviceId: "",
                                                serviceName: "",
                                                servicePrice: "",
                                                serviceDuration: "",
                                                scheduleDateTime: "",
                                                status: ""
                                            });
                                        
                                        }, 1500);
                                    }, 1500);
                                }
        
                            })
                            .catch(err=>{
        
                                setTimeout(()=>{

                                    if(err.response.status === 500){
                            
                                        notifyError(err.response.data.message);
                            
                                    }else{
                            
                                    }
                                }, 1500);

                            });
                        }

                    })
                    .catch(err=>{

                        setTimeout(()=>{

                            if(err.response.status === 500){
                    
                                notifyError(err.response.data.message);
                    
                            }else{
                    
                            }
                        }, 1500);

                    });

                }
            })
            .catch(err=>{

                setTimeout(()=>{

                    if(err.response.status === 500){
            
                        notifyError(err.response.data.message);
            
                    }else{
            
                    }
                }, 1500);
                setLoading(false);
    
            });
        }
    }
    
    useEffect(()=>{

        let newSlots = [...slots]

        UpdateSlotsBasedOnSelected(selectedSlot, newSlots, scheduleData.serviceDuration, user != null ? user.minTimeService : null, setUpdatedSlots)
    },[selectedSlot]);

    useEffect(()=>{

        const fetchData = async ()=>{

            if(scheduleData.serviceId != ""){

                let p2s = [];

                const requestProfessionalsToServices = await axios.get(`/api/professionals-to-services/${scheduleData.serviceId}`);

                //Need to push into an array because the direct response cannot be mapped
                p2s.push(requestProfessionalsToServices.data.data);
                
                Promise.all(


                    p2s[0].map(async(item)=>{

                    let res;


                    res = await axios.get(`/api/professionals/${item.professionalId}`);

                    return res.data;
                })
                ).then(res=>{

                    let professionals = [];
                    res.map((professionalData)=>{

                        professionals.push(professionalData.data);
                    });

                    setProfessionalsList(professionals)
                });
            }
        }

        fetchData()
    }, [scheduleData.serviceName])


    useEffect(()=>{

        const fetchData = async ()=>{
        
            if(user != null && addScheduleBarTriggerName != "edit"){

                axios.get(`/api/services/${user.id}`)
                .then(res=>{

                    if(res.data.success === true){

                        setServicesList(res.data.data);
                    }
                })
                .catch(err=>{

                    setTimeout(()=>{

                        if(err.response.status === 500){
                
                            notifyError(err.response.data.message);
                
                        }else if(err.response.status === 404){
                
                            notifyError(err.response.data.message);
                
                        }else{
                
                        }
                    }, 1500);

                });
            }else if(addScheduleBarTriggerName == "edit"){

                await axios.get(`/api/professionals-to-services/${selectedScheduleData.serviceId}`).then(async res=>{

                    if(res.data.success){

                        let professionalsList = [];

                        res.data.data.map(p2s=>{

                            axios.get(`/api/professionals/${p2s.professionalId}`).then(res=>{

                                if(res.data.success){

                                    professionalsList.push(res.data.data);

                                }


                            }).catch(err=>{


                            });
                        });

                        setProfessionalsList(professionalsList);
                    }

                }).catch(err=>{


                });

                await axios.get(`/api/services/${user.id}`).then(res=>{

                    if(res.data.success){

                        setServicesList(res.data.data)
                    }
                }).catch(err=>{


                });
        
        
            }
        }
        if(user != null){
            fetchData()

        }
    },[])

    const getSchedules = async (e) => {

        e.preventDefault();

        if(businessRules != null){

            const selectedDay = moment(date).format("dddd");

            if(businessRules[selectedDay.toLocaleLowerCase()] == 0){
            
                return setSlots([]);
            }


            const startTime = moment(scheduleData.scheduleDateTime).startOf("day").utc().toISOString();
            const endTime = moment(scheduleData.scheduleDateTime).endOf("day").utc().toISOString();

            try{

                const schedulesRequest = await axios.get("/api/schedules/scheduled-services-by-date", { params:{

                    barberId: user.id,
                    professionalId: scheduleData.professionalId,
                    date: scheduleData.date.substring(0, 10)
                }});

                findTimeSlots(schedulesRequest.data.data);
        
                setSchedulesList(schedulesRequest.data.data);
            }catch(err){

                if(err.response.status == 404){

                    findTimeSlots([]);
                    
                }
            }


        }
        
        
    }

    const findTimeSlots = (schedules, professionalId) => {


        setSlots(GetSlotsForSpecificProfessional(businessRules.initialWorkTime, businessRules.endWorkTime, businessRules.initialLunchTime, businessRules.endLunchTime, schedules, scheduleData.professionalId, scheduleData.serviceDuration, businessRules.minTimeService));
      
    }

    return (

        <>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ToastContainer />
            {
    
                addScheduleBarState
                ? <form className="add-schedule-bar-bg">
    
                    <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    />
                    <div className="container">
                        <div className="top-container">
                            <span className="title">Novo Agendamento</span>
                            <IoClose onClick={()=>{setAddScheduleBarState(!addScheduleBarState)}} className="icon"/>
                        </div>
    
                        <div className="fields">

                            <SelectIpt
                                inputData={{
                                    handleInput: handleInputService,
                                    list: servicesList.length > 0 ? servicesList : null,
                                    label: "Serviço",
                                    placeholder: "Selecione..."
                                }}
                                recInputData={addScheduleBarTriggerName == "edit" ? {value: scheduleData.serviceId, label: scheduleData.serviceName} : null}
                            />

                            <FormInput
                                inputData={{
    
                                    id: 3,
                                    name: "servicePrice",
                                    label: "Preço do seviço",
                                    placeholder: "Preço do seviço",
                                    value: scheduleData.servicePrice,
                                    required: true,
                                    disabled: true
                                }}
                                handleInput={handleInput}
                                
                            />

                            {

                                professionalsList.length > 0
                                ?
                                <SelectIpt
                            inputData={{
                                handleInput: handleInputProfessional,
                                list: professionalsList.length > 0 ? professionalsList : null,
                                label: "Profissional",
                                placeholder: "Selecione...",
                                disabled: professionalsList.length > 0 ? false : true
                            }}
                            recInputData={addScheduleBarTriggerName === "edit" && professionalsList.length > 0 ? {
                                value: scheduleData.professionalId,
                                label: professionalsList.find(professional => professional.id === scheduleData.professionalId)?.name
                            } : null}
                            />
                                : null
                            }

                            <DatePickerInput
                                inputData={{
                                    name: "date",
                                    label: "Selecione uma data *",
                                    value: scheduleData.date,
                                    minDate: moment().toDate()
                                }}
                                setDate={setDate}
                                date={date}
                                handleInput={handleInput}
                                recInputData={addScheduleBarTriggerName == "edit" ? scheduleData.date : null}
                            />

                            <FormInput
                                inputData={{
    
                                    name: "customerName",
                                    label: "Nome do cliente",
                                    placeholder: "Nome do cliente",
                                    value: scheduleData.customerName,
                                    required: true
                                }}
                                handleInput={handleInput}
                            />

                            <FormInput
                                inputData={{
    
                                    name: "customerEmail",
                                    label: "E-mail do cliente",
                                    placeholder: "E-mail do cliente",
                                    value: scheduleData.customerEmail,
                                    required: true
                                }}
                                handleInput={handleInput}
                            />
                            

                            <MaskedFormInput
                                inputData={{

                                    name: "customerPhoneNumber",
                                    label: "Telefone *",
                                    placeholder: "Telefone",
                                    value: scheduleData.customerPhoneNumber,
                                    required: true,
                                    mask: "(99) 99999-9999",
                                    hasError: validateFields("customerPhoneNumber").toString(),
                                    error: "Número inválido!"
                                }}
                                handleInput={handleInput}
                            />

                            {

                                addScheduleBarTriggerName != "edit"
                                ?
                                    <>
                                        {
                                            scheduleData.serviceName != "" && scheduleData.date != "" && scheduleData.customerName != "" && scheduleData.customerEmail != "" && !validateFields("customerPhoneNumber")
                                                ? <button className='get-timeslots-btn' onClick={(e)=>{getSchedules(e)}}>Buscar horários</button>
                                                : <button className='get-timeslots-btn' disabled onClick={(e)=>{getSchedules(e)}}>Buscar horários</button>
                                        }

                                        <div className='slots-time-container'>
                                            <span className='slots-time-title'>{slots.length !== 0 ? "Horários disponíveis" : null}</span>
                                            
                                                <div className='slots-time-sub-container'>

                                                    {
                                                        slots.length === 0
                                                            ? <span style={{flex: 1, textAlign: "center"}}>Não funcionamos hoje!</span>
                                                            : selectedSlot.slot == ""
                                                                    ? slots.map((slot, i)=>{
                                                                        return <button name='time' key={i} onClick={(e)=>{handleInput(e, i, slot)}} type='button' className={`time-schedule-item ${selectedSlot.index > 0 && selectedSlot.index == i ? "selected" : ""}`}>
                                                                            {slot}
                                                                    </button>})
                                                                    : updatedSlots.map((slot, i)=>{
                                                                            return <button name='time' key={i} onClick={(e)=>{handleInput(e, i, slot)}} type='button' className={`time-schedule-item ${selectedSlot.index == i ? "selected" : ""}`}>{slot}</button>
                                                                        })

                                                    }

                                                </div>
                                        </div>
                                    </>
                                : <div className='scheduled-time'>
                                    <div className='container'><span className='title'>Horário de agendamento:</span><span className='time'>{moment(scheduleData.scheduleDateTime).format("HH:mm")}</span></div>
                                    <button className='change-time' onClick={(e)=>{getSchedules(e)}}>Mudar o horário</button>
                                    <div className='slots-time-container'>
                                            <span className='slots-time-title'>Horários disponíveis</span>
                                            
                                                <div className='slots-time-sub-container'>
                                                    {
                                                        
                                                        selectedSlot.slot != "" && scheduleData.serviceId != "" && scheduleData.professionalId != "" && scheduleData.customerName != "" && scheduleData.customerEmail != "" && scheduleData.customerPhoneNumber != ""
                                                        ? updatedSlots.length > -1
                                                            ? updatedSlots.map((slot, i)=>{return <button name='time' key={i} onClick={(e)=>{handleInput(e, i, slot)}} type='button' className={`time-schedule-item ${selectedSlot.index == i ? "selected" : ""}`}>{slot}</button>})
                                                            : null
                                                        :  slots.map((slot, i)=>{return <button name='time' key={i} onClick={(e)=>{handleInput(e, i, slot)}} type='button' className={`time-schedule-item ${selectedSlot.index > 0 && selectedSlot.index == i ? "selected" : ""}`}>{slot}</button>})
                                                            
                                                    }
                                                </div>
                                        </div>
                                </div>

                            }

                        </div>

                        <div className="bottom-container">
    
                            {
                                addScheduleBarTriggerName == "edit"
                                    ? !loading
                                        ? scheduleData.serviceName != "" &&
                                        scheduleData.date != "" &&
                                        scheduleData.time != "" &&
                                        scheduleData.customerName != "" &&
                                        scheduleData.customerEmail != "" &&
                                        !validateFields("customerPhoneNumber")

                                            ? <button onClick={(e)=>{handleSubmit(e)}} className="add-schedule-btn">Editar Agendamento</button>
                                            : <button onClick={(e)=>{handleSubmit(e)}} className="add-schedule-btn" disabled>Editar Agendamento</button>

                                        : <button style={{gap: "10px"}} className="add-schedule-btn"><img style={{width: "20px", height: "20px"}} src={LoadingIcon}/>Salvando Alterações</button>

                                    : !loading

                                        ? scheduleData.serviceName != "" &&
                                            scheduleData.customerName != "" &&
                                            scheduleData.customerEmail != "" &&
                                            scheduleData.date != "" &&
                                            scheduleData.time != "" &&
                                            !validateFields("customerPhoneNumber")

                                                ? <button onClick={(e)=>{handleSubmit(e)}} className="add-schedule-btn">Confirmar Agendamento</button>
                                                : <button onClick={(e)=>{handleSubmit(e)}} className="add-schedule-btn" disabled>Confirmar Agendamento</button>

                                        : <button disabled={true} style={{gap: "10px"}} className="add-schedule-btn"><img style={{width: "20px", height: "20px"}} src={LoadingIcon}/>Confirmando agendamento</button>
            
                            }
                        </div>
                    </div>
                  </form>
                : null
            }
        </>
        
    )
}

export default AddScheduleBar;